/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { translate } from 'i18n/i18n';
import { useSelector } from 'react-redux';
import { HCP_USER_ROLES, HCP_USER_STATUS } from 'Constants';

const useHasUserRole = (roles) => {
  const [hasUserRole, setHasUserRole] = useState(false);
  const currentUserStore = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    const userRoleId = _.get(currentUserStore, 'role.id');
    if (userRoleId) {
      setHasUserRole(_.includes(roles, userRoleId));
    }
  }, [currentUserStore, roles]);

  return hasUserRole;
};

const useIsUserState = (status) => {
  const [isUserState, setIsUserState] = useState(false);
  const currentUserStore = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    const userStatus = _.get(currentUserStore, 'status');
    setIsUserState(userStatus && userStatus === status);
  }, [currentUserStore, status]);

  return isUserState;
};

/**
 * Utility function to show 'Undisclosed' when a value is not given
 * @param {string} value Given value
 * @returns {string} `value` if not null or empty, 'Undisclosed' otherwise
 * */
export const undisclosed = (value) => value || translate('GENERAL.undisclosed');

/**
 * Uppercases the first letter of a string,
 * and lowercases the rest of it
 * @param {string} value The string to format
 */
export const capFirstCharOnly = (value) => value && value.charAt(0).toUpperCase()
    + value.slice(1).toLowerCase();

/**
  * Trim spaces from the beginning of a string
  * @param {string} value
  * @return {string} the `value` without any leading space
  */
export const leftTrim = (value) => {
  if (!value) return value;
  return value.replace(/^\s+/g, '');
};

/**
 * When date from server comes in ISO 8601 format (YYYY-MM-DD), eg. date of birth,
 * if used as-is with Date(), the computed date will be relative to the users time zone.
 * Use this helper method, if you want to convert it into time zone independent date
 * This will turn YYYY-MM-DD into YYYY,MM,DD and then create the Date object
 * @param {string} value Date in YYYY-MM-DD syntax
 * @returns {Date} Time zone independent date object
 */
export const serverDateToGMT = (value) => new Date(value.replace(/-/g, ','));

/**
 * Helper function to compose full name of a user in the format '{lastName}, {firstName}'.
 *
 * @param {string} firstName
 * @param {string} lastName
 */
export const composeFullName = (firstName, lastName) => (
  (lastName && firstName)
    ? `${lastName}, ${firstName}`
    : ''
);


/**
 * Helper function to compose the medication label with both name and active ingredient.
 *
 * @param {string} name
 * @param {string} activeIngredient
 */
export const composeTitrationMedicationLabel = (medication) => (
  `${medication.activeIngredient} (${medication.name})`
);

/**
 * Filtering algorithm for a medication, used when searching in the medications dropdown
 *
 * @param {object} medication Medication object, as in the reference data
 * @param {string} searchTerm Search term to match
 */
export const medicationSearchMatch = (medication, searchTerm) => {
  const lowerSearchTerm = leftTrim(searchTerm).toLowerCase();
  const fullMedicationLabel = composeTitrationMedicationLabel(medication).toLowerCase();
  return medication.name.toLowerCase().indexOf(lowerSearchTerm) === 0
    || medication.activeIngredient.toLowerCase().indexOf(lowerSearchTerm) === 0
    || fullMedicationLabel.indexOf(lowerSearchTerm) === 0;
};

/**
 * Helper function to prepend a child key path to its parent namespace.
 * Used for translation keys so we don't have to repeat the entire path.
 *
 * Example usage:
 * const i18nKey = withKeyNamespace('TITRATIONS.titrationBuilder');
 * {translate(i18nKey('title'))} will use the message from 'TITRATIONS.titrationBuilder.title'
 *
 * @param {string} namespace
 */
export const withKeyNamespace = (namespace) => (child) => `${namespace}.${child}`;

/**
 * Custom hook which stores the previous value of a given prop, state or
 * other calculated value.
 * Source and usage:
 * https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 *
 * @param {any} value Value to be watched for previous values.
 */
export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/**
 * React hook for isUserPhysician flag
 */
export const useIsUserPhysician = useHasUserRole.bind(null, [HCP_USER_ROLES.physician]);

/**
 * React hook for isUserNurse flag
 */
export const useIsUserNurse = useHasUserRole.bind(null, [HCP_USER_ROLES.nurse]);

/**
 * React hook for isUserHospitalStaff flag
 */
export const useIsUserHospitalStaff = useHasUserRole.bind(
  null,
  [HCP_USER_ROLES.physician, HCP_USER_ROLES.nurse],
);

/**
 * Helper function which pads a number with zero on front if that
 * number is a digit.
 *
 * @param {number} value The number to pe padded if is a digit.
 * @returns {string} The number as a string.
 */
export const padIfDigit = (value) => _.padStart(value, 2, '0');

/**
 * React hook for isUserPending flag
 * 'true' if current hcp user has not yet been approved into the system
 */
export const useIsUserPending = useIsUserState.bind(null, HCP_USER_STATUS.pending);

/**
 * React hook for isUserActive flag
 */
export const useIsUserActive = useIsUserState.bind(null, HCP_USER_STATUS.active);
