/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core';
import palette from 'styles/colors';

const boxTextColor = palette.black.black1;
const boxBackgroundColor = palette.silver.silver1;

const KnTextBoxText = styled('span')({
  color: boxTextColor,
  opacity: 0.7,
});

const KnTextBoxWrapper = styled('div')(({ theme, wide }) => ({
  padding: theme.spacing(2, 1),
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
  backgroundColor: boxBackgroundColor,
  minWidth: wide ? 108 : 90,
  height: 'fit-content',
  whiteSpace: 'nowrap',
}));

const KnTextBox = ({ text, ...rest }) => (
  <KnTextBoxWrapper {...rest}>
    <Typography component={KnTextBoxText}>{text}</Typography>
  </KnTextBoxWrapper>
);

KnTextBox.propTypes = {
  text: PropTypes.string.isRequired,
};

export default KnTextBox;
