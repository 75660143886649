/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { appInsights } from 'appInsights';
import { useDispatch, useSelector } from 'react-redux';
import patientActions from 'redux/actions/patientActions';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { KnSectionHeader } from 'components/Typography';
import KnButton from 'components/Button';
import KnTitrationsList from 'components/titrations/TitrationsList';
import KnTitrationInfo from 'components/titrations/item-components/TitrationItemInfo';
import KnMedicationInfo from 'components/titrations/item-components/TitrationItemMedication';
import useDialog from 'components/dialog/DialogService';
import KnErrorMessage from 'components/ErrorMessage';
import {
  APP_PAGE_URLS,
  REGIMEN_STATUS, TITRATIONS_CONTEXT,
  TRACKING_EVENTS,
} from 'Constants';
import { useIsUserHospitalStaff } from 'utils/utils';
import { KnElevatedColorBox } from 'styles/common';
import KnRegimenStatus, { getStatusColor } from './RegimenStatus';
import KnThresholdDetails from './ThresholdDetails';
import KnPatientRecordBox from './styles';

const KnAssignedRegimenDetails = (props) => {
  const {
    assignedRegimen,
    error,
    onRetry,
    actions,
    AssignRegimenButton,
  } = props;

  return (
    <div data-testid="assigned-regimen-wrapper">
      {!error && !assignedRegimen && (
        <Box mb={3}>
          {AssignRegimenButton}
        </Box>
      )}
      <KnErrorMessage
        error={error}
        messageKey="PATIENT_RECORD.ERROR_MESSAGES.medicationsListError"
        onRetry={onRetry}
        centered={false}
        pb={3}
        data-testid="regimens-fetch-error"
      />
      {/** Show regimen */
        !error && assignedRegimen && (
          <>
            <KnTitrationInfo
              context={TITRATIONS_CONTEXT.assignedRegimen}
              titration={assignedRegimen}
              actions={actions}
            />
            <Box mt={3} mb={2}>
              {assignedRegimen.medications.map((presetMedication) => (
                <KnMedicationInfo
                  key={presetMedication.index}
                  presetMedication={presetMedication}
                />
              ))}
            </Box>
          </>
        )
      }
    </div>
  );
};


KnAssignedRegimenDetails.propTypes = {
  assignedRegimen: PropTypes.shape(),
  error: PropTypes.bool,
  onRetry: PropTypes.func,
  actions: PropTypes.shape(),
  AssignRegimenButton: PropTypes.node,
};

KnAssignedRegimenDetails.defaultProps = {
  assignedRegimen: null,
  error: false,
  onRetry: () => { },
  actions: {},
  AssignRegimenButton: null,
};

/**
 * Component for displaying patient's medication regimen
 */
const KnMedicationRegimen = ({ patientId }) => {
  const { t: translate } = useTranslation();
  const { data: patientInfo } = useSelector((state) => state.patientRecord.patientInfo);
  const {
    data: regimens,
    error: regimensError,
  } = useSelector((state) => state.patientRecord.regimens);
  const {
    data: thresholds,
    error: thresholdsError,
  } = useSelector((state) => state.patientRecord.thresholds);
  const dispatch = useDispatch();
  const dialog = useDialog();
  const userIsHospitalStaff = useIsUserHospitalStaff();
  const history = useHistory();

  useEffect(() => {
    dispatch(patientActions.fetchMedicationRegimenData(patientId));
    dispatch(patientActions.fetchThresholds(patientId));
  }, [dispatch, patientId]);

  const redoFetchPatientMedications = useCallback(() => {
    dispatch(patientActions.fetchMedicationRegimenData(patientId));
  }, [dispatch, patientId]);

  const redoFetchPatientThresholds = useCallback(() => {
    dispatch(patientActions.fetchThresholds(patientId));
  }, [dispatch, patientId]);

  const canAssignNewRegimen = useMemo(() => {
    const patientHasAssignedRegimen = _.find(regimens, (regimen) => (
      regimen.status === REGIMEN_STATUS.notStarted
      || regimen.status === REGIMEN_STATUS.active
    ));
    return userIsHospitalStaff && !patientHasAssignedRegimen;
  }, [userIsHospitalStaff, regimens]);

  const canSetThreshold = useMemo(() => {
    const patientHasThresholds = !_.isEmpty(thresholds);
    return userIsHospitalStaff && !patientHasThresholds;
  }, [userIsHospitalStaff, thresholds]);

  /** Currently assigned regimen (started or not) */
  const assignedRegimen = useMemo(() => {
    const currentRegimens = _.filter(
      regimens,
      (regimen) => (
        (regimen.status !== REGIMEN_STATUS.deactivated)
        && (regimen.status !== REGIMEN_STATUS.completed)
      ),
    );
    return _.first(currentRegimens);
  }, [regimens]);
  const pastRegimens = useMemo(() => _.filter(
    regimens,
    (regimen) => (
      regimen.status === REGIMEN_STATUS.deactivated
      || regimen.status === REGIMEN_STATUS.completed
    ),
  ), [regimens]);

  const editMedicationRegimen = useCallback(({ medications, id: regimenId, assignedAt }) => {
    history.push(
      APP_PAGE_URLS.editAssignedRegimen.replace(':patientId', patientId).replace(':regimenId', regimenId),
      {
        patientName: _.get(patientInfo, 'firstName'),
        medications,
        assignedAt,
      },
    );
  }, [history, patientId, patientInfo]);

  const deactivateMedicationRegimen = useCallback(({ id: regimenId }) => {
    dialog({
      title: translate('PATIENT_RECORD.medicationRegimen.confirmDeactivateRegimenDialog.title'),
      description: translate('PATIENT_RECORD.medicationRegimen.confirmDeactivateRegimenDialog.content'),
      submitLabel: translate('GENERAL.okButton'),
      closeLabel: translate('GENERAL.cancelButton'),
    }).then(() => {
      dispatch(patientActions.deactivateMedicationRegimen(patientId, regimenId));
    });
  }, [dialog, dispatch, patientId, translate]);

  const editThresholds = useCallback(() => {
    history.push(
      APP_PAGE_URLS.threshold.replace(':patientId', patientId),
      {
        activeRegimen: assignedRegimen,
        patientName: _.get(patientInfo, 'firstName'),
        thresholds,
      },
    );
  }, [history, patientId, patientInfo, assignedRegimen, thresholds]);

  const onAssignRegimen = useCallback(() => {
    appInsights.trackEvent({ name: TRACKING_EVENTS.clickOnAssignMedication });
  }, []);

  const onSetThreshold = useCallback(() => {
    appInsights.trackEvent({ name: TRACKING_EVENTS.clickSetThreshold });
  }, []);

  /** Define consts for these two buttons, to avoid repeating them */
  const AssignRegimenButton = useMemo(() => (
    <KnButton
      route={APP_PAGE_URLS.assignPreset.replace(':patientId', _.get(patientInfo, 'patientId'))}
      onClick={onAssignRegimen}
      routeParams={{ patientInfo }}
      data-testid="assign-regimen-button"
    >
      {translate('PATIENT_RECORD.patientSettings.assignRegimenButton')}
    </KnButton>
  ), [onAssignRegimen, patientInfo, translate]);
  const SetThresholdButton = useMemo(() => (
    <KnButton
      route={APP_PAGE_URLS.threshold.replace(':patientId', patientId)}
      onClick={onSetThreshold}
      routeParams={{
        activeRegimen: assignedRegimen,
        patientName: _.get(patientInfo, 'firstName'),
      }}
      data-testid="set-threshold-button"
    >
      {translate('PATIENT_RECORD.patientSettings.setThresholdButton')}
    </KnButton>
  ), [onSetThreshold, patientId, assignedRegimen, patientInfo, translate]);

  return (
    <KnPatientRecordBox extrapadding="true" mb={3} data-testid="medication-regimen-wrapper">
      { /** PATIENT SETTINGS (ACTIVE REGIMEN & THRESHOLDS) */}
      <Box mb={3} display="flex" justifyContent="space-between">
        <Typography variant="h6" component={KnSectionHeader}>
          {translate('PATIENT_RECORD.patientSettings.title')}
        </Typography>
        {assignedRegimen && (
          <KnRegimenStatus
            status={assignedRegimen.status}
            reachedGoal={assignedRegimen.goalDoseReached}
          />
        )}
      </Box>

      {/** When both buttons need to be shown
       * (no loading errors, no assigned regimen, no thresholds)
       */
        canAssignNewRegimen && regimens && canSetThreshold && thresholds
        && (
          <Box display="inline-box">
            <Box mr={3}>
              {AssignRegimenButton}
            </Box>
            {SetThresholdButton}
          </Box>
        )
      }

      {/** When there are loading errors,
        * or at least one thing to display (regimen or thresholds) */
        (assignedRegimen || !_.isEmpty(thresholds) || regimensError || thresholdsError)
        && (
          <KnElevatedColorBox pt={3} pb={3} pl={3} pr={4} borderColor={assignedRegimen ? getStatusColor(assignedRegimen.status, assignedRegimen.goalDoseReached) : 'white'}>
            <KnAssignedRegimenDetails
              assignedRegimen={assignedRegimen}
              error={regimensError}
              onRetry={redoFetchPatientMedications}
              actions={userIsHospitalStaff ? {
                onEditMedicationRegimen: editMedicationRegimen,
                onDeactivateMedicationRegimen: deactivateMedicationRegimen,
              } : {}}
              AssignRegimenButton={(regimens && canAssignNewRegimen) ? AssignRegimenButton : null}
            />
            <KnThresholdDetails
              thresholds={thresholds}
              error={thresholdsError}
              onRetry={redoFetchPatientThresholds}
              onEditClick={editThresholds}
              SetThresholdButton={canSetThreshold ? SetThresholdButton : null}
            />
          </KnElevatedColorBox>
        )
      }

      { /** PAST MEDICATIONS */
        pastRegimens.length > 0
        && (
          <div data-testid="past-regimens-wrapper">
            <Box mt={5} mb={3}>
              <Typography variant="h6" component={KnSectionHeader}>
                {patientInfo && translate('PATIENT_RECORD.medicationRegimen.pastTitle')}
              </Typography>
            </Box>
            <KnTitrationsList
              context={TITRATIONS_CONTEXT.pastRegimen}
              titrationsList={pastRegimens}
              onRetry={redoFetchPatientMedications}
            />
          </div>
        )
      }

    </KnPatientRecordBox>
  );
};

KnMedicationRegimen.propTypes = {
  patientId: PropTypes.string.isRequired,
};

export default KnMedicationRegimen;
