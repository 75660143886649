/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const KnImprovementIcon = (props) => (
  <SvgIcon {...props}>
    <path fill="#219653" d="M15 9l-1.057-1.058-4.193 4.185V3h-1.5v9.127L4.065 7.935 3 9l6 6 6-6z" />
  </SvgIcon>
);

export default KnImprovementIcon;
