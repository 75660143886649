/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { KnActionLink } from 'components/Link';
import KnEditIcon from 'components/icons/EditIcon';
import KnErrorMessage from 'components/ErrorMessage';
import KnTextBox from 'components/TextBox';
import { useIsUserHospitalStaff, withKeyNamespace } from 'utils/utils';
import { groupSymptomsBySeverity } from 'utils/metrics';
import { KnDarkerText, KnCardsSubtitle } from 'components/Typography';
import {
  KnThresholdDetailsBox,
  KnSymptomsTextBox,
} from './styles';

const i18nKey = withKeyNamespace('THRESHOLD');

const KnThresholdDetails = (props) => {
  const {
    thresholds,
    error,
    onRetry,
    onEditClick,
    SetThresholdButton,
  } = props;
  const { t: translate } = useTranslation();
  const userIsHospitalStaff = useIsUserHospitalStaff();

  const { seizure, symptoms } = thresholds || {};
  const aggregatedSymptoms = symptoms ? groupSymptomsBySeverity(symptoms) : null;

  return (
    <KnThresholdDetailsBox>
      {!error && thresholds && SetThresholdButton}
      <KnErrorMessage
        error={error}
        messageKey="PATIENT_RECORD.ERROR_MESSAGES.thresholdsFetchError"
        onRetry={onRetry}
        centered={false}
        mt={-2}
        data-testid="thresholds-fetch-error"
      />
      {!error && thresholds && !SetThresholdButton && (
        /** Show hint only when there's some threshold to edit */
        <Box mb={2} mt={-2}>
          <Typography variant="body2" component={KnCardsSubtitle}>
            {translate(i18nKey('editThresholdsHint'))}
          </Typography>
        </Box>
      )}
      <Box display="flex">
        {seizure && (
          <Box display="inline-block" mr={10}>
            <Box display="flex" justifyContent="space-between" mb={1} pr={1}>
              <Typography component={KnDarkerText}>
                {translate(i18nKey('seizure.title'))}
              </Typography>
              {userIsHospitalStaff && (
                <KnActionLink
                  data-testid="edit-thresholds-button"
                  LhsIcon={KnEditIcon}
                  onClick={onEditClick}
                >
                  {translate('GENERAL.editButton')}
                </KnActionLink>
              )}
            </Box>
            <Box display="flex">
              <KnTextBox
                wide="true"
                text={translate(i18nKey('seizure.suffix.seizure'), { count: seizure.count })}
                data-testid="seizure-threshold-count"
              />
              <KnTextBox
                wide="true"
                text={translate(i18nKey('seizure.suffix.day'), { count: seizure.days })}
                data-testid="seizure-threshold-days-count"
              />
            </Box>
          </Box>
        )}
        {aggregatedSymptoms && (aggregatedSymptoms.length > 0) && (
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-between">
              <Typography component={KnDarkerText}>
                {translate(i18nKey('symptoms.title'))}
              </Typography>
              {!seizure && userIsHospitalStaff && (
                <KnActionLink
                  data-testid="edit-thresholds-button"
                  LhsIcon={KnEditIcon}
                  onClick={onEditClick}
                >
                  {translate('GENERAL.editButton')}
                </KnActionLink>
              )}
            </Box>
            <Box pt={1}>
              {aggregatedSymptoms.map((group, index) => (
                <Box
                  display="flex"
                  key={group.severity}
                  data-testid={`symptom-threshold-wrapper-${index + 1}`}
                >
                  <KnTextBox text={group.severity.toString()} />
                  <KnSymptomsTextBox text={group.symptoms.join(', ')} />
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </KnThresholdDetailsBox>
  );
};

KnThresholdDetails.propTypes = {
  thresholds: PropTypes.shape({
    seizure: PropTypes.shape({
      count: PropTypes.number.isRequired,
      days: PropTypes.number.isRequired,
    }),
    sideEffects: PropTypes.arrayOf(PropTypes.shape()),
  }),
  error: PropTypes.bool,
  onRetry: PropTypes.func,
  onEditClick: PropTypes.func.isRequired,
  SetThresholdButton: PropTypes.node,
};

KnThresholdDetails.defaultProps = {
  thresholds: {},
  error: false,
  onRetry: () => {},
  SetThresholdButton: null,
};

export default KnThresholdDetails;
