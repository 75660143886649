/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import ACTION_TYPES from 'redux/actionTypes';

const initialState = {
  data: [],
  loading: false,
};

const userRoleReducer = (state = initialState, action) => {
  const { data, type } = action;

  switch (type) {
    case ACTION_TYPES.USER_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ACTION_TYPES.USER_ROLE_SUCCESS:
    case ACTION_TYPES.USER_ROLE_ERROR:
      return {
        ...state,
        loading: false,
        data: data || [],
      };

    default:
      return state;
  }
};

export { initialState };

export default userRoleReducer;
