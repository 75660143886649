/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import startOfDay from 'date-fns/startOfDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import {
  getMetricsTimeRange,
  countSeizureMetricsByType,
  aggregateLongTermSymptoms,
  aggregateLongTermMedicationAdherence,
} from 'utils/metrics';
import { withKeyNamespace } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import patientActions from 'redux/actions/patientActions';
import Typography from '@material-ui/core/Typography';
import KnErrorMessage from 'components/ErrorMessage';
import Box from '@material-ui/core/Box';
import { KnSectionHeader, KnSubtitleText } from 'components/Typography';
import { INSIGHTS_METRICS_PERIOD, SEIZURE_TYPES, MEDICATION_ADHERENCE_STATUS } from 'Constants';
import KnInsightsMetricsChart from './charts/InsightsMetricsChart';
import KnInsightsMetricsLegend from './charts/InsightsMetricsLegend';
import {
  KnPatientInsightsBox, KnInsightsMetricsHeaderBox,
  KnPatientInsightsSubtitleBox, KnPatientInsightsRangeBox,
} from './styles';

const i18nKey = withKeyNamespace('PATIENT_RECORD');


const KnInsightsMetrics = ({ patientId }) => {
  const dispatch = useDispatch();
  const [seizuresCounts, setSeizuresCounts] = useState({});
  const [toggledType, setToggledType] = useState({ type: '' });
  const [metricsRange, setMetricsRange] = useState(null);
  const [selectedRange, setSelectedRange] = useState(null);
  const { t: translate } = useTranslation();
  const {
    patientInfo,
    longTermSeizureMetrics,
    longTermSymptomsMetrics,
    longTermMedicationsMetrics,
  } = useSelector((state) => state.patientRecord);
  const seizureData = longTermSeizureMetrics.data;
  const symptomsData = longTermSymptomsMetrics.data;
  const medicationsData = longTermMedicationsMetrics.data;
  const aggregatedSeizureData = useMemo(() => (
    seizureData ? countSeizureMetricsByType(seizureData) : []
  ), [seizureData]);

  const aggregatedSymptomsData = useMemo(() => (
    (symptomsData && symptomsData.length)
      ? aggregateLongTermSymptoms(symptomsData)
      : []
  ), [symptomsData]);

  const aggregatedMedicationAdherenceData = useMemo(() => (
    (medicationsData && medicationsData.length)
      ? aggregateLongTermMedicationAdherence(medicationsData)
      : []
  ), [medicationsData]);

  useEffect(() => {
    /** We need patient timezone loaded, to decide the time range for the symptoms metrics */
    if (_.get(patientInfo, 'data.timeZone')) {
      const { startDate, endDate } = getMetricsTimeRange({
        timezone: _.get(patientInfo, 'data.timeZone'),
        offset: INSIGHTS_METRICS_PERIOD.days,
      });
      setMetricsRange({ startDate, endDate });
      dispatch(patientActions.fetchLongTermSeizureData(
        patientId,
        startDate,
        endDate,
      ));
      dispatch(patientActions.fetchLongTermSymptomData(
        patientId,
        startDate,
        endDate,
      ));
      dispatch(patientActions.fetchLongTermMedicationAdherenceData(
        patientId,
        startDate,
        endDate,
        MEDICATION_ADHERENCE_STATUS.missed,
      ));
    }
  }, [dispatch, patientId, patientInfo]);

  const updateRanges = useCallback((start, end) => {
    /**
     * The chart dates use the start of the day; we need to transform
     * the interval limits so no seizures are left uncounted.
     */
    const startDay = startOfDay(start);
    const endDay = startOfDay(end);
    const seizuresRangeData = _.filter(
      aggregatedSeizureData,
      (item) => isWithinInterval(item.date, { start: startDay, end: endDay }),
    );

    const counts = {};
    const seizureTypes = Object.values(SEIZURE_TYPES);
    seizureTypes.forEach((type) => {
      counts[type] = _.sumBy(seizuresRangeData, type) || 0;
    });

    setSeizuresCounts(counts);
    setSelectedRange({ startDay, endDay });
  }, [aggregatedSeizureData]);

  const onLegendFilterSelected = useCallback((type) => {
    setToggledType({ type });
  }, []);

  /** Retry handlers for error cases */
  const redoFetchSeizures = useCallback(() => {
    dispatch(patientActions.fetchLongTermSeizureData(
      patientId,
      metricsRange.startDate,
      metricsRange.endDate,
    ));
  }, [dispatch, patientId, metricsRange]);

  const redoFetchSymptoms = useCallback(() => {
    dispatch(patientActions.fetchLongTermSymptomData(
      patientId,
      metricsRange.startDate,
      metricsRange.endDate,
    ));
  }, [dispatch, patientId, metricsRange]);

  const redoFetchMedicationAdherence = useCallback(() => {
    dispatch(patientActions.fetchLongTermMedicationAdherenceData(
      patientId,
      metricsRange.startDate,
      metricsRange.endDate,
      MEDICATION_ADHERENCE_STATUS.missed,
    ));
  }, [dispatch, patientId, metricsRange]);

  return (
    <KnPatientInsightsBox mb={2} flex="1">
      <KnErrorMessage
        error={longTermSeizureMetrics.error}
        messageKey={i18nKey('ERROR_MESSAGES.seizuresFetchError')}
        onRetry={redoFetchSeizures}
        centered={false}
        mb={2}
      />

      <KnErrorMessage
        error={longTermSymptomsMetrics.error}
        messageKey={i18nKey('ERROR_MESSAGES.symptomsFetchError')}
        onRetry={redoFetchSymptoms}
        centered={false}
        mb={2}
      />

      <KnErrorMessage
        error={longTermMedicationsMetrics.error}
        messageKey={i18nKey('ERROR_MESSAGES.medicationAdherenceFetchError')}
        onRetry={redoFetchMedicationAdherence}
        centered={false}
        mb={2}
      />

      {seizureData && (
        <>
          <KnInsightsMetricsHeaderBox>
            <Typography variant="h6" component={KnSectionHeader}>
              {translate(
                i18nKey('seizureInsights.title'),
                { count: _.sum(Object.values(seizuresCounts)) },
              )}
            </Typography>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <KnPatientInsightsSubtitleBox>
                <Typography component={KnSubtitleText}>
                  {translate(i18nKey('seizureInsights.subtitle'))}
                </Typography>
              </KnPatientInsightsSubtitleBox>
              <KnPatientInsightsRangeBox>
                <Typography component={KnSubtitleText}>
                  {translate(
                    i18nKey('seizureInsights.selectedRange'),
                    selectedRange,
                  )}
                </Typography>
              </KnPatientInsightsRangeBox>
            </Box>
          </KnInsightsMetricsHeaderBox>
          {metricsRange && (
            <KnInsightsMetricsChart
              seizureData={aggregatedSeizureData}
              symptomsData={aggregatedSymptomsData}
              medicationsData={aggregatedMedicationAdherenceData}
              updateRanges={updateRanges}
              toggledSeizure={toggledType}
              startDate={metricsRange.startDate}
              endDate={metricsRange.endDate}
            />
          )}
          <KnInsightsMetricsLegend
            seizuresCounts={seizuresCounts}
            onLegendFilterSelected={onLegendFilterSelected}
            hideSymptomsLegend={!aggregatedSymptomsData}
            hideMedicationsLegend={!aggregatedMedicationAdherenceData}
          />
        </>
      )}
    </KnPatientInsightsBox>
  );
};

KnInsightsMetrics.propTypes = {
  patientId: PropTypes.string.isRequired,
};

export default KnInsightsMetrics;
