/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { Auth } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { parsePhoneNumber } from 'libphonenumber-js';
import ACTION_TYPES from 'redux/actionTypes';
import appActions from 'redux/actions/appActions';
import {
  ERROR_CODES,
  API_URLS, API_REQUEST_METHODS,
  DEFAULT_COUNTRY_CODE,
} from 'Constants';
import apiRequest from './apiActions';

const handleRegisterFailure = (dispatch, errorCode) => {
  dispatch(appActions.appPushNotification(`REGISTER.ERROR_MESSAGES.${errorCode}`));
  return dispatch({ type: ACTION_TYPES.REGISTER_ERROR, error: errorCode });
};

/** Transforms phone number into E164 format, for the Cognito call
 * @param {string} value Phone number as entered by the user
 * @returns {string} Phone number in E164 format
 */
const preparePhoneNumber = (value) => {
  try {
    const phoneNumber = parsePhoneNumber(value, DEFAULT_COUNTRY_CODE);
    return phoneNumber.number;
  } catch (e) {
    /** We should never get here */
    return '';
  }
};

const mapLocalUserDataToServer = (userData) => ({
  email: userData.email.trim().toLowerCase(),
  phoneNumber: preparePhoneNumber(userData.phone),
  healthSystemId: userData.healthSystemId,
  firstName: userData.firstName.trim(),
  lastName: userData.lastName.trim(),
  hcpRole: userData.userRole,
});

/**
 * Register (sign up) action creator,
 * performs the first step of new user sign up
 * @param signUpData Object containing email, password and phone
 */
const signUp = (signUpData) => {
  const request = () => ({ type: ACTION_TYPES.REGISTER_REQUEST });
  const success = () => ({ type: ACTION_TYPES.REGISTER_SUCCESS, registering: true });

  return (dispatch) => {
    dispatch(request());
    dispatch(appActions.appPushBusy());
    dispatch(appActions.appPopNotification());

    const username = uuidv4();
    const phoneNumber = preparePhoneNumber(signUpData.phone);

    /** Call Cognito sign up */
    return Auth.signUp({
      /** Send a random UUID for username */
      username,
      password: signUpData.password,
      attributes: {
        email: signUpData.email.trim().toLowerCase(),
        phone_number: phoneNumber,
      },
    })
      .then(
        () => {
          dispatch(success());
          /** Let's just return the response to the caller (sign up card) */
          return { username };
        },
        () => handleRegisterFailure(dispatch, ERROR_CODES.SIGNUP_ERROR),
      )
      .catch(() => handleRegisterFailure(dispatch, ERROR_CODES.SIGNUP_ERROR))
      .finally(() => dispatch(appActions.appPopBusy()));
  };
};

/**
 * Register action creator,
 * performs the second (confirm sign up) step of the registration
 * @param username Username (UUID) used in sign up request
 * @param code Confirmation code
 */
const confirmSignUp = (username, code, userData) => {
  const request = () => ({ type: ACTION_TYPES.REGISTER_REQUEST });
  const success = () => ({ type: ACTION_TYPES.REGISTER_SUCCESS, registering: true });

  return (dispatch) => {
    dispatch(request());
    dispatch(appActions.appPushBusy());
    dispatch(appActions.appPopNotification());

    const clientMetadata = mapLocalUserDataToServer(userData);
    /** healthSystemId must be a string for cognito */
    clientMetadata.healthSystemId += '';

    const options = { clientMetadata };

    /** Call Cognito confirm sign up */
    return Auth.confirmSignUp(username, code.trim(), options)
      .then(
        () => dispatch(success()),
        (error) => {
          switch (error.code) {
            /** Handling different error scenarios */
            case ERROR_CODES.CODE_MISMATCH:
            case ERROR_CODES.EXPIRED_CODE:
              return handleRegisterFailure(dispatch, error.code);
            case ERROR_CODES.LAMBDA_EXCEPTION:
              /** There's no specific error code when account already exists,
               * the hint is in the error message, so let's look for it
               */
              if (error.message.indexOf(ERROR_CODES.DUPLICATE_ACCOUNT) > -1) {
                return handleRegisterFailure(dispatch, ERROR_CODES.DUPLICATE_ACCOUNT);
              }
              /** else go with default error */
              return handleRegisterFailure(dispatch, ERROR_CODES.ACCOUNT_VERIFY_ERROR);
            default:
              /** Show same generic error, regardless of the cause */
              return handleRegisterFailure(dispatch, ERROR_CODES.ACCOUNT_VERIFY_ERROR);
          }
        },
      )
      .catch(() => handleRegisterFailure(dispatch, ERROR_CODES.ACCOUNT_VERIFY_ERROR))
      .finally(() => dispatch(appActions.appPopBusy()));
  };
};


/**
 * Register action creator,
 * performs resending the confirmation code
 * @param username Username (UUID) used in sign up request
 */
const resendSignUp = (username) => {
  const request = () => ({ type: ACTION_TYPES.REGISTER_REQUEST });
  const success = () => ({ type: ACTION_TYPES.REGISTER_SUCCESS, registering: true });

  return (dispatch) => {
    dispatch(request());
    dispatch(appActions.appPushBusy());
    dispatch(appActions.appPopNotification());

    /** Call Cognito resend sign up */
    return Auth.resendSignUp(username)
      .then(
        () => {
          dispatch(appActions.appPushNotification(
            'FORGOT_PASSWORD.SUCCESS_MESSAGES.forgotPasswordResendSuccess',
          ));
          return dispatch(success());
        },
        () => handleRegisterFailure(dispatch, ERROR_CODES.RESEND_SIGNUP_ERROR),
      )
      .catch(() => handleRegisterFailure(dispatch, ERROR_CODES.RESEND_SIGNUP_ERROR))
      .finally(() => dispatch(appActions.appPopBusy()));
  };
};


/**
 * Register action creator,
 * performs registering the newly created user into our DB
 * @param userData Object containing firstName, lastName, email, phoneNumber, healthSystem
 */
const registerUser = (userData) => {
  const request = () => ({ type: ACTION_TYPES.REGISTER_REQUEST });
  const success = () => ({ type: ACTION_TYPES.REGISTER_SUCCESS, registering: false });

  const payload = mapLocalUserDataToServer(userData);

  return async (dispatch) => {
    dispatch(request());

    return dispatch(apiRequest(API_REQUEST_METHODS.POST, API_URLS.users, payload))
      .then(() => dispatch(success()))
      .catch(() => handleRegisterFailure(dispatch, ERROR_CODES.SIGNUP_ERROR));
  };
};

/** Action used to clear error from the Register page */
const clearError = () => ({ type: ACTION_TYPES.REGISTER_CLEAR_ERROR });

/** Action used to reset reducer */
const reset = () => ({ type: ACTION_TYPES.REGISTER_RESET });

/**
 * Collection of the exposed register-related action creators
 */
export default {
  signUp,
  confirmSignUp,
  resendSignUp,
  registerUser,
  clearError,
  reset,
};
