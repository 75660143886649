/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { appInsights } from 'appInsights';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/core/styles';
import { TRACKING_EVENTS } from 'Constants';
import { KnBoldSectionHeader, KnSectionHeader } from 'components/Typography';
import KnTextField from 'components/TextField';
import KnButton from 'components/Button';
import healthSystemActions from 'redux/actions/healthSystemActions';
import userRoleActions from 'redux/actions/userRoleActions';
import {
  emailFormatValidator,
  nameFormatValidator,
  passwordFormatValidator,
  passwordMatchValidator,
  passwordEqualsEmailAddress,
  trimValueEmptyValidator,
  phoneNumberValidator,
} from 'utils/customFieldValidators';
import registerActions from 'redux/actions/registerActions';

const KnBoldSectionHeaderSignUp = styled(KnBoldSectionHeader)({
  marginBottom: 24,
});

const initialRegisterInfo = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  password: '',
  confirmPassword: '',
  healthSystemId: '',
  userRole: '',
};

const SignUpStep = ({ submitDisabled, onSuccess }) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const {
    data: HSProviders,
    loading: loadingHSProviders,
  } = useSelector((state) => state.healthSystem);
  const {
    data: UserRoles,
    loading: loadingUserRoles,
  } = useSelector((state) => state.userRole);
  const {
    handleSubmit, errors, control, triggerValidation,
  } = useForm({
    mode: 'onBlur',
    defaultValues: initialRegisterInfo,
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    appInsights.trackEvent({ name: TRACKING_EVENTS.viewRegisterPage });
    dispatch(healthSystemActions.fetch());
    dispatch(userRoleActions.fetch());
  }, [dispatch]);

  /** We can cache the validation rules, as they should change only if the
   * translation is updated so that the messages are in the correct language.
   */
  const firstNameValidationRules = useMemo(() => ({
    validate: {
      trimValueEmpty: (value) => (
        trimValueEmptyValidator(value)
          ? translate('REGISTER.FIELD_VALIDATION_MESSAGES.firstName.required')
          : true
      ),
      nameFormat: nameFormatValidator,
    },
  }), [translate]);

  const lastNameValidationRules = useMemo(() => ({
    validate: {
      trimValueEmpty: (value) => (
        trimValueEmptyValidator(value)
          ? translate('REGISTER.FIELD_VALIDATION_MESSAGES.lastName.required')
          : true
      ),
      nameFormat: nameFormatValidator,
    },
  }), [translate]);

  const emailValidationRules = useMemo(() => ({
    required: {
      value: true,
      message: translate('REGISTER.FIELD_VALIDATION_MESSAGES.email.required'),
    },
    validate: {
      emailFormat: emailFormatValidator,
    },
  }), [translate]);

  const phoneNumberValidationRules = useMemo(() => ({
    validate: {
      trimValueEmpty: (value) => (
        trimValueEmptyValidator(value)
          ? translate('REGISTER.FIELD_VALIDATION_MESSAGES.phone.required')
          : true
      ),
      phoneNumber: (value) => (
        phoneNumberValidator(value)
          ? true
          : translate('FIELD_VALIDATION_MESSAGES.phoneNumber')
      ),
    },
  }), [translate]);

  const passwordValidationRules = useMemo(() => ({
    required: {
      value: true,
      message: translate('REGISTER.FIELD_VALIDATION_MESSAGES.password.required'),
    },
    validate: {
      weakPassword: (value) => (
        passwordFormatValidator(value)
          ? true
          : translate('REGISTER.FIELD_VALIDATION_MESSAGES.password.weak')
      ),
      equalsEmailAddress: (value) => {
        const emailAddress = control.getValues('email');
        if (emailAddress && value) {
          return !passwordEqualsEmailAddress(value, emailAddress)
            ? true
            : translate('REGISTER.FIELD_VALIDATION_MESSAGES.password.equalsEmailAddress');
        }
        /** Marking it valid until we have both password and email values. */
        return true;
      },
    },
  }), [control, translate]);

  const confirmPasswordValidationRules = useMemo(() => ({
    required: {
      value: true,
      message: translate('REGISTER.FIELD_VALIDATION_MESSAGES.confirmPassword.required'),
    },
    validate: {
      matchPassword: (confirmPwdValue) => {
        const passwordValue = control.getValues('password');
        if (passwordValue && confirmPwdValue) {
          return passwordMatchValidator(passwordValue, confirmPwdValue)
            ? true
            : translate('REGISTER.FIELD_VALIDATION_MESSAGES.confirmPassword.matchPassword');
        }
        /** Marking it valid until we have both password and confirm pasword values. */
        return true;
      },
    },
  }), [control, translate]);

  /** Both Health System and User Role are required,
   * and validation message is generic
   */
  const dropDownValidationRules = useMemo(() => ({
    required: {
      value: true,
      message: translate('REGISTER.FIELD_VALIDATION_MESSAGES.dropDown.required'),
    },
  }), [translate]);

  const handleDropDownChange = useCallback(([{ target: { value } }]) => value, []);

  const onPasswordBlur = useCallback(() => {
    /** On password blur, if there is a value in the confirm password,
     * trigger the validation of confirm password field manually,
     * in case a mismatch password error was fixed.
     */
    if (control.getValues('confirmPassword')) {
      triggerValidation('confirmPassword');
    }
  }, [control, triggerValidation]);

  /** This function will be called by handleSubmit with form data
   * only when the data is valid. We can make the sign up call now.
   */
  const onSubmit = (formData, e) => {
    e.preventDefault();
    dispatch(registerActions.signUp({
      email: formData.email,
      password: formData.password,
      phone: formData.phone,
    }))
      .then((response) => {
        if (!response.error) {
          /** Sign up was successful,
           * send all gathered data to step 2 of the sign up flow */
          const data = {
            ...formData,
            username: response.username,
          };
          onSuccess(data);
        }
      });
  };

  return (
    <>
      <Typography variant="h6" component={KnBoldSectionHeaderSignUp}>
        {translate('REGISTER.registrationTitle')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          variant="h6"
          component={KnSectionHeader}
          data-testid="personal-info-subtitle"
        >
          {translate('REGISTER.personalInfoSubtitle')}
        </Typography>
        <Controller
          as={KnTextField}
          name="firstName"
          rules={firstNameValidationRules}
          label={translate('FIELD_LABELS.firstName')}
          inputProps={{ 'data-testid': 'first-name-input-field', maxLength: 30 }}
          InputLabelProps={{ 'data-testid': 'first-name-input-label' }}
          error={errors.firstName}
          control={control}
        />
        <Controller
          as={KnTextField}
          name="lastName"
          rules={lastNameValidationRules}
          label={translate('FIELD_LABELS.lastName')}
          inputProps={{ 'data-testid': 'last-name-input-field', maxLength: 30 }}
          InputLabelProps={{ 'data-testid': 'last-name-input-label' }}
          error={errors.lastName}
          control={control}
        />
        <Controller
          as={KnTextField}
          name="email"
          rules={emailValidationRules}
          label={translate('GENERAL.email')}
          inputProps={{ 'data-testid': 'email-input-field', maxLength: 256 }}
          InputLabelProps={{ 'data-testid': 'email-input-label' }}
          error={errors.email}
          control={control}
        />
        <Controller
          as={KnTextField}
          name="phone"
          rules={phoneNumberValidationRules}
          label={translate('GENERAL.phone')}
          inputProps={{ 'data-testid': 'phone-input-field', maxLength: 256 }}
          InputLabelProps={{ 'data-testid': 'phone-input-label' }}
          error={errors.phone}
          control={control}
        />
        <Typography paragraph>{translate('REGISTER.phoneHint')}</Typography>
        <Typography
          variant="h6"
          component={KnSectionHeader}
          data-testid="password-subtitle"
        >
          {translate('REGISTER.passwordSubtitle')}
        </Typography>
        <Controller
          as={KnTextField}
          name="password"
          type="password"
          rules={passwordValidationRules}
          label={translate('FIELD_LABELS.password')}
          onBlur={onPasswordBlur}
          inputProps={{ 'data-testid': 'password-input-field', maxLength: 256 }}
          InputLabelProps={{ 'data-testid': 'password-input-label' }}
          error={errors.password}
          control={control}
        />
        <Controller
          as={KnTextField}
          name="confirmPassword"
          type="password"
          rules={confirmPasswordValidationRules}
          label={translate('FIELD_LABELS.confirmPassword')}
          inputProps={{ 'data-testid': 'confirm-password-input-field', maxLength: 256 }}
          InputLabelProps={{ 'data-testid': 'confirm-password-input-label' }}
          error={errors.confirmPassword}
          control={control}
        />
        <Typography paragraph>{translate('REGISTER.passwordHint')}</Typography>
        <Typography
          variant="h6"
          component={KnSectionHeader}
          data-testid="health-system-subtitle"
        >
          {translate('REGISTER.healthSystemSubtitle')}
        </Typography>
        <Controller
          select
          as={KnTextField}
          name="healthSystemId"
          rules={dropDownValidationRules}
          label={translate('FIELD_LABELS.healthSystem')}
          inputProps={{ 'data-testid': 'health-system-input-field', maxLength: 256 }}
          InputLabelProps={{ 'data-testid': 'health-system-input-label' }}
          onChange={handleDropDownChange}
          error={errors.healthSystemId}
          control={control}
          disabled={loadingHSProviders}
        >
          {
            HSProviders.map((option, index) => (
              <MenuItem
                key={option.id}
                value={option.id}
                data-testid={`health-system-option-${index + 1}`}
              >
                {option.name}
              </MenuItem>
            ))
          }
        </Controller>

        <Controller
          select
          as={KnTextField}
          name="userRole"
          rules={dropDownValidationRules}
          label={translate('FIELD_LABELS.userRole')}
          inputProps={{ 'data-testid': 'user-role-input-field', maxLength: 256 }}
          InputLabelProps={{ 'data-testid': 'user-role-input-label' }}
          onChange={handleDropDownChange}
          error={errors.userRole}
          control={control}
          disabled={loadingUserRoles}
        >
          {
            UserRoles.map((option, index) => (
              <MenuItem
                key={option.id}
                value={option.id}
                data-testid={`health-system-option-${index + 1}`}
              >
                {option.name}
              </MenuItem>
            ))
           }
        </Controller>

        <Box
          pt={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <KnButton
            variant="text"
            type="button"
            route="/"
            data-testid="login-link-button"
          >
            {translate('REGISTER.backToLogin')}
          </KnButton>
          <KnButton
            data-testid="register-button"
            disabled={submitDisabled}
          >
            {translate('REGISTER.submitButton')}
          </KnButton>
        </Box>
      </form>
    </>
  );
};

SignUpStep.propTypes = {
  submitDisabled: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default SignUpStep;
