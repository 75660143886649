/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { KnBoldSectionHeader } from 'components/Typography';
import KnButton from 'components/Button';

const PasswordConfirmedStep = () => {
  const { t: translate } = useTranslation();
  return (
    <>
      <Typography variant="h6" component={KnBoldSectionHeader} marginBottom>
        {translate('FORGOT_PASSWORD.passwordConfirmedTitle')}
      </Typography>
      <Typography paragraph>{translate('FORGOT_PASSWORD.passwordConfirmedHint')}</Typography>
      <Box
        pt={2}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <KnButton
          route="/"
          data-testid="login-button"
        >
          {translate('LOGIN.submitButton')}
        </KnButton>
      </Box>
    </>
  );
};

export default PasswordConfirmedStep;
