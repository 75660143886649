/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import { KnBoldSectionHeader, KnSectionHeader, KnSubtleText } from 'components/Typography';
import KnPageContent, { KnBrightBox } from 'components/Content';
import KnValidatedTextField from 'components/ValidatedTextField';
import { KnContrastTextField } from 'components/TextField';
import KnButton from 'components/Button';
import KnSuccessConfirmationCard from 'components/SuccessConfirmationCard';
import { withKeyNamespace } from 'utils/utils';
import patientActions from 'redux/actions/patientActions';
import { APP_PAGE_URLS, EMAIL_FORMAT } from 'Constants';

const i18nKey = withKeyNamespace('STUDY_PATIENTS');

const KnWrapperBox = styled(Box)({
  width: 415,
});

const InvitePatientsPage = () => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const {
    handleSubmit, errors, control, formState: { dirty, isSubmitting },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const [successfulInvite, setSuccessfulInvite] = useState(false);

  const onSubmit = useCallback(({ patientEmail }) => {
    dispatch(patientActions.inviteStudyPatient(patientEmail)).then(() => {
      setSuccessfulInvite(true);
    });
  }, [dispatch]);

  return (
    <KnPageContent>
      {!successfulInvite && (
        <>
          <Typography variant="h4" component={KnBoldSectionHeader}>
            {translate(i18nKey('title'))}
          </Typography>

          <KnBrightBox display="flex" flexDirection="column" flex="1" p={2} mt={2}>
            <KnWrapperBox>
              <Typography variant="h6" component={KnSectionHeader}>
                {translate('GENERAL.email')}
              </Typography>
              <Box pb={2}>
                <Typography variant="body2" component={KnSubtleText}>
                  {translate(i18nKey('hint'))}
                </Typography>
              </Box>

              <form onSubmit={handleSubmit(onSubmit)}>
                <KnValidatedTextField
                  Component={KnContrastTextField}
                  name="patientEmail"
                  control={control}
                  errors={errors}
                  required
                  trimSpaces
                  format={EMAIL_FORMAT}
                  defaultValue=""
                />
                <KnButton data-testid="save-button" disabled={!dirty || isSubmitting}>
                  {translate(i18nKey('submitButton'))}
                </KnButton>
              </form>
            </KnWrapperBox>
          </KnBrightBox>
        </>
      )}

      {successfulInvite && (
        <KnSuccessConfirmationCard
          message={translate(i18nKey('successMessage'))}
          buttonLabel={translate(i18nKey('viewPatients'))}
          buttonProps={{
            route: APP_PAGE_URLS.home,
            'data-testid': 'view-patients-button',
          }}
          data-testid="patient-invited-confirmation-card"
        />
      )}
    </KnPageContent>
  );
};

export default InvitePatientsPage;
