/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

const KnAccountIcon = ({ height, width, ...rest }) => (
  <SvgIcon
    width={width}
    height={height}
    fill="none"
    viewBox={`0 0 ${width} ${height}`}
    {...rest}
  >
    <path fill="#666" fillRule="evenodd" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zM6 15.98c1.29 1.94 3.5 3.22 6 3.22s4.71-1.28 6-3.22c-.03-1.99-4.01-3.08-6-3.08-2 0-5.97 1.09-6 3.08z" clipRule="evenodd" />
  </SvgIcon>
);

KnAccountIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

KnAccountIcon.defaultProps = {
  height: 24,
  width: 24,
};

export default KnAccountIcon;
