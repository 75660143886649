/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, {
  useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useUserStatus from 'utils/userStatus';
import KnPageContent from 'components/Content';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import appActions from 'redux/actions/appActions';
import { APP_PAGE_URLS } from 'Constants';
import SignInStep from './SignInStep';
import MFAStep from './MFAStep';

const stepComponents = [SignInStep, MFAStep];

const LoginPage = () => {
  const { t: translate } = useTranslation();
  const loginStore = useSelector((state) => state.login);
  const appStore = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  /** This will accummulate all the data gathered step by step */
  const stepsData = useRef({});
  const StepComponent = stepComponents[currentStep];
  const userStatus = useUserStatus();
  const history = useHistory();

  useEffect(() => {
    if (userStatus) {
      /** We have a valid user sessions, so we should be inside the app
       * Note: this is used to prevent browser's Back button to get back to Login page
       */
      history.push(APP_PAGE_URLS.home);
    }
  }, [userStatus, history]);

  /**
   * Called when a card (step) succeeds
   */
  const nextStep = (response) => {
    stepsData.current = {
      ...stepsData.current,
      ...response,
    };
    if (currentStep < stepComponents.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      /** Last step done, ready to navigate into the app
       * Note: this happens anyways, as Login page redirects to home, when authenticated
       */
      history.push(APP_PAGE_URLS.home);
    }
  };

  /**
   * Called when flow is cancelled
   */
  const resetFlow = () => {
    dispatch(appActions.appPopNotification());
    setCurrentStep(0);
  };

  return (
    <KnPageContent dark>
      <Card elevation={0}>
        <CardContent>
          <StepComponent
            stepsData={stepsData.current}
            submitDisabled={loginStore.busy}
            onSuccess={nextStep}
            onReset={resetFlow}
          />
          <Box
            pt={7}
            display="flex"
            justifyContent="center"
          >
            {translate('GENERAL.versionLabel', { version: process.env.REACT_APP_VERSION })}
          </Box>
          <Box display="flex" justifyContent="center">
            {translate('GENERAL.apiVersionLabel', { apiVersion: appStore.apiVersion })}
          </Box>
        </CardContent>
      </Card>
    </KnPageContent>
  );
};

export default LoginPage;
