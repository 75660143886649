/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import KnButton from 'components/Button';

const KnStyledButton = withStyles({
  label: {
    display: 'contents',
  },
})(KnButton);

const KnIconButton = ({ Icon, ...rest }) => (
  <KnStyledButton
    variant="text"
    size="small"
    {...rest}
  >
    <Icon />
  </KnStyledButton>
);

KnIconButton.propTypes = {
  Icon: PropTypes.func.isRequired,
};

export default KnIconButton;
