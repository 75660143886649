/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import format from 'date-fns/format';
import { appInsights } from 'appInsights';
import ACTION_TYPES from 'redux/actionTypes';
import {
  PATIENT_LINKS_STATUS,
  API_URLS, API_REQUEST_METHODS, API_DATE_FORMAT,
  THRESHOLD_EVENT_TYPES,
  TRACKING_EVENTS,
} from 'Constants';
import patientMappings from './mappingFunctions/patientMappings';
import titrationMappings from './mappingFunctions/titrationMappings';
import thresholdsMappings from './mappingFunctions/thresholdsMappings';
import appActions from './appActions';
import apiRequest from './apiActions';

const REGIMENS_DEFAULT_SORT_KEY = 'assignedAt';
const REGIMENS_DEFAULT_SORT_ORDER = 'desc';

/**
 * Action to request patient seizure data given a date range.
 *
 * @param {string} patientId The patient id.
 * @param {Date} startDate The start date of the interested time range.
 * @param {Date} endDate The end date of the interested time range.
 * @param {object} actions Object with request, success and error actions to be dispatched.
 */
const requestSeizureData = (patientId, startDate, endDate, actions) => {
  const request = () => ({ type: actions.request });
  const success = (data) => ({ type: actions.success, data });
  const failure = () => ({ type: actions.error });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.patientSeizures.replace(':patientId', patientId),
          null,
          {
            start: format(startDate, API_DATE_FORMAT),
            end: format(endDate, API_DATE_FORMAT),
          },
        ),
      );

      dispatch(success(patientMappings.mapServerPatientSeizuresToLocal(response.data)));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

/**
 * Action to request patient symptoms data given a date range.
 *
 * @param {string} patientId The patient id.
 * @param {Date} startDate The start date of the interested time range.
 * @param {Date} endDate The end date of the interested time range.
 * @param {object} actions Object with request, success and error actions to be dispatched.
 */
const requestSymptomData = (patientId, startDate, endDate, actions) => {
  const request = () => ({ type: actions.request });
  const success = (data) => ({ type: actions.success, data });
  const failure = () => ({ type: actions.error });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.patientSymptoms.replace(':patientId', patientId),
          null,
          {
            start: format(startDate, API_DATE_FORMAT),
            end: format(endDate, API_DATE_FORMAT),
          },
        ),
      );

      dispatch(success(patientMappings.mapServerPatientSymptomsToLocal(response.data)));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

/**
 * Action to fetch the patients list:
 * unverified, accepted or unlinked patients
 *
 * @param {string} status Patient list status.
 * @param {boolean} silent Logical flag to mark if the request should show the progress bar.
 */
const fetchPatients = (status, silent = true) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_LIST_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_LIST_SUCCESS, status, data });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_LIST_ERROR, status });

  return async (dispatch, getState) => {
    try {
      const { physicianFilter } = getState().patientsList;
      let statusToSend = status;
      let hcpId = physicianFilter || undefined;
      if (status === PATIENT_LINKS_STATUS.unlinked) {
        /** When fetching unlinked patients,
         * we don't want to send the status parameter, nor doctor filter
         * `undefined` is the proper value, when we don't want to send a param
         */
        statusToSend = undefined;
        hcpId = undefined;
      }
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          status === PATIENT_LINKS_STATUS.unlinked
            ? API_URLS.patientDeactivatedLinks : API_URLS.patientLinks,
          null,
          {
            status: statusToSend,
            hcpId,
          },
          { silent },
        ),
      );

      dispatch(success(patientMappings.mapServerPatientsListToLocal(response.data)));
    } catch (errorCode) {
      dispatch(failure(errorCode));
    }
  };
};

/**
 * Action to accept or reject a patient link.
 *
 * @param {number} linkId The ID value of the patient link to verify.
 * @param {boolean} accepted Whether the patient was accepted or rejected.
 */
const verifyPatient = (linkId, accepted) => {
  const success = () => (
    accepted
      ? { type: ACTION_TYPES.PATIENT_ACCEPTED, linkId }
      : { type: ACTION_TYPES.PATIENT_REJECTED, linkId }
  );

  return async (dispatch) => {
    try {
      await dispatch(
        apiRequest(
          API_REQUEST_METHODS.PATCH,
          `${API_URLS.patientLinks}/${linkId}`,
          {
            status: accepted ? PATIENT_LINKS_STATUS.accepted : PATIENT_LINKS_STATUS.rejected,
          },
        ),
      );
      dispatch(success());
    } catch (errorCode) {
      dispatch(appActions.appPushNotification(
        accepted
          ? 'HOME.ERROR_MESSAGES.patientAcceptError'
          : 'HOME.ERROR_MESSAGES.patientRejectError',
      ));
    }
  };
};

/**
 * Action to sort the verified patients list.
 * Sort is done in the patient reducer.
 *
 * @param {string} sortKey The field (key) by which the patients list should be sorted
 * if it is the same as the one by which is currently sorted, it will reverse the order
 */
const sortPatients = (sortKey) => ({
  type: ACTION_TYPES.PATIENT_LIST_SORT,
  sortKey,
});

/**
 * Action to set data of a patient into the redux store
 * @param {object} data Patient information
 */
const setPatientInfo = (data) => ({
  type: ACTION_TYPES.PATIENT_SET_INFO,
  data,
});

/**
 * Action to filter patients by physician, it:
 *
 * @param {number} physicianId The id of the physician to filter by, 0 for no filtering
 * @param {boolean} unverified Logical flag for which patients list to filter right away
 */
const filterPatientsByPhysician = (physicianId, status) => {
  const setFilter = (id) => ({
    type: ACTION_TYPES.PATIENT_LIST_PHYSICIAN_FILTER,
    physicianId: id,
  });

  return async (dispatch) => {
    /** setting the filter value into the redux state, for all future fetches */
    dispatch(setFilter(physicianId));
    dispatch(fetchPatients(status, false));
  };
};

/**
 * Action to fetch basic patient information
 * @param {number} patientId
 */
const fetchPatientInfo = (patientId) => {
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_SET_INFO, data });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_ERROR });

  return async (dispatch) => {
    dispatch({ type: ACTION_TYPES.PATIENT_CLEAR_ERROR });
    try {
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.patientInfo.replace(':patientId', patientId),
        ),
      );
      dispatch(success(patientMappings.mapServerPatientInfoToLocal(response.data)));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

/** Action to fetch patient seizure top line metrics data. */
const fetchTopLineSeizureData = (patientId, startDate, endDate) => requestSeizureData(
  patientId,
  startDate,
  endDate,
  {
    success: ACTION_TYPES.PATIENT_TOPLINE_SEIZURES_SUCCESS,
    request: ACTION_TYPES.PATIENT_TOPLINE_SEIZURES_REQUEST,
    error: ACTION_TYPES.PATIENT_TOPLINE_SEIZURES_ERROR,
  },
);

/** Action to fetch long term patient seizure metrics data. */
const fetchLongTermSeizureData = (patientId, startDate, endDate) => requestSeizureData(
  patientId,
  startDate,
  endDate,
  {
    success: ACTION_TYPES.PATIENT_LONG_TERM_SEIZURES_SUCCESS,
    request: ACTION_TYPES.PATIENT_LONG_TERM_SEIZURES_REQUEST,
    error: ACTION_TYPES.PATIENT_LONG_TERM_SEIZURES_ERROR,
  },
);

/** Action to fetch patient symptoms top line metrics data. */
const fetchTopLineSymptomData = (patientId, startDate, endDate) => requestSymptomData(
  patientId,
  startDate,
  endDate,
  {
    request: ACTION_TYPES.PATIENT_TOPLINE_SYMPTOMS_REQUEST,
    success: ACTION_TYPES.PATIENT_TOPLINE_SYMPTOMS_SUCCESS,
    error: ACTION_TYPES.PATIENT_TOPLINE_SYMPTOMS_ERROR,
  },
);

/** Action to fetch long term patient symptoms metrics data. */
const fetchLongTermSymptomData = (patientId, startDate, endDate) => requestSymptomData(
  patientId,
  startDate,
  endDate,
  {
    success: ACTION_TYPES.PATIENT_LONG_TERM_SYMPTOMS_SUCCESS,
    request: ACTION_TYPES.PATIENT_LONG_TERM_SYMPTOMS_REQUEST,
    error: ACTION_TYPES.PATIENT_LONG_TERM_SYMPTOMS_ERROR,
  },
);

/** Action to fetch long term patient medication adherence metrics data. */
const fetchLongTermMedicationAdherenceData = (patientId, startDate, endDate, status) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_LONG_TERM_MEDICATION_ADHERENCE_REQUEST });
  const success = (data) => ({
    type: ACTION_TYPES.PATIENT_LONG_TERM_MEDICATION_ADHERENCE_SUCCESS,
    data,
  });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_LONG_TERM_MEDICATION_ADHERENCE_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.patientMedicationAdherence.replace(':patientId', patientId),
          null,
          {
            start: format(startDate, API_DATE_FORMAT),
            end: format(endDate, API_DATE_FORMAT),
            status,
          },
        ),
      );

      dispatch(success(patientMappings.mapServerPatientMedicationAdherenceToLocal(response.data)));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

const fetchLatestSurveyResults = (patientId) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_SURVEYS_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_SURVEYS_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_SURVEYS_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.patientSurveys.replace(':patientId', patientId),
        ),
      );

      dispatch(success(patientMappings.mapServerPatientSurveyResultsToLocal(response.data)));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

/**
 * Action to assign or edit medication regimen to a patient
 *
 * @param {object} medications Medication regimen to be assigned to the patient
 * @param {number} patientId Id of the patient
 * @param {number} [regimenId] Id of the regimen, set only when this is an edit (update) action
 */
const assignRegimen = (medications, patientId, regimenId) => {
  const success = () => ({ type: ACTION_TYPES.PATIENT_REGIMEN_ASSIGNED });

  const url = regimenId
    ? API_URLS.patientRegimen.replace(':patientId', patientId).replace(':regimenId', regimenId)
    : API_URLS.patientRegimens.replace(':patientId', patientId);
  return (dispatch) => {
    const payload = {
      medications: titrationMappings.mapLocalMedicationsToServer(medications),
    };

    return dispatch(
      apiRequest(
        regimenId ? API_REQUEST_METHODS.PUT : API_REQUEST_METHODS.POST,
        url,
        payload,
      ),
    ).then(() => {
      appInsights.trackEvent({ name: TRACKING_EVENTS.successfullyAssignedMedication });
      dispatch(success());
    }).catch((errorCode) => {
      dispatch(appActions.appPushNotification('ASSIGN_REGIMEN.ERROR_MESSAGES.assignRegimenError'));
      return Promise.reject(errorCode);
    });
  };
};

/**
 * Action to fetch (current and past) medication regimens of a patient
 *
 * @param {number} patientId
 */
const fetchMedicationRegimenData = (patientId) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_MEDICATION_REGIMENS_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_MEDICATION_REGIMENS_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_MEDICATION_REGIMENS_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.patientRegimens.replace(':patientId', patientId),
        ),
      );
      const regimens = response.data.map(titrationMappings.mapServerTitrationToLocal);
      dispatch(success(_.orderBy(
        regimens,
        [REGIMENS_DEFAULT_SORT_KEY],
        [REGIMENS_DEFAULT_SORT_ORDER],
      )));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

/**
 * Action to deactivate a medication regimen of a patient.
 *
 * @param {string} patientId The id of the patient to deactivate the medication regimen
 * @param {string} regimenId The id of the medication regimen to be deactivated
 */
const deactivateMedicationRegimen = (patientId, regimenId) => {
  const success = () => ({
    type: ACTION_TYPES.PATIENT_MEDICATION_REGIMEN_DEACTIVATED,
    data: { regimenId },
  });

  return async (dispatch) => {
    try {
      await dispatch(
        apiRequest(
          API_REQUEST_METHODS.DELETE,
          API_URLS.patientRegimen
            .replace(':patientId', patientId)
            .replace(':regimenId', regimenId),
        ),
      );
      dispatch(success());
    } catch (errorCode) {
      dispatch(appActions.appPushNotification('PATIENT_RECORD.ERROR_MESSAGES.deactivateMedicationRegimenError'));
    }
  };
};

/**
 * Action to set seizure and side-effects thresholds for a patient.
 *
 * @param {string} patientId The id of the patient to set the thresholds for.
 * @param {object} data Data object with seizures and side-effects thresholds.
 */
const setThresholds = (patientId, data) => {
  const payload = thresholdsMappings.mapLocalThresholdsToServer(data);
  return async (dispatch) => (
    dispatch(
      apiRequest(
        API_REQUEST_METHODS.PUT,
        API_URLS.patientThresholds.replace(':patientId', patientId),
        payload,
      ),
    ).catch((errorCode) => {
      dispatch(appActions.appPushNotification('THRESHOLD.ERROR_MESSAGES.saveThresholdError'));
      return Promise.reject(errorCode);
    })
  );
};

/**
 * Action to fetch (seizure and side effects) thresholds set for a patient
 *
 * @param {number} patientId
 */
const fetchThresholds = (patientId) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_THRESHOLDS_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_THRESHOLDS_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_THRESHOLDS_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.patientThresholds.replace(':patientId', patientId),
        ),
      );
      const thresholds = thresholdsMappings.mapServerThresholdsToLocal(response.data);
      dispatch(success(thresholds));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

const fetchLatestThresholdEvents = (patientId) => {
  const request = () => ({ type: ACTION_TYPES.PATIENT_THRESHOLD_EVENTS_REQUEST });
  const success = (data) => ({ type: ACTION_TYPES.PATIENT_THRESHOLD_EVENTS_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.PATIENT_THRESHOLD_EVENTS_ERROR });

  return async (dispatch) => {
    try {
      dispatch(request());
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.patientEvents.replace(':patientId', patientId),
          null,
          {
            eventType: [THRESHOLD_EVENT_TYPES.seizure, THRESHOLD_EVENT_TYPES.side_effect],
          },
        ),
      );

      dispatch(success(patientMappings.mapServerPatientEventsToLocal(response.data)));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

/**
 * Action to unlink a patient from his HCP.
 *
 * @param {number} linkId The link ID associated with the patient.
 */
const unlinkPatient = (linkId) => {
  const success = () => ({ type: ACTION_TYPES.PATIENT_UNLINK_SUCCESS, linkId });

  return async (dispatch) => {
    try {
      await dispatch(
        apiRequest(
          API_REQUEST_METHODS.PUT,
          API_URLS.patientUnlink.replace(':linkId', linkId),
          {
            value: true,
          },
        ),
      );

      return dispatch(success());
    } catch (errorCode) {
      dispatch(appActions.appPushNotification('PATIENT_RECORD.ERROR_MESSAGES.unlinkPatientError'));
      return Promise.reject(errorCode);
    }
  };
};

/**
 * Action to invite a study patient.
 *
 * @param {string} patientEmail The email of the patient to invite.
 */
const inviteStudyPatient = (patientEmail) => (
  async (dispatch) => {
    try {
      await dispatch(
        apiRequest(
          API_REQUEST_METHODS.POST,
          API_URLS.inviteStudyPatient,
          { patientEmail },
        ),
      );
      return Promise.resolve();
    } catch (errorCode) {
      dispatch(appActions.appPushNotification('STUDY_PATIENTS.ERROR_MESSAGES.invitePatientError'));
      return Promise.reject(errorCode);
    }
  }
);

export default {
  fetchPatients,
  verifyPatient,
  unlinkPatient,
  sortPatients,
  fetchPatientInfo,
  setPatientInfo,
  fetchTopLineSeizureData,
  fetchLongTermSeizureData,
  fetchTopLineSymptomData,
  fetchLongTermSymptomData,
  fetchLongTermMedicationAdherenceData,
  fetchLatestSurveyResults,
  fetchLatestThresholdEvents,
  filterPatientsByPhysician,
  assignRegimen,
  fetchMedicationRegimenData,
  deactivateMedicationRegimen,
  setThresholds,
  fetchThresholds,
  inviteStudyPatient,
};
