/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import KnPageContent from 'components/Content';
import { useIsUserActive, useIsUserPending } from 'utils/utils';
import KnUserPendingCard from './UserPendingCard';
import KnPatientLists from './PatientLists';

const HomePage = (props) => {
  const { location } = props;
  const selectedTab = _.get(location, 'state.patientsListTab');
  const isUserActive = useIsUserActive();
  const isUserPending = useIsUserPending();

  return (
    <KnPageContent dark={!isUserActive}>
      {isUserPending && <KnUserPendingCard />}
      {isUserActive && <KnPatientLists initialTab={selectedTab} />}
    </KnPageContent>
  );
};

HomePage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      patientsListTab: PropTypes.number,
    }),
  }).isRequired,
};

export default HomePage;
