/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { styled } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { KnTextFieldBase } from 'components/TextField';
import Box from '@material-ui/core/Box';
import palette from 'styles/colors';

const textFieldBackgroundColor = palette.silver.silver1;

export const KnSettingsFieldsBox = styled(Box)(({ theme }) => ({
  '& > *:first-child': {
    paddingRight: theme.spacing(1),
  },
  '& > *': {
    width: 285,
  },
}));

export const KnSettingsReadonlyTextFields = styled(KnTextFieldBase)({
  '& .MuiFilledInput-root.Mui-disabled': {
    backgroundColor: fade(textFieldBackgroundColor, 0.3),
  },
});
