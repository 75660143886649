/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';

/**
 * Utility function to filter a collection of patients given a search term;
 * the search is case-insensitive and will be applied on fullname.
 *
 * @param {array} data The collection of patients to be filtered by name.
 * @param {string} term The search term to be used to filter the patients.
 */
export const filterPatientsByName = (data, term) => {
  /** Let's make case-insensitive search. */
  const filterBy = term.trim().toLowerCase();

  /**
   * Search on either firstname or lastname; convert to lower case
   * to match case of the term.
   */
  return (
    _.filter(data, (item) => (
      (`${item.firstName.toLowerCase()} ${item.lastName.toLowerCase()}`.indexOf(filterBy) > -1)
      || (`${item.lastName.toLowerCase()} ${item.firstName.toLowerCase()}`.indexOf(filterBy) > -1)
    ))
  );
};

/** TO be removed as soon as we have another function exported;
 * placeholder to silent eslint errors.
 */
export default {};
