/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { appInsights } from 'appInsights';
import Box from '@material-ui/core/Box';
import KnPageContent from 'components/Content';
import { TRACKING_EVENTS } from 'Constants';
import KnPatientReports from './PatientReports';
import KnPatientInfomation from './PatientInformation';
import KnInsightsMetrics from './InsightsMetrics';
import KnPatientRegimen from './MedicationRegimen';

const PatientRecordPage = (props) => {
  const { match: { params: { patientId } } } = props;

  useEffect(() => {
    appInsights.trackEvent({ name: TRACKING_EVENTS.viewPatientRecord });
  }, []);

  return (
    <KnPageContent>
      <KnPatientInfomation patientId={patientId} />
      <Box display="flex" flex="1">
        <KnPatientReports patientId={patientId} />
        <KnInsightsMetrics patientId={patientId} />
      </Box>
      <KnPatientRegimen patientId={patientId} />
    </KnPageContent>
  );
};

PatientRecordPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      patientId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default PatientRecordPage;
