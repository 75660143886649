/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/


import ACTION_TYPES from 'redux/actionTypes';

const initialState = {
  sideEffectPresets: {
    data: null,
    loading: false,
  },
};

const thresholdsReducer = (state = initialState, action) => {
  const { data, type } = action;

  switch (type) {
    case ACTION_TYPES.THRESHOLD_SYMPTOM_PRESETS_REQUEST:
      return {
        ...state,
        sideEffectPresets: {
          ...state.sideEffectPrests,
          loading: true,
        },
      };

    case ACTION_TYPES.THRESHOLD_SYMPTOM_PRESETS_SUCCESS:
    case ACTION_TYPES.THRESHOLD_SYMPTOM_PRESETS_ERROR:
      return {
        ...state,
        sideEffectPresets: {
          data: (type === ACTION_TYPES.THRESHOLD_SYMPTOM_PRESETS_SUCCESS) ? data : null,
          loading: false,
        },
      };

    case ACTION_TYPES.THRESHOLD_SYMPTOM_PRESETS_CLEAR:
      return {
        ...state,
        sideEffectPresets: initialState.sideEffectPresets,
      };

    default:
      return state;
  }
};

export default thresholdsReducer;
