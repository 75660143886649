/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { Droppable, Draggable } from 'react-beautiful-dnd';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { KnActionLink } from 'components/Link';
import KnPlusIcon from 'components/icons/PlusIcon';
import Typography from '@material-ui/core/Typography';
import { withKeyNamespace } from 'utils/utils';
import KnTitrationFormDosage from './TitrationFormDosage';
import { KnMedicationBox } from '../styles';

const i18nKey = withKeyNamespace('TITRATIONS.titrationBuilder');

/**
 * Component used by the KnTitrationForm,
 * for editing one medication of the titration
 */
const KnTitrationFormMedication = (props) => {
  const { t: translate } = useTranslation();
  const {
    label,
    dosages,
    medId,
    unit,
    onAddDosage,
    onMedicationChange,
    onMedicationDosageRemove,
    dataTestId,
    ...rest
  } = props;

  /**
   * Droppable and draggable must share the same type
   * to prevent other type draggables to be dropped.
   */
  const droppableType = `med-${medId}`;

  const onMedicationChangeHandler = useCallback((dosageId, updates) => {
    onMedicationChange(medId, dosageId, updates);
  }, [medId, onMedicationChange]);

  const onMedicationDosageRemoveHandler = useCallback((dosageId) => {
    onMedicationDosageRemove(medId, dosageId);
  }, [medId, onMedicationDosageRemove]);

  return (
    <Droppable droppableId={medId.toString()} type={droppableType} direction="vertical">
      {(droppableProvided, snapshot) => (
        <div ref={droppableProvided.innerRef} data-testid={dataTestId}>
          <KnMedicationBox pb={5} mb={5}>
            <Typography variant="h6" color="primary">{label}</Typography>
            {dosages.map((dosage, index) => (
              <Draggable
                key={dosage.keyId}
                draggableId={dosage.keyId}
                index={index}
                type={droppableType}
              >
                {(draggableProvided) => (
                  <div
                    ref={draggableProvided.innerRef}
                    data-testid={`dosage-wrapper-${index + 1}`}
                    {...draggableProvided.draggableProps}
                  >
                    <KnTitrationFormDosage
                      dosage={dosage}
                      unit={unit}
                      index={index}
                      onChange={onMedicationChangeHandler}
                      onRemove={onMedicationDosageRemoveHandler}
                      dragHandleProps={draggableProvided.dragHandleProps}
                      {...rest}
                    />
                  </div>
                )}
              </Draggable>
            ))}

            <div>
              {!snapshot.isDraggingOver && !snapshot.draggingFromThisWith && (
                <KnActionLink onClick={() => onAddDosage(medId)} LhsIcon={KnPlusIcon} data-testid="add-dosage-link">
                  {translate(i18nKey('addDosage'))}
                </KnActionLink>
              )}
            </div>
          </KnMedicationBox>

          {droppableProvided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

KnTitrationFormMedication.propTypes = {
  dosages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  label: PropTypes.string.isRequired,
  medId: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  onAddDosage: PropTypes.func.isRequired,
  onMedicationChange: PropTypes.func.isRequired,
  onMedicationDosageRemove: PropTypes.func.isRequired,
  dataTestId: PropTypes.string.isRequired,
};

export default KnTitrationFormMedication;
