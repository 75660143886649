/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import KnErrorMessage from 'components/ErrorMessage';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { KnSubtleText } from 'components/Typography';
import { TITRATIONS_CONTEXT } from 'Constants';
import KnTitrationItem from './TitrationItem';

/**
 * Generic component, used to show a list of titrations
 * This may be for titration management, assigned medication regimens, or presets selection
 * It uses the KnTitrationItem component to display each titration
 */
const KnTitrationsList = (props) => {
  const {
    titrationsList,
    emptyMessageKey,
    error,
    errorMessageKey,
    onRetry,
    context,
    actions,
    alwaysExpanded,
  } = props;
  const { t: translate } = useTranslation();

  return (
    <>
      {!error && titrationsList && !titrationsList.length && emptyMessageKey && (
        <Box display="flex" justifyContent="center" pt={7}>
          <Typography component={KnSubtleText} variant="body2" data-testid="empty-titrations-list">
            {translate(emptyMessageKey)}
          </Typography>
        </Box>
      )}
      <KnErrorMessage
        error={error}
        messageKey={errorMessageKey}
        onRetry={onRetry}
      />
      {titrationsList && titrationsList.map((titrationData) => (
        <KnTitrationItem
          key={titrationData.id}
          titrationData={titrationData}
          context={context}
          actions={actions}
          alwaysExpanded={alwaysExpanded}
        />
      ))}
    </>
  );
};

KnTitrationsList.propTypes = {
  titrationsList: PropTypes.arrayOf(PropTypes.shape()),
  emptyMessageKey: PropTypes.string,
  error: PropTypes.bool,
  errorMessageKey: PropTypes.string,
  onRetry: PropTypes.func.isRequired,
  context: PropTypes.oneOf(
    [TITRATIONS_CONTEXT.assignPreset, TITRATIONS_CONTEXT.titrationsManagement,
      TITRATIONS_CONTEXT.assignedRegimen, TITRATIONS_CONTEXT.pastRegimen],
  ).isRequired,
  actions: PropTypes.shape(),
  alwaysExpanded: PropTypes.bool,
};

KnTitrationsList.defaultProps = {
  titrationsList: [],
  actions: {},
  alwaysExpanded: false,
  emptyMessageKey: null,
  error: false,
  errorMessageKey: '',
};

export default KnTitrationsList;
