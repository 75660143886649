/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import palette from 'styles/colors';

const KnContent = styled('div')(({ theme, dark }) => ({
  backgroundColor: dark ? palette.coolGrey.coolGrey2 : palette.paleGrey.paleGrey2,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  marginTop: -52, /** pull the content up to compensate on the minHeight of the notification bar */
  paddingTop: 60, /** compensate on negative marginTop, and a bit more to have space from the bar */
  paddingBottom: theme.spacing(1),
  /** Fluid padding between 16px and 120px. */
  paddingLeft: `max(min(${theme.spacing(15)}px, calc((100% - 1198px) / 2)), ${theme.spacing(2)}px)`,
  paddingRight: `max(min(${theme.spacing(15)}px, calc((100% - 1198px) / 2)), ${theme.spacing(2)}px)`,
}));

const KnPageContent = ({ dark, ...rest }) => (
  <KnContent dark={dark ? 'true' : ''} {...rest} />
);

export const KnBrightBox = styled(Box)({
  backgroundColor: palette.white.white2,
});

export default KnPageContent;

KnPageContent.propTypes = {
  dark: PropTypes.bool,
};

KnPageContent.defaultProps = {
  dark: false,
};
