/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import { DragDropContext } from 'react-beautiful-dnd';
import React, {
  useEffect, useState, useCallback, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import useDialog from 'components/dialog/DialogService';
import appActions from 'redux/actions/appActions';
import KnValidatedTextField from 'components/ValidatedTextField';
import { KnContrastTextField } from 'components/TextField';
import {
  composeTitrationMedicationLabel,
  medicationSearchMatch,
  withKeyNamespace,
} from 'utils/utils';
import {
  API_REQUEST_ERROR_CODES,
  TITRATIONS_CONTEXT,
  TITRATION_NAME_FORMAT,
  MEDICATION_UNITS,
} from 'Constants';
import { v4 as uuidv4 } from 'uuid';
import refDataService, { REFERENCE_DATA_TYPES } from 'services/referenceDataService';
import KnBrightSheet from 'components/BrightSheet';
import KnSearchDropDown from 'components/SearchDropDown';
import KnTitrationFormMedication from './form-components/TitrationFormMedication';
import {
  KnTitrationBuilderFieldsBox,
  KnTitrationSubmitButton,
} from './styles';

const i18nKey = withKeyNamespace('TITRATIONS.titrationBuilder');

/**
 * Quantities can be floating numbers. We need precise sum results
 * with 2 decimal points. For this, we first multiply each quantity
 * with 100; this will turn very small numbers such as 0.01 - which
 * are difficult to be represented in memory with precision - to 1.
 * At the end, we divide the sum by 100 to have the correct result.
 * NOTE: quantities are validated against floating numbers with more
 * than 2 decimal points; so even if a user will enter 0.211, the
 * sum will increase only by 0.21 which is expected.
 *
 * @param {array} quantities List of floating numbers representing
 * individual dosage quantities.
 */
const computeTotalQuantity = (quantities) => (
  _.sumBy(quantities, (q) => (
    Number(q) ? Math.trunc(Number(q) * 100) : 0
  )) / 100
);

const initialPresetInfo = {
  presetName: '',
  presetMedication: '',
};

/**
 * Generic component used to create/edit a titration,
 * in different contexts (presets, assigned regimens, etc)
 */
const KnTitrationForm = (props) => {
  const {
    context,
    headerActionComponent,
    submitAction,
    submitButtonLabel,
    title,
    disclaimer,
    defaultMedications,
    preventCleanSubmit,
  } = props;
  const { t: translate } = useTranslation();
  const [medications, setMedications] = useState(defaultMedications);
  const [dosagesUpdated, setDosagesUpdated] = useState(false);
  const successfulSave = useRef(false);
  const dialog = useDialog();
  const confirmedLeave = useRef(false);
  const unblockLeave = useRef(() => {});
  /** This will keep flags for medication unit changes,
   * to be able to warn the user when a unit is switched
   * It's a map of (medId: number, unitChanged: boolean) pairs */
  const medicationUnitChanges = useRef(new Map());
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    control,
    errors,
    triggerValidation,
    getValues,
    setError,
    clearError,
    setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues: initialPresetInfo,
    reValidateMode: 'onBlur',
  });

  const formControls = useMemo(() => ({
    control,
    errors,
    triggerValidation,
    setValue,
  }), [control, errors, triggerValidation, setValue]);

  const beforePageLeave = useCallback((e) => {
    /**
     * Display the prompt on browser/window close or page reload
     * only if we haven't already saved the titration.
     */
    if (!successfulSave.current) {
      /** Cancel the event as stated by the standard. */
      e.preventDefault();
      /** Chrome requires returnValue to be set. */
      e.returnValue = '';
    }
  }, []);

  useEffect(() => {
    if (!successfulSave.current && dosagesUpdated && !confirmedLeave.current) {
      /** Unblock handler to allow transitioning. */
      unblockLeave.current = history.block((nextLocation) => {
        dialog({
          title: translate(i18nKey('confirmationLeaveDialog.title')),
          description: translate(i18nKey('confirmationLeaveDialog.content')),
          submitLabel: translate('GENERAL.okButton'),
          closeLabel: translate('GENERAL.cancelButton'),
        }).then(() => {
          /**
           * Set the logical flag, unblock navigation and navigate
           * to the requested page on modal confirmation.
           */
          confirmedLeave.current = true;
          unblockLeave.current();
          history.push(nextLocation);
        });

        return !dosagesUpdated || confirmedLeave.current;
      });
    }

    return unblockLeave.current;
  }, [history, dialog, dosagesUpdated, translate]);

  useEffect(() => {
    window.addEventListener('beforeunload', beforePageLeave, false);

    return () => {
      window.removeEventListener('beforeunload', beforePageLeave, false);
    };
  }, [beforePageLeave]);

  const allMedications = useMemo(() => _.sortBy(
    refDataService.getList(REFERENCE_DATA_TYPES.medications),
    ['activeIngredient', 'name'],
  ), []);

  const onSubmit = () => {
    /** Check if there's medication added */
    const noMedicationError = medications.length === 0;
    if (noMedicationError) {
      setError('medicationSelect', 'emptyTitration');
    }

    triggerValidation().then((isValid) => {
      if (!noMedicationError && isValid) {
        const data = { medications };
        if (context === TITRATIONS_CONTEXT.newTitration) {
          data.name = getValues('presetName');
        }

        submitAction(data).then(() => {
          successfulSave.current = true;
        },
        (errorCode) => {
          if (errorCode === API_REQUEST_ERROR_CODES.TITRATION_PRESET_EXISTS) {
            setError('presetName', 'uniquePreset');
          }
        });
      }
    });
  };

  const isMedicationAdded = useCallback((medicationItem) => _.find(
    medications,
    { activeIngredient: medicationItem.activeIngredient },
  ), [medications]);

  /**
   * A medication will be added to the titration only if the medication wasn't added already.
   */
  const addMedicationIfNeeded = useCallback((medicationItem) => {
    if (!isMedicationAdded(medicationItem)) {
      setMedications((meds) => (
        [
          {
            ...medicationItem,
            index: meds.length + 1,
            unit: MEDICATION_UNITS[0],
            dosages: [
              {
                keyId: uuidv4(),
                index: 1,
                quantity: '',
                duration: '',
                frequency: '',
                times: [],
                individualQuantities: [],
                hasCustomDosage: false,
              },
            ],
          },
          ...meds,
        ]
      ));
      if (!dosagesUpdated) {
        setDosagesUpdated(true);
      }
      clearError('medicationSelect', 'emptyTitration');
    }
  }, [isMedicationAdded, clearError, dosagesUpdated]);

  /**
   * When a new dosage is added, we copy all the values, except the quantity,
   * from the previous dosage.
   */
  const onAddDosage = useCallback((medId) => {
    const med = _.find(medications, { id: medId });
    if (med) {
      const lastDosage = _.last(med.dosages);
      const dosages = med.dosages.concat({
        ...lastDosage,
        keyId: uuidv4(),
        index: med.dosages.length + 1,
        quantity: lastDosage.hasCustomDosage ? lastDosage.quantity : '',
        individualQuantities: lastDosage.hasCustomDosage
          ? lastDosage.individualQuantities
          : new Array(lastDosage.frequency).fill(0),
      });

      setMedications((meds) => _.map(meds, (item) => {
        if (item.id === medId) {
          return {
            ...item,
            dosages,
          };
        }
        return item;
      }));
    }
  }, [medications]);

  const computeQuantities = useCallback((total, frequency) => {
    const eachQuantity = Number(((parseFloat(total) || 0) / frequency).toFixed(2));
    const individualQuantities = new Array(frequency).fill(eachQuantity);
    const sub = individualQuantities.slice(0, -1);
    individualQuantities[frequency - 1] = Number((total - _.sum(sub)).toFixed(2)) || eachQuantity;
    return individualQuantities;
  }, []);

  /**
   * Called when a flag for medication unit changed needs to be updated (value switched)
   * If `shouldRemove` is true, flag should be removed for the given medication
   */
  const updateMedicationUnitChangesFlag = useCallback((medId, shouldRemove = false) => {
    if (shouldRemove) {
      medicationUnitChanges.current.delete(medId);
    } else {
      medicationUnitChanges.current.set(medId, !medicationUnitChanges.current.get(medId));
    }
    /** Check if the warning notification needs to be shown or removed */
    let changesCount = 0;
    medicationUnitChanges.current.forEach((value) => {
      if (value) {
        changesCount += 1;
      }
    });
    if (changesCount === 0) {
      /** There are no more meds with changed unit value */
      dispatch(appActions.appPopNotification('TITRATIONS.WARNING_MESSAGES.unitSwitched'));
    } else if (changesCount === 1) {
      /** Unit just changed for one medication */
      dispatch(appActions.appPushNotification('TITRATIONS.WARNING_MESSAGES.unitSwitched'));
    }
  }, [dispatch]);

  /**
   * Updates a dosage for a medication in the list. The function receives
   * only the field value which has updated in the updates object.
   */
  const onTitrationChange = useCallback((medId, dosageId, updates) => {
    setMedications((meds) => meds.map((med) => {
      if (med.id === medId) {
        if (_.get(updates, 'unit')) {
          /** It's a unit change, dosages remain unchanged */
          updateMedicationUnitChangesFlag(medId);
          return { ...med, ...updates };
        }

        const updatedDosages = med.dosages.map((dosage) => {
          if (dosage.keyId === dosageId) {
            /**
             * The individual quantities will be computed when:
             *   - custom dosage is unchecked and either the quantity or the frequency have changed
             *   - custom dosage has just been unchecked: the quantity will be evenly distributed
             */
            if (!dosage.hasCustomDosage || (updates.hasCustomDosage === false)) {
              if (updates.quantity || updates.frequency) {
                const individualQuantities = computeQuantities(
                  updates.quantity || dosage.quantity || 0,
                  updates.frequency || dosage.frequency,
                );
                return { ...dosage, ...updates, individualQuantities };
              }
              if (updates.hasCustomDosage === false) {
                const individualQuantities = computeQuantities(dosage.quantity, dosage.frequency);
                return { ...dosage, ...updates, individualQuantities };
              }
            }
            /**
             * If custom dosage is enabled:
             *  - if the individual quantities have changed, the total quantity will be updated with
             *    the sum of the individual ones.
             *  - if frequency has changed, the total quantity will be updated with the sum of the
             *    the remaining frequencies dosages.
             */
            if (dosage.hasCustomDosage) {
              if (updates.individualQuantities) {
                const updatedQuantity = computeTotalQuantity(updates.individualQuantities);
                return { ...dosage, ...updates, quantity: updatedQuantity };
              }
              if (updates.frequency) {
                const individualQuantities = dosage.individualQuantities.slice(
                  0,
                  updates.frequency,
                );
                const updatedQuantity = _.sumBy(individualQuantities, (q) => (Number(q) || 0));
                const hasCustomDosage = (updates.frequency !== 1);
                return {
                  ...dosage,
                  ...updates,
                  individualQuantities,
                  hasCustomDosage,
                  quantity: updatedQuantity,
                };
              }
            }
            /** If neither of the above, then these are other dosage updates. */
            return { ...dosage, ...updates };
          }
          return dosage;
        });
        return { ...med, dosages: updatedDosages };
      }
      return med;
    }));

    if (!dosagesUpdated) {
      setDosagesUpdated(true);
    }
  }, [computeQuantities, dosagesUpdated, updateMedicationUnitChangesFlag]);

  const onTitrationDosageRemove = useCallback((medId, dosageId) => {
    setMedications((meds) => _.flatMap(meds, (med) => {
      if (med.id === medId) {
        const filteredDosages = _.filter(med.dosages, (dosage) => dosage.keyId !== dosageId);

        /** When we delete the last dosage, the medication must also be deleted. */
        if (!filteredDosages.length) {
          /** Clear unit change flag for this med */
          updateMedicationUnitChangesFlag(medId, true);
          return [];
        }
        return [{ ...med, dosages: filteredDosages }];
      }
      return [med];
    }));

    if (!dosagesUpdated) {
      setDosagesUpdated(true);
    }
  }, [dosagesUpdated, updateMedicationUnitChangesFlag]);

  const onTitrationDosageReorder = useCallback((result) => {
    /**
     * If the dosage is dragged outside the medication it belongs to
     * destination will be null. We should do nothing in this case OR
     * if the dosage was dropped in the same place.
     */
    if (!result.destination
      || (result.destination.index === result.source.index)) {
      return;
    }

    const medId = parseInt(result.source.droppableId, 10);
    const from = result.source.index;
    const to = result.destination.index;

    setMedications((meds) => meds.map((med) => {
      if (med.id === medId) {
        const dosages = [...med.dosages];
        const [moved] = dosages.splice(from, 1);
        dosages.splice(to, 0, moved);

        return { ...med, dosages };
      }
      return med;
    }));

    if (!dosagesUpdated) {
      setDosagesUpdated(true);
    }
  }, [dosagesUpdated]);

  return (
    <>
      <KnBrightSheet
        title={title}
        subTitle={disclaimer}
        ActionComponent={headerActionComponent}
      >
        <Box flex="1">
          <KnTitrationBuilderFieldsBox display="flex" pt={2}>
            {(context === TITRATIONS_CONTEXT.newTitration) && (
              <KnValidatedTextField
                name="presetName"
                Component={KnContrastTextField}
                control={control}
                errors={errors}
                format={TITRATION_NAME_FORMAT}
                required
                trimSpaces
                maxLength={80}
              />
            )}

            <KnSearchDropDown
              label={translate('FIELD_LABELS.presetMedication')}
              items={allMedications}
              isItemDisabled={isMedicationAdded}
              itemDisplay={composeTitrationMedicationLabel}
              onItemSelect={addMedicationIfNeeded}
              searchMatch={medicationSearchMatch}
              error={errors.medicationSelect}
            />
          </KnTitrationBuilderFieldsBox>

          <DragDropContext onDragEnd={onTitrationDosageReorder}>
            {medications.map((medication, index) => (
              <KnTitrationFormMedication
                key={medication.id}
                medId={medication.id}
                label={composeTitrationMedicationLabel(medication)}
                unit={medication.unit}
                dosages={medication.dosages}
                onAddDosage={onAddDosage}
                onMedicationChange={onTitrationChange}
                onMedicationDosageRemove={onTitrationDosageRemove}
                dataTestId={`medication-wrapper-${index + 1}`}
                {...formControls}
              />
            ))}
          </DragDropContext>
        </Box>
      </KnBrightSheet>
      <KnTitrationSubmitButton
        onClick={onSubmit}
        data-testid="save-titration-button"
        disabled={preventCleanSubmit && !dosagesUpdated}
      >
        {submitButtonLabel}
      </KnTitrationSubmitButton>
    </>
  );
};

KnTitrationForm.defaultProps = {
  disclaimer: null,
  headerActionComponent: null,
  defaultMedications: [],
  preventCleanSubmit: false,
};

KnTitrationForm.propTypes = {
  title: PropTypes.string.isRequired,
  disclaimer: PropTypes.string,
  headerActionComponent: PropTypes.shape(),
  submitAction: PropTypes.func.isRequired,
  submitButtonLabel: PropTypes.string.isRequired,
  context: PropTypes.oneOf([
    TITRATIONS_CONTEXT.newTitration,
    TITRATIONS_CONTEXT.editTitration,
    TITRATIONS_CONTEXT.assignRegimen,
  ]).isRequired,
  defaultMedications: PropTypes.arrayOf(PropTypes.shape()),
  preventCleanSubmit: PropTypes.bool,
};

export default KnTitrationForm;
