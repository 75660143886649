/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

const KnHamburgerIcon = (props) => {
  const {
    height,
    width,
    ...rest
  } = props;

  return (
    <SvgIcon
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path fillRule="evenodd" d="M21 11H3V9h18v2zM3 15h18v-2H3v2zm0 4h18v-2H3v2z" clipRule="evenodd" />
    </SvgIcon>
  );
};

KnHamburgerIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

KnHamburgerIcon.defaultProps = {
  height: 24,
  width: 24,
};

export default KnHamburgerIcon;
