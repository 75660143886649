/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import KnTextField from 'components/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { KnBoldSectionHeader } from 'components/Typography';
import KnButton from 'components/Button';
import loginActions from 'redux/actions/loginActions';
import { trimValueEmptyValidator } from 'utils/customFieldValidators';

const initialMFAInfo = {
  confirmationCode: '',
};

/**
 * UI Component for handling MFA code verification step
 */
const MFAStep = ({
  submitDisabled, stepsData, onReset, onSuccess,
}) => {
  const { t: translate } = useTranslation();
  const { control, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    defaultValues: initialMFAInfo,
    reValidateMode: 'onBlur',
  });
  const dispatch = useDispatch();
  const history = useHistory();

  /** We can cache the validation rules, as they should change only if the
   * translation is updated so that the messages are in the correct language.
   */
  const verificationCodeValidationRules = useMemo(() => ({
    required: {
      value: true,
      message: translate('LOGIN.FIELD_VALIDATION_MESSAGES.confirmationCode.required'),
    },
    validate: {
      trimValueEmpty: (value) => (
        trimValueEmptyValidator(value)
          ? translate('LOGIN.FIELD_VALIDATION_MESSAGES.confirmationCode.required')
          : true
      ),
    },
  }), [translate]);

  /** This function will be called by handleSubmit with form data
   * only when the data is valid. We can make the confirmSignIn call now.
   */
  const onSubmit = (formData, e) => {
    e.preventDefault();
    dispatch(loginActions.confirmSignIn(
      stepsData.signInResult,
      formData.confirmationCode,
    ))
      .then((response) => {
        if (!response.error) {
          /** Sign in confirmation was successful */
          onSuccess(response);
        }
      });
  };

  const onCancelLogin = useCallback(() => {
    history.push('/');
    onReset();
  }, [onReset, history]);

  return (
    <>
      <Typography variant="h6" component={KnBoldSectionHeader} marginBottom>
        {translate('LOGIN.title')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography paragraph>{translate('LOGIN.confirmationCodeHint')}</Typography>
        <Controller
          as={KnTextField}
          name="confirmationCode"
          label={translate('FIELD_LABELS.confirmationCode')}
          rules={verificationCodeValidationRules}
          error={errors.confirmationCode}
          inputProps={{ 'data-testid': 'confirmation-code-input-field', maxLength: 256 }}
          InputLabelProps={{ 'data-testid': 'confirmation-code-input-label' }}
          defaultValue=""
          control={control}
        />
        <Box
          pt={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <KnButton
            variant="text"
            type="button"
            data-testid="cancel-login-link"
            onClick={onCancelLogin}
          >
            {translate('GENERAL.cancelButton')}
          </KnButton>
          <KnButton
            disabled={submitDisabled}
            data-testid="login-button"
          >
            {translate('LOGIN.submitButton')}
          </KnButton>
        </Box>
      </form>
    </>
  );
};

MFAStep.propTypes = {
  submitDisabled: PropTypes.bool.isRequired,
  stepsData: PropTypes.shape().isRequired,
  onReset: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default MFAStep;
