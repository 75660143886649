/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import {
  EMAIL_FORMAT,
  NAME_FORMAT,
  PASSWORD_FORMAT,
  DEFAULT_COUNTRY_CODE,
} from 'Constants';
import { parsePhoneNumber } from 'libphonenumber-js';

/**
 * Custom field validator to determine if an email address is a valid format.
 *
 * @param {string} value The email address to be validated
 * @returns {boolean} Whether the email address is valid.
 */
export const emailFormatValidator = (value) => RegExp(EMAIL_FORMAT).test(value.trim());

/**
 * Custom field validator to determine if the password and confirm password
 * values match.
 *
 * @param {string} password
 * @param {string} confirmPassword
 * @returns {boolean} Whether the password and confirm password are the same.
 */
export const passwordMatchValidator = (password, confirmPassword) => (password === confirmPassword);

/**
 * Custom field validator to determine if value is a valid phone number.
 *
 * @param {string} value The phone number to be validated.
 * @returns {boolean} Whether the phone number is valid.
 */
export const phoneNumberValidator = (value) => {
  try {
    const phoneNumber = parsePhoneNumber(value, DEFAULT_COUNTRY_CODE);
    return phoneNumber.isValid()
      && (!process.env.REACT_APP_PHONE_COUNTRY_CODE
        || phoneNumber.country === process.env.REACT_APP_PHONE_COUNTRY_CODE);
  } catch (e) {
    return false;
  }
};

/**
 * Custom field validator to determine if the password is strong enough.
 *
 * @param {string} value The password to validate its strength.
 * @returns {boolean} Whether the password is secure enough.
 */
export const passwordFormatValidator = (value) => RegExp(PASSWORD_FORMAT).test(value);

/**
 * Custom field validator to determine if the password entered equals the email address.
 * This is a security measure. The check will be case-insensitive as even with a
 * capitalization difference, the password is not strong enough.
 *
 * @param {string} value The password to be validated.
 * @param {string} emailAddress The email address to check the password against.
 * @returns {boolean} Whether the password equals the email address.
 */
export const passwordEqualsEmailAddress = (value, emailAddress) => (
  value.toLowerCase() === emailAddress.toLowerCase()
);

/**
 * Custom field validator to determine if the user has entered
 * only white spaces. The required rule does not invalidate this
 * case. In most of the case, we'll delegate the required error
 * message on this error.
 *
 * @param {string} value The input value to be validated agains white spaces.
 */
export const trimValueEmptyValidator = (value) => value.toString().trim() === '';

/**
 * Custom field validator to determine if a name is valid.
 *
 * @param {string} value The name to be validated.
 * @returns {boolean} Whether the name is valid.
 */
export const nameFormatValidator = (value) => RegExp(NAME_FORMAT).test(value.trim());

/**
 * Generic field format validator
 *  to check if value matches given format (regular expression)
 *
 * Note: This validator can replace some of the
 *  specific validators from above (eg, name, phone, email format validators)
 *
 * @param {string} value The value to be validated
 * @param {string} format Regular expression to match with
 * @param {boolean} [trimSpaces = false] Should trim spaces before validation
 * @returns {boolean} Whether the value is valid.
 */
export const formatValidator = (value, format, trimSpaces = false) => (
  RegExp(format).test(trimSpaces ? value.toString().trim() : value)
);
