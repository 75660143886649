/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { darken, fade } from '@material-ui/core/styles/colorManipulator';
import { styled } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import KnCheckOutlineIcon from 'components/icons/CheckOutlineIcon';
import { KnBoldSectionHeader } from 'components/Typography';
import KnButton from 'components/Button';
import palette from 'styles/colors';

const successBox = palette.primary;
const boxContrast = palette.white.white2;
const buttonText = palette.primary;

const KnSuccessBox = styled(Box)(({ theme }) => ({
  minHeight: 488,
  minWidth: 817,
  backgroundColor: successBox,

  '& > *': {
    marginBottom: theme.spacing(5),
  },
}));

const KnSuccessActionButton = styled(KnButton)({
  backgroundColor: boxContrast,
  color: buttonText,
  width: 326,

  '&:hover': {
    backgroundColor: darken(boxContrast, 0.1),
  },
});

const KnSecondaryActionButton = styled(KnButton)({
  color: boxContrast,

  '&:hover': {
    backgroundColor: fade('#000', 0.3),
  },
});

const KnSuccessConfirmationCard = ({
  message,
  buttonLabel,
  buttonProps,
  secondaryButtonLabel,
  secondaryButtonProps,
  ...rest
}) => (
  <KnSuccessBox
    alignItems="center"
    alignSelf="center"
    display="flex"
    flexDirection="column"
    justifyContent="center"
    color={boxContrast}
    {...rest}
  >
    <KnCheckOutlineIcon fontSize={97} />

    <Typography variant="h5" component={KnBoldSectionHeader}>
      {message}
    </Typography>

    <KnSuccessActionButton {...buttonProps}>
      {buttonLabel}
    </KnSuccessActionButton>

    {secondaryButtonLabel && (
      <KnSecondaryActionButton variant="text" {...secondaryButtonProps}>
        {secondaryButtonLabel}
      </KnSecondaryActionButton>
    )}
  </KnSuccessBox>
);

KnSuccessConfirmationCard.defaultProps = {
  secondaryButtonLabel: null,
  secondaryButtonProps: {},
};

KnSuccessConfirmationCard.propTypes = {
  message: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonProps: PropTypes.shape().isRequired,
  secondaryButtonLabel: PropTypes.string,
  secondaryButtonProps: PropTypes.shape(),
};

export default KnSuccessConfirmationCard;
