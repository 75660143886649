/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { KnSubtleText } from 'components/Typography';
import {
  SEIZURE_TYPES,
  SEIZURES_COLORS, SYMPTOM_SEVERITY_COLORS, MEDICATION_ADHERENCE_COLOR,
} from 'Constants';
import palette from 'styles/colors';
import { withKeyNamespace } from 'utils/utils';
import { KnLegendText } from '../styles';

const i18nKey = withKeyNamespace('PATIENT_RECORD');

const filterBorder = palette.paleGrey.paleGrey2;
const filterBoxShadow = palette.primary;

const KnFilterWrapper = styled(Box)({
  width: 207,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: filterBorder,
  boxShadow: `0 1px 1px 0 ${fade(filterBoxShadow, 0.15)}`,
  marginBottom: 8,
  marginRight: 8,
  alignItems: 'center',
});

const KnTypeColorIndicator = styled('div')(({ color }) => ({
  width: 14,
  height: 14,
  borderRadius: 10,
  backgroundColor: color,
}));

const KnLegendBullet = styled(Box)(({ color, theme }) => ({
  width: 12,
  height: 12,
  borderRadius: 6,
  backgroundColor: color,
  marginRight: theme.spacing(1),
}));

const KnMetricsLegendWrapper = styled(Box)({
  width: 860,
});

const KnInsightsMetricsFilter = (props) => {
  const {
    type, count, onSelected, children, labelKey, ...rest
  } = props;
  const [checked, setChecked] = useState(false);
  const manuallyUnchecked = useRef(false);

  useEffect(() => {
    /** We will make no updates on the filter if the user unchecked it explicitly. */
    if (manuallyUnchecked.current) return;

    /** A filter is checked/unchecked based on count only if the user hasn't unchecked it. */
    if (checked !== !!count) {
      setChecked(!!count);
    }
  }, [checked, count]);

  const onCheckChanged = useCallback(({ target }) => {
    /** Logical flag to mark if a user has explicitly unchecked a filter. */
    manuallyUnchecked.current = !target.checked;
    onSelected(type);
    setChecked(target.checked);
  }, [onSelected, type]);

  return (
    <KnFilterWrapper display="flex" flexDirection="row" p={1} {...rest}>
      <Box display="flex" flex="1" alignItems="center">
        <FormControlLabel
          disabled={!count}
          control={(
            <Checkbox
              size="small"
              name="filter"
              checked={checked}
              onChange={onCheckChanged}
            />
          )}
          label={(
            <Trans
              parent={KnLegendText}
              i18nKey={labelKey}
              components={[<div />]}
            />
          )}
        />
      </Box>

      <Box display="flex" flexDirection="column" alignItems="center">
        {children}
      </Box>
    </KnFilterWrapper>
  );
};

const KnSeizuresLegend = ({ counts, onSelected }) => (
  <Box display="flex" flexDirection="row" flexWrap="wrap">
    {Object.values(SEIZURE_TYPES).map((type, index) => (
      <KnInsightsMetricsFilter
        key={type}
        type={type}
        count={counts[type]}
        onSelected={onSelected}
        labelKey={`PATIENT_RECORD.seizureInsights.chartLegend.${type}`}
      >
        <KnTypeColorIndicator color={SEIZURES_COLORS[index].hex} />
        <KnLegendText>{counts[type]}</KnLegendText>
      </KnInsightsMetricsFilter>
    ))}
  </Box>
);

const KnSymptomsLegend = () => {
  const { t: translate } = useTranslation();

  return (
    <Box>
      <Typography variant="subtitle2" component={KnSubtleText}>
        {translate(i18nKey('sideEffectsInsights.title'))}
      </Typography>
      <Box pb={2}>
        {Object.keys(SYMPTOM_SEVERITY_COLORS).map((type) => (
          <Box display="inline-flex" alignItems="center" pr={2} key={type}>
            <KnLegendBullet color={SYMPTOM_SEVERITY_COLORS[type].hex} />
            <Typography variant="body2" component={KnSubtleText}>
              {translate(i18nKey(`sideEffectsInsights.severities.${type}`))}
            </Typography>
          </Box>
        ))}
      </Box>
      <Typography variant="body2" component={KnSubtleText}>
        {translate(i18nKey('sideEffectsInsights.legendHint'))}
      </Typography>
    </Box>
  );
};

const KnMedicationsAdherenceLegend = () => {
  const { t: translate } = useTranslation();

  return (
    <Box ml={2}>
      <Typography variant="subtitle2" component={KnSubtleText}>
        {translate(i18nKey('medicationsInsights.legend.title'))}
      </Typography>
      <Box display="flex" alignItems="center">
        <KnLegendBullet color={MEDICATION_ADHERENCE_COLOR.hex} />
        <Typography variant="body2" component={KnSubtleText}>
          {translate(i18nKey('medicationsInsights.legend.text'))}
        </Typography>
      </Box>
    </Box>
  );
};

const KnInsightsMetricsLegend = (props) => {
  const {
    seizuresCounts, onLegendFilterSelected, hideSymptomsLegend, hideMedicationsLegend,
  } = props;

  return (
    <KnMetricsLegendWrapper display="flex" flexDirection="column">
      <KnSeizuresLegend counts={seizuresCounts} onSelected={onLegendFilterSelected} />

      <Box display="flex" pt={3} pb={3}>
        {!hideSymptomsLegend && <KnSymptomsLegend />}
        {!hideMedicationsLegend && <KnMedicationsAdherenceLegend />}
      </Box>
    </KnMetricsLegendWrapper>
  );
};

KnSeizuresLegend.propTypes = {
  counts: PropTypes.shape({}).isRequired,
  onSelected: PropTypes.func.isRequired,
};

KnInsightsMetricsFilter.propTypes = {
  type: PropTypes.string.isRequired,
  count: PropTypes.number,
  onSelected: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  labelKey: PropTypes.string.isRequired,
};

KnInsightsMetricsFilter.defaultProps = {
  count: 0,
};

KnInsightsMetricsLegend.propTypes = {
  seizuresCounts: PropTypes.shape().isRequired,
  onLegendFilterSelected: PropTypes.func.isRequired,
  hideSymptomsLegend: PropTypes.bool.isRequired,
  hideMedicationsLegend: PropTypes.bool.isRequired,
};


export default KnInsightsMetricsLegend;
