/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const KnCheckIcon = (props) => (
  <SvgIcon {...props}>
    <path fill="#6FCF97" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
  </SvgIcon>
);

export default KnCheckIcon;
