/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const KnDeleteIcon = (props) => (
  <SvgIcon
    viewBox="-4 -2 24 24"
    fill="none"
    {...props}
  >
    <path fill="#a9abb5" d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V4H1v12zM14 1h-3.5l-1-1h-5l-1 1H0v2h14V1z" />
  </SvgIcon>
);

export default KnDeleteIcon;
