/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const KnButton = (props) => {
  const {
    children,
    color,
    onClick,
    route,
    routeParams,
    ...rest
  } = props;
  const history = useHistory();

  const onClickHandler = useCallback((e) => {
    if (route) {
      history.push(route, routeParams);
    }
    onClick(e);
  }, [onClick, route, routeParams, history]);

  return (
    <Button
      color={color}
      variant="contained"
      size="large"
      onClick={onClickHandler}
      {...rest}
    >
      {children}
    </Button>
  );
};

KnButton.defaultProps = {
  color: 'primary',
  onClick: () => {},
  route: null,
  routeParams: {},
  type: 'submit',
};

KnButton.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func,
  route: PropTypes.string,
  routeParams: PropTypes.shape(),
  type: PropTypes.string,
};

export default KnButton;
