/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { styled } from '@material-ui/core/styles';
import palette from 'styles/colors';

const contrastTextField = palette.silver.silver1;

/** Select mode props to align the menu under the field. */
const SelectProps = {
  MenuProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        maxHeight: 170,
      },
    },
  },
};

export const KnTextFieldBase = ({ children, ...rest }) => {
  const selectProps = rest.select
    ? { SelectProps }
    : {};

  return (
    <TextField
      color="secondary"
      variant="filled"
      {...selectProps}
      {...rest}
    >
      {children}
    </TextField>
  );
};

const KnTextField = (props) => {
  const {
    children,
    error,
    disableErrorMessage,
    ...rest
  } = props;
  const { t: translate } = useTranslation();
  const { type: errorType } = error;

  /** Use the error's message if it was provided OR use a global field validation message. */
  const helperText = errorType
    ? (error.message || translate(`FIELD_VALIDATION_MESSAGES.${errorType}`))
    : null;

  return (
    <Box pb={3}>
      <KnTextFieldBase
        fullWidth
        error={!!error.type}
        {...rest}
      >
        {children}
      </KnTextFieldBase>
      {/* Instead of using the helperText prop on TextField which dynamically adds the
          FormHelperText component when there is an error, we'll manually add it so it
          will be in the DOM all the time to prevent the fields from being pushed down.
          Fixes: https://kokoon.atlassian.net/browse/KK-250

          Note: We can't know beforehand how much vertical space an error message needs.
          We've set a min height of 22px for FormHelperText; this is enough for a single
          line error message. If an error message spans multiple lines, then it will push
          the fields down. */}
      {!disableErrorMessage && (
        <FormHelperText error data-testid="field-validation-error">{helperText}</FormHelperText>
      )}
    </Box>
  );
};

export const KnContrastTextField = styled(KnTextField)({
  '& .MuiInputBase-root': {
    backgroundColor: contrastTextField,

    '&.Mui-disabled': {
      opacity: 0.4,
    },
  },
});

/** Some text fields don't have a label so we need to center the values. */
export const KnUnlabeledTextField = styled(KnContrastTextField)({
  '& .MuiInputBase-input': {
    transform: 'translateY(-8px)',
    /**
     * Fixes KK-1172: Autofill style is overflowing the field.
     * By default, Material-UI uses labels for fields. As we need
     * unlabeled textfields, in order to align the input value with
     * an input adornment or the arrow of a select dropdown, we applied
     * a translateY of -8px transformation. However, this slightly moves
     * the input outside of the .MuiInputBase-root container. The sum of
     * paddingTop and marginTop is 27 to preserve the default paddingTop
     * of a textfield input. Decreasing the padding top will bring the input
     * in the bounds of the container.
     */
    paddingTop: 10,
    marginTop: 17,
  },
  '& .MuiSelect-filled': {
    backgroundColor: 'transparent',
    transform: 'translateY(-8px)',
  },
});

KnTextFieldBase.propTypes = {
  children: PropTypes.node,
};

KnTextField.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
  disableErrorMessage: PropTypes.bool,
};

KnTextFieldBase.defaultProps = {
  children: null,
};

KnTextField.defaultProps = {
  children: null,
  error: {},
  disableErrorMessage: false,
};

export default KnTextField;
