/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import palette from 'styles/colors';
import { boxShadow } from 'styles/common';

export default {
  MuiExpansionPanel: {
    root: {
      backgroundColor: palette.white.white2,
      boxShadow,
      marginBottom: 8,

      '&.Mui-expanded': {
        marginTop: 0,
        marginBottom: 8,
      },

      '&::before': {
        height: 0,
      },
    },
  },

  MuiExpansionPanelSummary: {
    root: {
      paddingLeft: 8,
      paddingRight: 16,
      borderBottomColor: palette.paleGrey.paleGrey2,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
    content: {
      flexDirection: 'column',
      marginTop: 8,
      marginBottom: 8,

      '&.Mui-expanded': {
        marginTop: 8,
        marginBottom: 8,
      },
    },
  },

  MuiExpansionPanelDetails: {
    root: {
      flexDirection: 'column',
      padding: 8,
    },
  },
};
