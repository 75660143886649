/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import { styled } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import palette from 'styles/colors';

const barTextColor = palette.white.white2;
const barBg = palette.primary;

const NotificationWrapper = styled('div')({
  minHeight: 52,
  position: 'sticky',
  top: 64, /** height of the KnHeader */
  zIndex: 10,
  pointerEvents: 'none',
  backgroundColor: 'transparent',
});

/** Component used to display messages at the top of the screen */
const KnNotificationBar = () => {
  const { t: translate } = useTranslation();
  const { busyCount, notification } = useSelector((state) => state.app);

  return (
    <NotificationWrapper>
      {(busyCount > 0) && <LinearProgress color="primary" />}
      {notification
      && (
        <Box
          p={2}
          boxShadow={5}
          color={barTextColor}
          bgcolor={barBg}
          data-testid="app-notification-bar"
        >
          {translate(notification.messageKey)}
        </Box>
      )}
    </NotificationWrapper>
  );
};

export default KnNotificationBar;
