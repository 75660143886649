/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import differenceInMonths from 'date-fns/differenceInMonths';
import differenceInCalendarMonths from 'date-fns/differenceInCalendarMonths';
import sub from 'date-fns/sub';
import add from 'date-fns/add';
import startOfDay from 'date-fns/startOfDay';
import startOfMonth from 'date-fns/startOfMonth';
import { utcToZonedTime } from 'date-fns-tz';
import refDataService, { REFERENCE_DATA_TYPES } from 'services/referenceDataService';
import { TOP_SYMPTOMS_COUNT } from 'Constants';

/** Returns how many months should be in the charts,
 * considering the range it covers
 * @param {object} timeRange The covered time range, {startDate, endDate} object
 */
const getChartMonthCount = (timeRange) => differenceInCalendarMonths(
  timeRange.endDate,
  timeRange.startDate,
) + 1;

/**
 * Although Amchart fills the inner gaps between 2 non-consecutive entries automatically,
 * if the list has only one OR only consecutive/non-consecutive entries which are less
 * than the given time period, the chart columns display is odd. Let's be defensive
 * and fill in the gaps OR prepend the list with the missing months entries before passing
 * the data for rendering.
 * We use the null value as Amchart does to be consistent. If we were to use 0, then a
 * tooltip would appear only for this month gaps and not for the ones added by Amcharts.
 *
 * @param {array} list Sorted by date list of metrics count.
 * @param {object} timeRange The time period that the chart should present
 * @returns {array} The list with the missing months until given metrics period filled
 * up with null value with the sorted order preserved.
 */
const fillMonthGaps = (list, timeRange) => {
  let copyList = [...list];
  let missingMonthsCount = getChartMonthCount(timeRange) - copyList.length;

  /**
   * Fill in the inner gaps from the back to the front of the list.
   * Loops in reverse order and verifies if there are 2 entries with non-consecutive months.
   * In that case, it reduces the monthGaps count and inserts in place the missing months.
   */
  for (let i = copyList.length - 1; ((i >= 1) && (missingMonthsCount > 0)); i -= 1) {
    const monthGaps = differenceInMonths(copyList[i].date, copyList[i - 1].date);
    if (monthGaps > 1) {
      for (let k = monthGaps; k > 1; k -= 1) {
        missingMonthsCount -= 1;
        copyList.splice(
          i,
          0,
          {
            value: null,
            date: startOfMonth(sub(copyList[i].date, { months: 1 })),
          },
        );
      }
    }
  }

  /**
   * The list is sorted by date; use the last date if the last reported month is not the current
   * one to append the missing months until the current one.
   */
  const lastMonth = _.get(_.last(copyList), 'date');
  let copyMissingMonthsCount = missingMonthsCount;

  const missingMonthsFromEnd = differenceInCalendarMonths(new Date(), lastMonth);
  for (let i = 1; i <= missingMonthsFromEnd; i += 1) {
    copyList = [
      ...copyList,
      { value: null, date: add(lastMonth, { months: i }) },
    ];
    copyMissingMonthsCount -= 1;
  }

  /** If we still need to fill in gaps in front, we'll prepend months. */
  if (copyMissingMonthsCount > 0) {
    /**
     * The list is sorted by date; use the first date to subtract
     * and prepend the missing months.
     */
    const firstMonth = _.get(copyList[0], 'date');

    if (firstMonth) {
      for (let i = 1; i <= copyMissingMonthsCount; i += 1) {
        copyList = [
          { value: null, date: sub(firstMonth, { months: i }) },
          ...copyList,
        ];
      }
    }
  }

  return copyList;
};

/**
 * Counts all the events which occured in the same month and returns
 * an aggregated list of objects.
 *
 * @param {array} data List of metrics objects.
 * @param {string} timestampKey Object property key for the timestamp
 * value which will be used for counting.
 * @returns {array} A list of object in the shape { date, value } where
 * date is the first day of a month and value is the total events from
 * that month.
 */
const aggregateMetricsByMonth = (data) => {
  const countedEvents = _.countBy(data, (item) => startOfMonth(item.date));
  const mappedList = _.map(countedEvents, (count, month) => ({
    date: new Date(month),
    value: count,
  }));

  return _.sortBy(mappedList, ['date']);
};

/**
 * Computes the most common symptoms. The second argument specifies
 * how many of the top symptoms should be returned.
 *
 * @param {array} data List of symptom metrics.
 * @param {number} topRange Number of top most common symptoms to return. Defaults to 3.
 * @returns {array} A list of most common symptoms with objects in shape { name, count }.
 */
const getTopCommonSymptoms = (data, topRange) => {
  const symptomsCounts = _.countBy(data, (item) => item.symptomNameValue);
  const aggregatedCounts = _.map(symptomsCounts, (count, name) => ({ count, name }));
  /** When 'count' is the same, chouse by 'name' (alphabetic order) */
  const orderedCounts = _.orderBy(aggregatedCounts, ['count', 'name'], ['desc', 'asc']);
  return _.take(orderedCounts, topRange);
};

/**
 * Counts all the seizures which occured in the same month and returns
 * an aggregated list of objects.
 *
 * @param {array} data List of seizure objects.
 * @param {object} timeRange Time period of the chart
 */
export const countSeizuresByMonth = (data, timeRange) => {
  /** List must be sorted by date for the chart visualizations. */
  let sortedMetrics = aggregateMetricsByMonth(data);
  if (sortedMetrics.length && (sortedMetrics.length < getChartMonthCount(timeRange))) {
    sortedMetrics = fillMonthGaps(sortedMetrics, timeRange);
  }

  return sortedMetrics;
};

/**
 * Groups symptoms by types and counts them by the same month they occured.
 * Symptom events are counted based only on the reporting date, regardless
 * of how many days it spaned.
 *
 * @param {array} data List of symptom objects.
 * @param {object} timeRange Time period of the chart
 * @returns {array} List of objects in the shape { typeId, list } where
 * typeId is a type of symptom and list is the symptoms counted by month
 * and sorted by date.
 */
export const countTopSymptomsByType = (data, timeRange, topRange = TOP_SYMPTOMS_COUNT) => {
  const topSymptoms = getTopCommonSymptoms(data, topRange);

  /** Make a list of the top symptom names to be used for filtering the data. */
  const topSymptomNames = _.map(topSymptoms, (symptom) => symptom.name);

  /** Filter the list and make the computations only on the top symptoms. */
  const onlyTopSymptoms = _.filter(
    data,
    (item) => _.includes(topSymptomNames, item.symptomNameValue),
  );

  /** Group symptoms by type */
  const groupSymptomsByType = _.groupBy(onlyTopSymptoms, 'symptomNameValue');

  /** Sort groups based on instance count */
  const sortedGroups = _.orderBy(
    _.map(groupSymptomsByType, (symptoms, type) => ({ type, symptoms })),
    (item) => item.symptoms.length,
    ['desc'],
  );

  const symptomsAggregated = _.map(sortedGroups, (group) => {
    let sortedMetrics = aggregateMetricsByMonth(group.symptoms);
    if (sortedMetrics.length && (sortedMetrics.length < getChartMonthCount(timeRange))) {
      sortedMetrics = fillMonthGaps(sortedMetrics, timeRange);
    }

    return {
      type: group.type,
      list: sortedMetrics,
    };
  });

  return symptomsAggregated;
};

export const countSeizureMetricsByType = (data) => {
  const groupSeizuresByDate = _.groupBy((data), (item) => startOfDay(item.date));
  const aggregatedSeizures = _.map(groupSeizuresByDate, (events, date) => {
    const countedByType = _.reduce(events, (res, event) => {
      const acc = { ...res };
      let key;

      switch (event.convulsions) {
        case true: {
          switch (event.lossOfConsciousness) {
            case true:
              key = 'cna';
              break;
            case false:
              key = 'ca';
              break;
            case null:
              key = 'cu';
              break;
            default:
          }
          break;
        }

        case false: {
          switch (event.lossOfConsciousness) {
            case true:
              key = 'ncna';
              break;
            case false:
              key = 'nca';
              break;
            case null:
              key = 'ncu';
              break;
            default:
          }
          break;
        }

        case null: {
          switch (event.lossOfConsciousness) {
            case true:
              key = 'una';
              break;
            case false:
              key = 'ua';
              break;
            case null:
              key = 'ucua';
              break;
            default:
          }
          break;
        }

        default:
          break;
      }

      acc[key] = (acc[key] || 0) + 1;

      if (event.triggers) {
        acc.triggers = {
          ...acc.triggers,
          [key]: event.triggers,
        };
      }

      if (event.recoveryTime) {
        acc.recoveryTime = {
          ...acc.recoveryTime,
          [key]: event.recoveryTime,
        };
      }

      if (!_.isUndefined(event.didGoToER)) {
        acc.didGoToER = {
          ...acc.didGoToER,
          [key]: event.didGoToER,
        };
      }

      if (!_.isUndefined(event.didUseRescueMedication)) {
        acc.didUseRescueMedication = {
          ...acc.didUseRescueMedication,
          [key]: event.didUseRescueMedication,
        };
      }

      return acc;
    }, {});

    return { date: new Date(date), ...countedByType };
  });

  return _.sortBy(aggregatedSeizures, ['date']);
};

/**
 * Groups and orders symptoms descending by severity and ascending by name.
 *
 * @param {array} symptoms List of symptoms
 */
export const groupSymptomsBySeverity = (symptoms) => {
  const groupedSymptoms = _.groupBy(symptoms, 'severity');
  const aggregatedSymptoms = _.map(groupedSymptoms, (symptomsList, severity) => ({
    severity: Number(severity),
    symptoms: _.sortBy(_.map(symptomsList, (item) => (
      refDataService.getValue(REFERENCE_DATA_TYPES.sideEffect, item.symptomId)
    ))),
  }));

  return _.orderBy(aggregatedSymptoms, ['severity'], ['desc']);
};

export const aggregateLongTermSymptoms = (symptoms) => {
  let seconds = 0;
  const orderedSymptoms = _.orderBy(symptoms, ['date'], ['asc']);

  /**
   * The side-effects are returned without time in the date.
   * In order for the chart to properly count the side-effects
   * when grouped by day, we need to add some time to the entries
   * so they will be identified as separate instances in the same
   * day.
   * Just adding seconds will do the job. This will not affect the
   * accuracy of the side-effect time, as the chance of falling on
   * a different day by adding seconds should be very small (there
   * are 86400 seconds in a day).
   */
  const symptomsWithTime = _.map(orderedSymptoms, (s) => {
    seconds += 1;

    return {
      ...s,
      date: add(s.date, { seconds }),
      value: 1,
      y: 0,
    };
  });

  return symptomsWithTime;
};

export const aggregateLongTermMedicationAdherence = (medications) => {
  let seconds = 0;
  const orderedMedications = _.orderBy(medications, ['date'], ['asc']);

  const medicationsWithTime = _.map(orderedMedications, (med) => {
    seconds += 1;

    return {
      ...med,
      date: add(med.date, { seconds }),
      value: 1,
      y: 0,
    };
  });

  return medicationsWithTime;
};

/**
 * Creates a time range object, using the offset argument.
 * Accepts an optional timezone argument to use when creating
 * the date range objects.
 *
 * @param {string} timezone Patient's timezone. Optional
 * @returns {object} {startDate, endDate} object
 */
export const getMetricsTimeRange = ({ timezone = undefined, offset }) => {
  /** If timezone is given, we will consider it when creating the time range
   */
  const physicianToday = new Date();
  const endDate = timezone ? utcToZonedTime(physicianToday, timezone) : physicianToday;
  /** Need to substract (x-1) days, to get a range of x days,
   * including start and end date, as we will only use the date parts */
  const startDate = sub(endDate, { days: (offset - 1) });
  return { startDate, endDate };
};

export { fillMonthGaps };
