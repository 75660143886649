/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import ACTION_TYPES from 'redux/actionTypes';

const initialState = {
  /** True while waiting for api response,
   * in each step of the sign up */
  busy: false,
  /** Error during the sign up flow */
  error: null,
  /** As registering is a multipstep process,
   * this flag is true while in the middle of this process
   * It is set to 'true' on successful sign up (first step),
   * and 'false' when the register call successfully ends (last step)
   */
  registering: false,
};

const registerReducer = (state = initialState, action) => {
  const { type, error, registering } = action;

  switch (type) {
    case ACTION_TYPES.REGISTER_REQUEST:
      return {
        ...state,
        busy: true,
        error: null,
      };
    case ACTION_TYPES.REGISTER_SUCCESS:
      return {
        ...state,
        busy: false,
        registering,
      };
    case ACTION_TYPES.REGISTER_ERROR:
      return {
        ...state,
        busy: false,
        error,
      };
    case ACTION_TYPES.REGISTER_CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case ACTION_TYPES.REGISTER_RESET:
      return initialState;

    default:
      return state;
  }
};

export { initialState };

export default registerReducer;
