/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import KnTextBox from 'components/TextBox';
import { composeTitrationMedicationLabel } from 'utils/utils';
import { KnTitrationMedsText } from '../styles';

const KnDosageInfo = ({ dosage, unit }) => {
  const { t: translate } = useTranslation();
  return (
    <Box display="flex" flexDirection="row" pt={1}>
      <KnTextBox text={translate('TITRATIONS.titrationBuilder.doseWithUnit', { dose: dosage.quantity, unit })} />
      <KnTextBox text={translate('GENERAL.durationDays', { count: dosage.duration })} />
      <KnTextBox text={translate('GENERAL.frequencyTimes', { count: dosage.frequency })} />
      <Box pl={2} display="flex" flexDirection="row">
        {dosage.times.map((dosageTime, index) => (
          <Box key={dosageTime.index}>
            <KnTextBox text={dosageTime.timePeriod.value} />
            <KnTextBox text={translate('TITRATIONS.titrationBuilder.doseWithUnit', { dose: dosage.individualQuantities[index], unit })} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

KnDosageInfo.propTypes = {
  dosage: PropTypes.shape({
    index: PropTypes.number.isRequired,
    quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    duration: PropTypes.number.isRequired,
    frequency: PropTypes.number.isRequired,
    times: PropTypes.arrayOf(PropTypes.shape({
      index: PropTypes.number.isRequired,
      timePeriod: PropTypes.shape({
        id: PropTypes.number.isRequired,
        value: PropTypes.string.isRequired,
      }),
    })),
    individualQuantities: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  unit: PropTypes.string.isRequired,
};

const KnTitrationItemMedication = ({ presetMedication }) => (
  <Box mb={1.5}>
    <Typography component={KnTitrationMedsText}>
      {composeTitrationMedicationLabel(presetMedication)}
    </Typography>
    {presetMedication.dosages.map(
      (dosage) => <KnDosageInfo key={dosage.index} dosage={dosage} unit={presetMedication.unit} />,
    )}
  </Box>
);

KnTitrationItemMedication.propTypes = {
  presetMedication: PropTypes.shape({
    index: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    activeIngredient: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
    dosages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }).isRequired,
};

export default KnTitrationItemMedication;
