/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useUserStatus from 'utils/userStatus';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { KnSectionHeader } from 'components/Typography';
import KnAccountMenu from 'components/AccountMenu';
import userActions from 'redux/actions/userActions';
import { styled } from '@material-ui/core/styles';
import palette from 'styles/colors';
import { APP_PAGE_URLS } from 'Constants';
import KnLogoIcon from 'components/icons/LogoIcon';
import { useIsUserActive } from 'utils/utils';
import KnLink from './Link';

const navLinkColor = palette.slateGrey;
const navLinkHoverColor = palette.brightPurple;

export const KnNavigationWrapper = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(4),

  '& .MuiLink-button': {
    paddingLeft: theme.spacing(4),
    fontWeight: 'bold',
    color: navLinkColor,

    '&:hover': {
      color: navLinkHoverColor,
    },
  },
}));

const KnNavigation = () => {
  const { t: translate } = useTranslation();

  return (
    <KnNavigationWrapper display="flex">
      <KnLink
        underline="none"
        data-testid="nav-link-patients"
        label={translate('MAIN_NAVIGATION.patientList')}
        route={APP_PAGE_URLS.home}
      />
      <KnLink
        underline="none"
        data-testid="nav-link-titrarions"
        label={translate('MAIN_NAVIGATION.titrations')}
        route={APP_PAGE_URLS.titrationsList}
      />
    </KnNavigationWrapper>
  );
};

const KnHeader = () => {
  const { registering } = useSelector((state) => state.register);
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const userStatus = useUserStatus();
  const isUserActive = useIsUserActive();

  const isLoggedIn = !!userStatus && !registering;

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(userActions.getCurrentUser());
    }
  }, [dispatch, isLoggedIn, registering]);

  return (
    <AppBar position="sticky" color="inherit" elevation={0}>
      <Toolbar>
        {/* TODO: maybe we'll have to use an svg? leave it like this for now */}
        <Box display="flex">
          <KnLogoIcon />

          <Typography variant="h6" component={KnSectionHeader}>
            {translate('GENERAL.projectName')}
          </Typography>
          {isLoggedIn && isUserActive && (
            <KnNavigation />
          )}
        </Box>
        {isLoggedIn && isUserActive && (
          <KnAccountMenu />
        )}
      </Toolbar>
    </AppBar>
  );
};

export default KnHeader;
