/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { APP_PAGE_URLS } from 'Constants';
import KnSuccessConfirmationCard from 'components/SuccessConfirmationCard';

const KnAssignRegimenConfirmationCard = (props) => {
  /** 'assignedAt' is null when it was a new regimen asignment */
  const {
    patientId,
    patientName,
    medications,
    assignedAt,
  } = props;
  const { t: translate } = useTranslation();

  /**
   * Take the thresholds from the patient record. In normal flow,
   * a user shouldn't be able to reach this step other than coming
   * from the patient record page. We fetched the thresholds there
   * therefore these should be available in the store.
   */
  const {
    data: thresholds,
  } = useSelector((state) => state.patientRecord.thresholds);

  return (
    <KnSuccessConfirmationCard
      message={translate('ASSIGN_REGIMEN.successfulAssignMessage', { name: patientName })}
      buttonLabel={translate('ASSIGN_REGIMEN.viewPatientRegimen', { name: patientName })}
      buttonProps={{
        route: APP_PAGE_URLS.patientRecord.replace(':patientId', patientId),
        'data-testid': 'view-patient-record-button',
      }}
      secondaryButtonLabel={translate('ASSIGN_REGIMEN.setThresholdButton', { name: patientName })}
      secondaryButtonProps={{
        route: APP_PAGE_URLS.threshold.replace(':patientId', patientId),
        routeParams: {
          activeRegimen: {
            medications,
            assignedAt,
          },
          patientName,
          thresholds,
        },
      }}
      data-testid="titration-saved-confirmation-card"
    />
  );
};

KnAssignRegimenConfirmationCard.propTypes = {
  patientId: PropTypes.string.isRequired,
  patientName: PropTypes.string.isRequired,
  medications: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  assignedAt: PropTypes.instanceOf(Date),
};

KnAssignRegimenConfirmationCard.defaultProps = {
  assignedAt: undefined,
};

export default KnAssignRegimenConfirmationCard;
