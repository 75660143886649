/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { Auth } from 'aws-amplify';
import appActions from 'redux/actions/appActions';
import { ERROR_CODES } from 'Constants';

/** Currently we make no differentiation on error scenarios */
const handleApiFailure = (dispatch, errorCode) => {
  dispatch(appActions.appPushNotification(`FORGOT_PASSWORD.ERROR_MESSAGES.${errorCode}`));
  return { error: errorCode };
};

/**
 * Performs the first step of the reset password
 * @param username Username of the user reseting the password for
 * @param [silently] when `true`, no progress indicator or error message will be displayed
 * @param [isResend] `true` when this is called to resend the confirmation code
 */
const forgotPassword = (username, silently = false, isResend = false) => (dispatch) => {
  if (silently) {
    return Auth.forgotPassword(username.trim());
  }

  dispatch(appActions.appPushBusy());
  dispatch(appActions.appPopNotification());

  /** Call Cognito forgot password */
  return Auth.forgotPassword(username.trim())
    .then(
      /**
       * Return username on success
       * Note: Cognito returns success even on inexistent username
       * (exactly how we want it)
       */
      () => {
        if (isResend) {
          dispatch(appActions.appPushNotification(
            'FORGOT_PASSWORD.SUCCESS_MESSAGES.forgotPasswordResendSuccess',
          ));
        }
        return username;
      },
      () => handleApiFailure(
        dispatch,
        isResend ? ERROR_CODES.FORGOT_PASSWORD_RESEND_ERROR : ERROR_CODES.FORGOT_PASSWORD_ERROR,
      ),
    )
    .catch(() => handleApiFailure(
      dispatch,
      isResend ? ERROR_CODES.FORGOT_PASSWORD_RESEND_ERROR : ERROR_CODES.FORGOT_PASSWORD_ERROR,
    ))
    .finally(() => dispatch(appActions.appPopBusy()));
};

/**
 * Performs the second step of the reset password
 * @param username Username of the user reseting the password for
 * @param code Confirmation code received after first step call
 * @param password New password
 */
const forgotPasswordSubmit = (username, code, password) => (dispatch) => {
  dispatch(appActions.appPushBusy());
  dispatch(appActions.appPopNotification());

  /** Call Cognito forgot password submit */
  return Auth.forgotPasswordSubmit(username.trim(), code.trim(), password)
    .then(
      () => ({ success: true }), /** Call returns nothing, simple success */
      (error) => {
        switch (error.code) {
          /** Handling different error scenarios */
          case ERROR_CODES.CODE_MISMATCH:
            return handleApiFailure(dispatch, ERROR_CODES.CODE_MISMATCH);
          case ERROR_CODES.EXPIRED_CODE:
            /** Trigger sending a new code in the background
             * We do this silently, hopefully with no errors
             */
            dispatch(forgotPassword(username, true));
            return handleApiFailure(dispatch, ERROR_CODES.EXPIRED_CODE);
          default:
            /** Show same generic error, regardless of the cause */
            return handleApiFailure(dispatch, ERROR_CODES.FORGOT_PASSWORD_ERROR);
        }
      },
    )
    .catch(() => handleApiFailure(dispatch, ERROR_CODES.FORGOT_PASSWORD_ERROR))
    .finally(() => dispatch(appActions.appPopBusy()));
};

/**
 * Collection of the exposed forgot password action creators
 */
export default {
  forgotPassword,
  forgotPasswordSubmit,
};
