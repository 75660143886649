/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { KnBoldSectionHeader, KnSubtitleText } from 'components/Typography';
import { styled } from '@material-ui/core/styles';
import palette from 'styles/colors';

const sheetBackgroundColor = palette.white.white2;
const headerBoxBorder = palette.silver.silver2;

const KnBrightBox = styled(Box)({
  backgroundColor: sheetBackgroundColor,
});

const KnBrightBoxHeader = styled(Box)({
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  borderBottomColor: headerBoxBorder,
});

const KnBrightBoxSubtitle = styled(Box)({
  width: 395,
});

const KnBrightSheet = (props) => {
  const {
    children,
    title,
    subTitle,
    ActionComponent,
  } = props;

  return (
    <KnBrightBox display="flex" flexDirection="column" flex="1">
      <Box display="flex" flex="1" flexDirection="column" pt={7} pb={7} pl={8} pr={8}>
        {/** Sheet header */}
        <KnBrightBoxHeader display="flex" justifyContent="space-between" alignItems="baseline" pb={3} mb={2.5}>
          {/** Titles */}
          <Box>
            <Typography variant="h5" component={KnBoldSectionHeader}>
              {title}
            </Typography>
            {subTitle && (
              <KnBrightBoxSubtitle>
                <Typography component={KnSubtitleText}>
                  {subTitle}
                </Typography>
              </KnBrightBoxSubtitle>
            )}
          </Box>
          {/** Action link/button on the right */}
          {ActionComponent}
        </KnBrightBoxHeader>
        {/** Content */}
        {children}
      </Box>
    </KnBrightBox>
  );
};

KnBrightSheet.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  ActionComponent: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  children: PropTypes.node,
};

KnBrightSheet.defaultProps = {
  subTitle: null,
  ActionComponent: null,
  children: null,
};

export default KnBrightSheet;
