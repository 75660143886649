/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

const palette = {
  primary: '#292e47',
  secondary: '#ffc234',
  error: {
    main: '#d44420',
  },
  white: {
    white1: '#f7f7f7',
    white2: '#fff',
    white3: '#d9d9d9',
    white4: '#dadada',
    white5: '#f9f9f9',
  },
  black: {
    black1: '#252525',
    black2: '#000',
    black3: '#262626',
  },
  coolGrey: {
    coolGrey1: '#a6a8b3',
    coolGrey2: '#a9abb5',
  },
  paleGrey: {
    paleGrey1: '#f9f9fb',
    paleGrey2: '#eaeaed',
    paleGrey3: '#f4f5f6',
  },
  lightGrey: '#595959',
  slateGrey: '#54586c',
  brownishGrey: '#666',
  gunmetal: '#4d5358',
  green: {
    dark: '#219653',
    light: '#d3eadd',
    royal: '#0d817a',
  },
  copper: '#d44420',
  linkWater: '#d4d5da',
  midnight: '#222838',
  silver: {
    silver1: '#eaebec',
    silver2: '#d4d5da',
  },
  red: {
    light: '#f6dad2',
  },

  brightBlue: '#0072b6',
  lightBlue: '#66aad3',
  extraLightBlue: '#b3d5e9',

  brightPurple: '#333cab',
  lightPurple: '#858acd',
  extraLightPurple: '#c2c5e6',

  brightOrange: '#e78102',
  lightOrange: '#f1b367',
  extraLightOrange: '#f8d9b3',
  paleOrange: '#fbecd9',
  steel: '#7f8291',
  darkPeach: '#db846f',
  veryLightBrown: '#e0b5ab',
};

export default palette;
