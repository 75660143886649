/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import Box from '@material-ui/core/Box';
import KnButton from 'components/Button';
import { styled } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import KnHamburgerIcon from 'components/icons/HamburgerIcon';
import palette from 'styles/colors';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { withStyles } from '@material-ui/core';

const medicationBorder = palette.paleGrey.paleGrey2;
const reorderDosageIcon = palette.brightPurple;

export const KnTitrationBuilderFieldsBox = styled(Box)(({ theme }) => ({
  '& > *:first-child': {
    paddingRight: theme.spacing(1),
  },
  '& > *': {
    width: 398,
  },
}));

export const KnMedicationFieldsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingTop: theme.spacing(1),

  '& > *': {
    width: 105,
    marginRight: theme.spacing(1),
    paddingBottom: 0,
  },

  '& > :first-child': {
    width: 150,
  },

  '& > :nth-child(3)': {
    width: 130,
  },
}));

export const KnTimesFieldsBox = styled(KnMedicationFieldsBox)({
  '& > .MuiBox-root': {
    width: 111,
  },
});

export const KnTimesDosagesBox = styled(Box)(({ theme }) => ({
  '& > *': {
    paddingBottom: theme.spacing(1),
  },
}));

export const KnCustomDosageFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  position: 'absolute',
  top: -27,
  right: 0,
  justifyContent: 'flex-end',
  '& .MuiCheckbox-colorSecondary': {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: theme.spacing(0.5),
  },
}));

export const KnDeleteDosageButton = styled(IconButton)({
  position: 'absolute',
  bottom: 16,
  right: 10,
});

export const KnReorderDosageIcon = styled(KnHamburgerIcon)({
  position: 'absolute',
  top: 16,
  right: 16,
  fill: reorderDosageIcon,
});

export const KnTitrationSubmitButton = styled(KnButton)({
  position: 'sticky',
  bottom: 0,
});

export const KnMedicationBox = styled(Box)({
  borderBottomWidth: 1,
  borderBottomStyle: 'solid',
  borderBottomColor: medicationBorder,
});

/** Styles for the Titrations (list) page */
export const KnTitrationNameContainer = styled('div')({
  width: 230,
});

export const KnTitrationMedsContainer = styled('div')(({ theme }) => ({
  maxWidth: 462,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingLeft: theme.spacing(3),
  '& *:last-child': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const KnTitrationActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  '& :not(:last-child)': {
    marginRight: theme.spacing(4),
  },
}));

export const KnTitrationSubtleText = styled('span')({
  color: palette.coolGrey.coolGrey2,
});

export const KnTitrationMedsText = styled('span')(({ theme }) => ({
  color: palette.black.black3,
  whiteSpace: 'nowrap',
  paddingRight: theme.spacing(2),
}));

export const KnTitrationExpansionPanelSummary = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 3, 4),
    borderBottomWidth: 0,

  },
  expandIcon: {
    alignSelf: 'baseline',
    marginTop: theme.spacing(1),
  },
}))(ExpansionPanelSummary);

export const KnTitrationExpansionPanelDetails = withStyles((theme) => ({
  root: {
    borderBottomColor: (props) => props.color,
    borderBottomStyle: 'solid',
    borderBottomWidth: theme.spacing(0.5),
    padding: theme.spacing(0, 4, 2, 4),
  },
}))(ExpansionPanelDetails);
