/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import palette from 'styles/colors';

export default {
  MuiAppBar: {
    root: {
      backgroundColor: 'transparent',
    },
  },
  MuiToolbar: {
    root: {
      backgroundColor: palette.white.white2,
      borderWidth: 1,
      borderColor: palette.white.white3,
      borderStyle: 'solid',
      justifyContent: 'space-between',
    },
  },
};
