/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { appInsights } from 'appInsights';
import { useTranslation } from 'react-i18next';
import KnPageContent from 'components/Content';
import { KnActionLink } from 'components/Link';
import { APP_PAGE_URLS, TITRATIONS_CONTEXT, TRACKING_EVENTS } from 'Constants';
import { withKeyNamespace } from 'utils/utils';
import titrationActions from 'redux/actions/titrationActions';
import KnSuccessConfirmationCard from 'components/SuccessConfirmationCard';
import KnTitrationForm from 'components/titrations/TitrationForm';

const i18nKey = withKeyNamespace('TITRATIONS.titrationBuilder');

const TitrationBuilderPage = (props) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [successfulSave, setSuccessfulSave] = useState(false);
  const { location, match } = props;

  /**
   * Default the router parameters in case the user
   * will navigate directly to the titration page.
   */
  const presetId = _.get(match, 'params.presetId', '');
  const presetName = _.get(location, 'state.presetName', null);
  const presetMedications = _.get(location, 'state.presetMedications', []);

  useEffect(() => {
    appInsights.trackEvent({ name: TRACKING_EVENTS.viewTitrationBuilder });
  }, []);

  const title = presetId
    ? translate(i18nKey('editTitle'), { presetName }) : translate(i18nKey('title'));

  const viewTitrationsLink = useMemo(() => (
    <KnActionLink route={APP_PAGE_URLS.titrationsList} data-testid="view-titrations-link">
      {translate(i18nKey('viewTitrations'))}
    </KnActionLink>
  ), [translate]);

  /** Called both on new preset and editing a preset */
  const submitTitration = useCallback((data) => (
    dispatch(titrationActions.saveTitration(data, presetId))
      .then(() => { setSuccessfulSave(true); })
  ), [dispatch, presetId]);

  return (
    <KnPageContent>
      {!successfulSave && (
        <KnTitrationForm
          context={presetId ? TITRATIONS_CONTEXT.editTitration : TITRATIONS_CONTEXT.newTitration}
          headerActionComponent={viewTitrationsLink}
          submitAction={submitTitration}
          submitButtonLabel={translate(i18nKey('submit'))}
          title={title}
          disclaimer={translate(i18nKey('subtitle'))}
          defaultMedications={presetMedications}
          preventCleanSubmit={!!presetId}
        />
      )}

      {successfulSave && (
        <KnSuccessConfirmationCard
          message={translate(i18nKey('successMessage'))}
          buttonLabel={translate(i18nKey('viewTitrations'))}
          buttonProps={{
            route: APP_PAGE_URLS.titrationsList,
            'data-testid': 'view-titrations-button',
          }}
          data-testid="titration-saved-confirmation-card"
        />
      )}
    </KnPageContent>
  );
};

TitrationBuilderPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      presetName: PropTypes.string,
      presetMedications: PropTypes.arrayOf(PropTypes.shape()),
    }),
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      presetId: PropTypes.string,
    }),
  }).isRequired,
};

export default TitrationBuilderPage;
