/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { KnElevatedColorBox } from 'styles/common';
import { KnDarkerText, KnLightText } from 'components/Typography';
import { padIfDigit, withKeyNamespace } from 'utils/utils';
import { THRESHOLD_EVENT_TYPES, SURVEY_TYPES } from 'Constants';
import palette from 'styles/colors';
import { KnMetricsLabel } from './styles';

const thresholdMetColor = palette.error.main;
const i18nKey = withKeyNamespace('PATIENT_RECORD.achievementsCard');

const KnAchievementsCard = ({ data }) => {
  const { t: translate } = useTranslation();

  const cardContent = useMemo(() => {
    switch (data.type) {
      case THRESHOLD_EVENT_TYPES.seizure:
      case THRESHOLD_EVENT_TYPES.side_effect:
        return translate(i18nKey(`content.${data.type}`));
      case SURVEY_TYPES.anxiety:
      case SURVEY_TYPES.depression:
        return translate(
          i18nKey('surveyResultsScore'),
          { value: padIfDigit(data.score), maxScore: padIfDigit(data.maxScore) },
        );
      default:
        return '';
    }
  }, [data, translate]);

  const dateDisplayKey = useMemo(() => {
    /** Some cards show date only, others time as well */
    if (data.type === THRESHOLD_EVENT_TYPES.seizure
      || data.type === THRESHOLD_EVENT_TYPES.side_effect) {
      return 'resultDate';
    }
    return 'resultDateTime';
  }, [data]);

  return (
    <KnElevatedColorBox
      p={2}
      pb={1.5}
      mb={1}
      display="flex"
      justifyContent="space-between"
      data-testid={`achievement-card-${data.type}`}
      borderColor={
        (data.type === THRESHOLD_EVENT_TYPES.seizure
          || data.type === THRESHOLD_EVENT_TYPES.side_effect) ? thresholdMetColor : 'white'
      }
    >
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle2" component={KnMetricsLabel}>
          {translate(i18nKey(`title.${data.type}`))}
        </Typography>
        <Typography component={KnDarkerText}>
          {cardContent}
        </Typography>
      </Box>
      <Typography variant="caption" component={KnLightText}>
        {translate(i18nKey(dateDisplayKey), { date: data.date })}
      </Typography>
    </KnElevatedColorBox>
  );
};

const KnPatientAchievementsReports = ({ surveys, events }) => {
  const [reportCards, setReportCards] = useState([]);
  const { t: translate } = useTranslation();

  useEffect(() => {
    let achievements = [];
    if (surveys) {
      achievements = achievements.concat(surveys);
    }
    if (events) {
      achievements = achievements.concat(events);
    }

    let cards = [];
    if (achievements.length) {
      cards = cards.concat(achievements.map((achievementData) => ({
        date: achievementData.date,
        Component: <KnAchievementsCard
          key={`${achievementData.type}-${achievementData.id}`}
          data={achievementData}
        />,
      })));
    }
    setReportCards(_.orderBy(cards, ['date'], ['desc']));
  }, [setReportCards, surveys, events, translate]);

  if (reportCards.length) {
    return <>{reportCards.map(({ Component }) => Component)}</>;
  }

  return null;
};

KnAchievementsCard.propTypes = {
  data: PropTypes.shape().isRequired,
};

KnPatientAchievementsReports.defaultProps = {
  surveys: [],
  events: [],
};

KnPatientAchievementsReports.propTypes = {
  surveys: PropTypes.arrayOf(PropTypes.shape()),
  events: PropTypes.arrayOf(PropTypes.shape()),
};

export default KnPatientAchievementsReports;
