/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import KnArrowDownIcon from 'components/icons/ArrowDownIcon';
import { METRICS_TYPE } from 'Constants';
import { countSeizuresByMonth, countTopSymptomsByType } from 'utils/metrics';
import KnMetricsSummary from './MetricsSummary';
import KnMetricsDetails from './MetricsDetails';
import { KnMetricsTitle } from './styles';

/**
 * Displays the collapsible card for seizures or symptoms patient metrics.
 */
const KnMetricsCard = (props) => {
  const { type, data, timeRange } = props;
  const { t: translate } = useTranslation();
  const isSeizureMetrics = (type === METRICS_TYPE.seizure);
  const panelTitle = isSeizureMetrics
    ? translate('PATIENT_RECORD.seizureMetrics.title')
    : translate('PATIENT_RECORD.symptomMetrics.title');

  const aggregatedData = useMemo(() => (
    isSeizureMetrics
      ? countSeizuresByMonth(data, timeRange) : countTopSymptomsByType(data, timeRange)
  ), [data, timeRange, isSeizureMetrics]);

  return (
    <ExpansionPanel square elevation={0}>
      <ExpansionPanelSummary expandIcon={aggregatedData.length > 0 && <KnArrowDownIcon data-testid="panel-expand-icon" />}>
        <Typography display="block" component={KnMetricsTitle}>{panelTitle}</Typography>
        <KnMetricsSummary
          rawData={data}
          type={type}
          timeRange={timeRange}
        />
      </ExpansionPanelSummary>

      {aggregatedData.length > 0
        && (
          <ExpansionPanelDetails>
            <KnMetricsDetails type={type} aggregatedData={aggregatedData} />
          </ExpansionPanelDetails>
        )}
    </ExpansionPanel>
  );
};

KnMetricsCard.propTypes = {
  type: PropTypes.oneOf([METRICS_TYPE.seizure, METRICS_TYPE.symptom]).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  timeRange: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }).isRequired,
};

export default KnMetricsCard;
