/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import ACTION_TYPES from 'redux/actionTypes';
import { API_REQUEST_METHODS, API_URLS } from 'Constants';
import apiRequest from './apiActions';

const mapItemToLocal = (item) => ({
  question: item.fields.question,
  answer: item.fields.answer,
});

const mapContentfulToLocal = (data) => data.items.map((item) => mapItemToLocal(item));

/**
 * Action creator for fetching Contentful data for FAQ page
 */
const fetchFAQ = () => {
  const success = (data) => ({ type: ACTION_TYPES.FAQ_FETCH_SUCCESS, data });
  const failure = () => ({ type: ACTION_TYPES.FAQ_FETCH_ERROR });

  return async (dispatch) => {
    try {
      const response = await dispatch(
        apiRequest(
          API_REQUEST_METHODS.GET,
          API_URLS.faq
            .replace(':spaceId', process.env.REACT_APP_CONTENTFUL_SPACE_ID)
            .replace(':environment', process.env.REACT_APP_CONTENTFUL_ENVIRONMENT),
          null,
          null,
          { contentful: true },
        ),
      );
      dispatch(success(mapContentfulToLocal(response.data)));
    } catch (errorCode) {
      dispatch(failure());
    }
  };
};

export default {
  fetchFAQ,
};
