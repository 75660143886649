/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import { capFirstCharOnly, serverDateToGMT } from 'utils/utils';
import {
  SEIZURE_USER_RESPONSES,
  SURVEY_TYPES,
  THRESHOLD_EVENT_TYPES,
} from 'Constants';
import refDataService, { REFERENCE_DATA_TYPES } from 'services/referenceDataService';

const mapSeizureUserResponse = (response) => {
  switch (response.toLowerCase()) {
    case SEIZURE_USER_RESPONSES.yes:
      return true;

    case SEIZURE_USER_RESPONSES.no:
      return false;

    case SEIZURE_USER_RESPONSES.notSure:
      return null;

    /** Let's hope we'll never be in this case. */
    default:
      return null;
  }
};

const mapServerPatientInfoToLocal = (patientInfo) => ({
  patientId: patientInfo.id,
  linkId: patientInfo.currentLinkId,
  accountNumber: patientInfo.accountNumber,
  firstName: patientInfo.firstName,
  lastName: patientInfo.lastName,
  email: patientInfo.email,
  dob: serverDateToGMT(patientInfo.dob),
  lastActive: patientInfo.lastActive && new Date(patientInfo.lastActive),
  gender: patientInfo.gender && patientInfo.gender.toLowerCase(),
  phoneNumber: patientInfo.phoneNumber,
  lastSeizure: patientInfo.lastReportedSeizure && new Date(patientInfo.lastReportedSeizure),
  diagnosedSince: capFirstCharOnly(
    _.get(patientInfo, 'diagnosedWithEpilepsyDuration.value'),
  ),
  timeZone: patientInfo.timeZone,
  thresholdMet: patientInfo.latestThresholdDate && new Date(patientInfo.latestThresholdDate),
});

const mapServerPatientsListToLocal = (data) => data.map((link, index) => ({
  dateOfRequest: new Date(link.dateOfRequest),
  dateOfDeactivation: new Date(link.dateOfDeactivation),
  /** patient data */
  ...mapServerPatientInfoToLocal(link.patientData),
  /** For unlinked patients there's no linkId,
   * let's use index instead, as it's needed for 'key' value for the rows of the table
   * IMPORTANT: to have linkId added after ...mapServerPatientInfoToLocal()
   *  to avoid overwriting the linkId field,
   *  as at this point BE doesn't return linkId value in patient data for patient lists
   */
  linkId: link.id || index,
}));

const mapServerPatientSeizuresToLocal = (data) => data.map((item) => {
  const seizure = {
    id: item.id,
    convulsions: mapSeizureUserResponse(item.experiencedConvulsions),
    lossOfConsciousness: mapSeizureUserResponse(item.lostConsciousness),
  };

  /** Consider only the date part from the date received from the server
   * Add safe code, in case current 'timestamp' field changes to 'date'
   */
  const dateFromServer = item.timestamp || item.date;
  const dateOnly = dateFromServer.length > 10 ? dateFromServer.substring(0, 10) : dateFromServer;
  seizure.date = serverDateToGMT(dateOnly);

  if (item.recoveryTime) {
    seizure.recoveryTime = refDataService.getValue(
      REFERENCE_DATA_TYPES.seizureRecoveryTime,
      item.recoveryTime.id,
    );
  }

  if (item.triggers) {
    seizure.triggers = _.map(item.triggers, (trigger) => (
      refDataService.getValue(
        REFERENCE_DATA_TYPES.seizureTrigger,
        trigger.id,
      )
    ));
  }

  if (_.isBoolean(item.didGoToER)) {
    seizure.didGoToER = item.didGoToER;
  }

  if (_.isBoolean(item.didUseRescueMedication)) {
    seizure.didUseRescueMedication = item.didUseRescueMedication;
  }

  return seizure;
});

const mapServerPatientSymptomsToLocal = (data) => data.map((symptom) => {
  const symptomNameId = _.get(symptom, 'sideEffect.id');

  return {
    id: symptom.id,
    date: serverDateToGMT(symptom.date),
    severity: symptom.severity,
    symptomNameId,
    symptomNameValue: refDataService.getValue(REFERENCE_DATA_TYPES.sideEffect, symptomNameId),
  };
});

const mapServerPatientMedicationAdherenceToLocal = (data) => data.map((med) => {
  const medicationNameId = _.get(med, 'medication.id');

  return {
    date: serverDateToGMT(med.date),
    medicationNameId,
    medicationNameValue: refDataService.getValue(
      REFERENCE_DATA_TYPES.medications,
      medicationNameId,
    ).name,
  };
});

const mapServerPatientSurveyResultsToLocal = (data) => data.map((result) => ({
  id: result.id,
  type: SURVEY_TYPES[result.type],
  score: result.score,
  maxScore: result.maxScore,
  date: new Date(result.timestamp),
}));

const mapServerPatientEventsToLocal = (data) => data.map((result, index) => ({
  id: result.id || index,
  type: THRESHOLD_EVENT_TYPES[result.eventType],
  /**
   * Here, we don't want local timezone to be applied,
   *  so normally, we would use serverDateToGMT(), as value comes in YYYY-MM-DD format,
   * but we still use new Date(), as we compare 'date' with other achievement dates (when sorting),
   * eg. with 'date' from mapServerPatientSurveyResultsToLocal() above
   * Timezone will be handled at display time (see translation key)
   */
  date: new Date(result.date),
}));

export default {
  mapServerPatientInfoToLocal,
  mapServerPatientSeizuresToLocal,
  mapServerPatientsListToLocal,
  mapServerPatientSymptomsToLocal,
  mapServerPatientMedicationAdherenceToLocal,
  mapServerPatientSurveyResultsToLocal,
  mapServerPatientEventsToLocal,
};
