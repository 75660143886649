/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import faqActions from 'redux/actions/faqActions';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import KnPageContent, { KnBrightBox } from 'components/Content';
import { KnBoldSectionHeader, KnSectionHeader } from 'components/Typography';

const KnFAQItem = ({ question, answer }) => (
  <Box>
    <Typography variant="h6" component={KnSectionHeader}>
      {question}
    </Typography>
    <Box pb={2}>
      {documentToReactComponents(answer)}
    </Box>
  </Box>
);

KnFAQItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.shape().isRequired,
};

const FAQPage = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const {
    error,
    data,
  } = useSelector((state) => state.faq);

  useEffect(() => {
    dispatch(faqActions.fetchFAQ());
  }, [dispatch]);

  return (
    <KnPageContent>
      <Typography gutterBottom variant="h4" component={KnBoldSectionHeader}>
        {translate('FAQ.title')}
      </Typography>
      <KnBrightBox display="flex" flexDirection="column" flex="1" p={2}>
        {error && <p data-testid="faq-error-message">{translate('FAQ.ERROR_MESSAGES.dataLoadingError')}</p>}
        {!error
          && data.map((item) => <KnFAQItem question={item.question} answer={item.answer} />)}
      </KnBrightBox>
    </KnPageContent>
  );
};

export default FAQPage;
