/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const mapServerSideEffectsToLocal = (sideEffects) => sideEffects.map((item) => ({
  keyId: uuidv4(), /** Used in the UI as component key */
  symptomId: item.sideEffect.id,
  severity: item.severity,
}));

const mapLocalSideEffectsToServer = (sideEffects) => sideEffects.map((sideEffect) => ({
  sideEffect: {
    id: Number(sideEffect.symptomId),
  },
  severity: Number(sideEffect.severity),
}));

/**
 * @param {object} data Data object with seizures and side-effects thresholds.
 * @returns Thresholds payload for the api call
 */
const mapLocalThresholdsToServer = (data) => {
  const seizure = data.seizure
    ? {
      seizure: {
        numberOfSeizures: Number(data.seizure.count),
        timePeriodInDays: Number(data.seizure.days),
      },
    }
    : {};
  const sideEffects = data.sideEffects.length
    ? { sideEffects: mapLocalSideEffectsToServer(data.sideEffects) }
    : {};

  return {
    ...seizure,
    ...sideEffects,
  };
};

const mapServerThresholdsToLocal = (data) => {
  const seizure = _.get(data, 'seizure')
    ? {
      seizure: {
        count: _.get(data, 'seizure.numberOfSeizures'),
        days: _.get(data, 'seizure.timePeriodInDays'),
      },
    }
    : {};
  const sideEffects = _.get(data, 'sideEffects')
    ? { symptoms: mapServerSideEffectsToLocal(_.get(data, 'sideEffects')) }
    : { };

  const thresholds = {
    ...seizure,
    ...sideEffects,
  };

  return thresholds;
};

const mapServerThresholdPresetsToLocal = (presets) => (
  presets.map((preset) => ({
    medicationId: preset.medication.id,
    sideEffects: mapServerSideEffectsToLocal(preset.sideEffectThresholds),
  }))
);

export default {
  mapLocalThresholdsToServer,
  mapServerThresholdsToLocal,
  mapServerThresholdPresetsToLocal,
};
