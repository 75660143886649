/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import { styled } from '@material-ui/core/styles';
import React, {
  useCallback, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  ready, create, color, Container, Circle, Scrollbar, percent, useTheme, Label,
} from '@amcharts/amcharts4/core';
import {
  XYChart, ColumnSeries, LineSeries, DateAxis, ValueAxis,
} from '@amcharts/amcharts4/charts';
import {
  SEIZURE_TYPES,
  SEIZURES_COLORS,
  SYMPTOM_SEVERITY_COLORS,
  MEDICATION_ADHERENCE_COLOR,
  INSIGHTS_METRICS_DEFAULT_TIME_RANGE,
} from 'Constants';
import sub from 'date-fns/sub';
import add from 'date-fns/add';
import isSameDay from 'date-fns/isSameDay';
import palette from 'styles/colors';
import { translate } from 'i18n/i18n';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

const labelsColor = color(palette.brownishGrey);
const dateAxisLabelsFontSize = 14;
const valueAxisLabelsFontSize = 11;
const dateAxisMonthLabelFormat = 'MMM';
const dateAxisDayLabelFormat = 'MM/dd';

const tooltipLabel = color(palette.black.black1);
const tooltipBackground = color(palette.white.white2);
const tooltipFontSize = 12;
const bulletStroke = color(palette.white.white2);

const sideEffectsChartBaseGrid = color(palette.copper);
const medicationChartBaseGrid = MEDICATION_ADHERENCE_COLOR;
const columnsContainerOutlineColor = palette.coolGrey.coolGrey2;

const maxBulletSize = 8;
const minBulletSize = 5;

const chartId = 'detailed-metrics-chart';

const chartFontFamily = 'DIN Next LT W01 Regular';

const bulletChartTooltipStyle = [
  `background-color: ${tooltipBackground}`,
  `color: ${tooltipLabel}`,
  'padding: 12px 16px',
  'min-width: 163px',
].join(';');

const tooltipSectionStyle = 'padding-bottom: 8px';

/**
 * Amcharts dimensions are relative to the chart container.
 * Define actual sizes here.
 */
const KnInsightsMetricsChartContainer = styled('div')(({ theme }) => ({
  height: 360,
  width: 868,
  marginTop: theme.spacing(1),

  '& g > g:last-child rect': {
    strokeWidth: 1,
    stroke: columnsContainerOutlineColor,
    strokeOpacity: 0.3,
  },
}));

/**
 * Helper function to map the maximum severity of a
 * side-effect data set to a color.
 *
 * @param {array} list List of side-effects data.
 */
const getColorBySeverity = (list) => {
  /**
   * The tooltip adapter function is called even when hovering
   * the chart, while the request for the data is ongoing.
   * Return a color until the data will be received to prevent
   * a UI crash.
   */
  if (!list.length) return SYMPTOM_SEVERITY_COLORS.mild;

  const maxSeverityItem = _.maxBy(list, 'severity');
  if (maxSeverityItem) {
    switch (maxSeverityItem.severity) {
      case 1:
        return SYMPTOM_SEVERITY_COLORS.mild;
      case 5:
        return SYMPTOM_SEVERITY_COLORS.severe;
      default:
        return SYMPTOM_SEVERITY_COLORS.moderate;
    }
  }

  return SYMPTOM_SEVERITY_COLORS.mild;
};

/**
 * Because the chart data is grouped, depending on the zoom level, the data might
 * either be stored as a list in the groupDataItems property OR as a single data
 * item in the dataContext property. This function makes sure we access the data
 * in a safe manner.
 *
 * @param {object} dataItem Chart tooltip data item object which will have:
 *    - groupDataItems: a list of data items when the chart element it refers to
 *      has more than one data item to represent (eg. more side effects in a day
 *      OR the chart is zoomed out in month view)
 *    - dataContext: data associated with the only data item for this chart element.
 *
 * @returns {array} The data context list from a tooltip data item.
 */
const getTooltipDataContext = (dataItem) => {
  if (dataItem.groupDataItems) {
    return _.map(dataItem.groupDataItems, (item) => item.dataContext);
  }
  return dataItem.dataContext ? [dataItem.dataContext] : [];
};

/**
 * Helper function which either returns the date as month + year if the data is month grouped
 * or the day of the month.
 *
 * Note: there is no direct way of knowing if the data is grouped. Checking only if groupDataItems
 * is defined for the dataItem is not enough as even in day view, the prop exists when there are
 * more than one entries represented (eg. multiple side-effects in one day).
 * In order to decide if the data is aggregated for a month, we check on the tooltip data if there
 * is any entry with a different date than the date the target element has, which is dateX.
 *
 * @param {object} dataItem Chart tooltip data item object.
 */
const getTooltipDate = (dataItem) => {
  const { dateX } = dataItem;
  const tooltipDataContext = getTooltipDataContext(dataItem);
  const isMonthGroup = _.find(
    tooltipDataContext,
    (item) => !isSameDay(item.date, dateX),
  );
  return isMonthGroup ? '{dateX.formatDate(\'MMM yyyy\')}' : '{dateX.formatDate(\'MM/dd\')}';
};

const createChartContainer = () => {
  const container = create(chartId, Container);
  container.fixedWidthGrid = false;
  container.width = percent(100);
  container.height = percent(100);
  container.fontFamily = chartFontFamily;

  const topLabelContainer = container.createChild(Container);
  topLabelContainer.layout = 'absolute';
  topLabelContainer.toBack();
  topLabelContainer.width = percent(100);

  const topLabel = topLabelContainer.createChild(Label);
  topLabel.text = translate('PATIENT_RECORD.sideEffectsInsights.title');
  topLabel.x = 46;
  topLabel.y = 63;
  topLabel.fontFamily = chartFontFamily;
  topLabel.fill = labelsColor;
  topLabel.fontSize = 12;

  const leftLabel = container.createChild(Label);
  leftLabel.isMeasured = false;
  leftLabel.x = 0;
  leftLabel.y = percent(70);
  leftLabel.text = translate('PATIENT_RECORD.seizureMetrics.title');
  leftLabel.fontFamily = chartFontFamily;
  leftLabel.fill = labelsColor;
  leftLabel.fontSize = 12;
  leftLabel.rotation = 270;

  return container;
};

const createChart = (container, data, onRangeChangeEnded, startDate, endDate) => {
  const chart = container.createChild(XYChart);
  chart.width = percent(100);
  chart.height = percent(100);
  chart.zoomOutButton.marginTop = -37;

  const copyData = [...data];
  if (!data.length) {
    const label = chart.createChild(Label);
    label.text = translate('PATIENT_RECORD.seizureInsights.emptyChart');

    /** This positions absolutely the label over the chart. */
    label.isMeasured = false;
    label.x = percent(50);
    label.y = percent(50);
    label.fontFamily = chartFontFamily;
    label.fill = labelsColor;
  }

  /**
   * If we don't have entries for the start OR end date, we add
   * these so we see the full range of the chart all the time.
   * The start date is prepended in the list to preserve the
   * chronological order of the data.
   */
  if (!_.find(copyData, (item) => isSameDay(item.date, startDate))) {
    copyData.unshift({ date: startDate });
  }
  if (!_.find(copyData, (item) => isSameDay(item.date, endDate))) {
    copyData.push({ date: endDate });
  }

  chart.data = copyData;

  /** Set the chart color list for the chart series. */
  chart.colors.list = SEIZURES_COLORS;

  /** On horizontal axis we show the timeline. */
  const dateAxis = chart.xAxes.push(new DateAxis());
  dateAxis.renderer.grid.template.location = 0;
  dateAxis.dateFormats.setKey('month', dateAxisMonthLabelFormat);
  dateAxis.dateFormats.setKey('day', dateAxisDayLabelFormat);
  dateAxis.renderer.labels.template.fontSize = dateAxisLabelsFontSize;
  dateAxis.renderer.labels.template.fill = labelsColor;
  dateAxis.groupData = true;

  /**
   * The data will be grouped by months and by days.
   * The data granularity will change based on the zoom
   * level.
   */
  dateAxis.groupIntervals.setAll([
    { timeUnit: 'day', count: 1 },
    { timeUnit: 'month', count: 1 },
  ]);

  /** On vertical axis we show the seizure metrics grid. */
  const valueAxis = chart.yAxes.push(new ValueAxis());
  valueAxis.min = 0;

  /** Force integer-only axis labels */
  valueAxis.maxPrecision = 0;

  valueAxis.renderer.labels.template.fontSize = valueAxisLabelsFontSize;
  valueAxis.renderer.labels.template.fill = labelsColor;
  valueAxis.renderer.grid.template.location = 0;
  valueAxis.align = 'right';

  /** Enable range selector for horizontal axis. */
  chart.scrollbarX = new Scrollbar();
  chart.scrollbarX.margin(18, 0, 40, 0);
  chart.leftAxesContainer.layout = 'vertical';
  chart.leftAxesContainer.reverseOrder = true;

  /**
   * The "ready" event is invoked when container/chart and all its children are ready (drawn).
   * Zoom in the chart on the last 30 days.
   * Note: we add an extra 1 day to the end, as chart does the same,
   *  and we want the chart to be visible till the end
   */
  chart.events.on('ready', () => {
    const start = sub(endDate, { days: INSIGHTS_METRICS_DEFAULT_TIME_RANGE.days - 1 });
    const end = add(endDate, { days: 1 });
    /**
     * We zoom by default to last 30 days.
     * The boolean arguments are so that amcharts will not invoke range change events
     * and it will not play zoom animations.
     */
    dateAxis.zoomToDates(
      start,
      end,
      true,
      true,
    );

    /** Register on event when range change event ends. */
    dateAxis.events.on('rangechangeended', onRangeChangeEnded);
    onRangeChangeEnded({
      target: {
        minZoomed: start,
        maxZoomed: end,
      },
    });
  });

  return chart;
};

const createSeries = (chart, field) => {
  const series = chart.series.push(new ColumnSeries());
  series.id = field;
  series.dataFields.valueY = field;
  series.dataFields.dateX = 'date';
  series.stacked = true;
  series.columns.template.width = percent(60);
  series.groupFields.valueY = 'sum';

  series.tooltip.label.fontSize = tooltipFontSize;
  series.tooltip.background.cornerRadius = 0;
  series.tooltip.pointerOrientation = 'down';
  series.tooltip.label.paddingLeft = 0;
  series.tooltip.label.paddingRight = 0;
  series.tooltip.label.paddingTop = 0;
  series.columns.template.tooltipHTML = '';

  series.columns.template.adapter.add('tooltipHTML', (html, target) => {
    const { dataItem } = target;
    if (dataItem) {
      const seizureData = getTooltipDataContext(dataItem);
      const date = getTooltipDate(dataItem);
      let extraInfo = '';

      const seriesData = _.filter(seizureData, (item) => !!item[series.id]);

      if ((seriesData.length === 1) && (seriesData[0][series.id] === 1)) {
        const {
          triggers,
          recoveryTime,
          didGoToER,
          didUseRescueMedication,
        } = seriesData[0];

        const hasRecoveryInfo = (
          (recoveryTime && !!recoveryTime[series.id])
          || (didGoToER && !_.isUndefined(didGoToER[series.id]))
          || (didUseRescueMedication && !_.isUndefined(didUseRescueMedication[series.id]))
        );
        const extraSeizureInfo = ((triggers && !!triggers[series.id]) || hasRecoveryInfo);

        if (extraSeizureInfo) {
          if (triggers && triggers[series.id]) {
            extraInfo = extraInfo.concat(`
              <div style="${tooltipSectionStyle}">
                <strong>${translate('PATIENT_RECORD.seizureInsights.tooltip.triggers').toUpperCase()}</strong>
                <div>${triggers[series.id].join(', ')}</div>
              </div>
            `);
          }

          if (hasRecoveryInfo) {
            let recoveryBlock = `
              <strong>${translate('PATIENT_RECORD.seizureInsights.tooltip.recovery').toUpperCase()}</strong>
            `;

            if (recoveryTime && recoveryTime[series.id]) {
              recoveryBlock = recoveryBlock.concat(`<div>${recoveryTime[series.id]}</div>`);
            }

            if (didGoToER && !_.isUndefined(didGoToER[series.id])) {
              const erVisit = didGoToER[series.id]
                ? translate('PATIENT_RECORD.seizureInsights.tooltip.ERVisit')
                : translate('PATIENT_RECORD.seizureInsights.tooltip.noERVisit');
              recoveryBlock = recoveryBlock.concat(`<div>${erVisit}</div>`);
            }

            if (didUseRescueMedication && !_.isUndefined(didUseRescueMedication[series.id])) {
              const rescueMedication = didUseRescueMedication[series.id]
                ? translate('PATIENT_RECORD.seizureInsights.tooltip.rescueMedication')
                : translate('PATIENT_RECORD.seizureInsights.tooltip.noRescueMedication');
              recoveryBlock = recoveryBlock.concat(`<div>${rescueMedication}</div>`);
            }
            extraInfo = extraInfo.concat(recoveryBlock);
          }
        }
      }

      return (`
        <div style="${bulletChartTooltipStyle}">
          <div style="${tooltipSectionStyle}">
            <strong>${translate('PATIENT_RECORD.seizureInsights.tooltip.date').toUpperCase()}</strong>
            <div>${date}</div>
          </div>
          <div style="${tooltipSectionStyle}">
            <strong>${translate('PATIENT_RECORD.seizureInsights.tooltip.type').toUpperCase()}</strong>
            <div>
              ${translate(`PATIENT_RECORD.seizureInsights.chartLegend.${series.id}`).replace(/0/g, 'div')}
            </div>
          </div>
          <div style="${tooltipSectionStyle}">
            <strong>${translate('PATIENT_RECORD.seizureInsights.tooltip.count').toUpperCase()}</strong>
            <div>{valueY}</div>
          </div>
          ${extraInfo}
        </div>
      `);
    }
    return '';
  });
  return series;
};

/**
 * Creates and adds a line series to the chart instance.
 *
 * @param {object} chart Chart instance.
 * @param {array} data The list of series data source.
 * @param {string} name Name of the series.
 * @param {object} colors Object of series specific colors.
 * @param {Function} tooltipAdapter Adapter function for the tooltip content.
 * @param {Function} bulletFillAdapter Adapter function for the bullet fill color.
 */
const createLineSeries = (
  chart, data, name, colors, tooltipAdapter, bulletFillAdapter, startDate,
) => {
  const series = chart.series.push(new LineSeries());
  series.id = name;
  const valueAxis = chart.yAxes.push(new ValueAxis());
  valueAxis.align = 'right';
  valueAxis.renderer.grid.template.disabled = true;
  valueAxis.renderer.baseGrid.stroke = colors.grid;
  valueAxis.renderer.baseGrid.strokeWidth = 2;
  valueAxis.renderer.labels.template.disabled = true;

  /**
   * The line chart uses a separate valueAxis. As it
   * holds no actual data, we display no labels.
   * This will narrow the valueAxis.
   */
  valueAxis.height = 40;

  /**
   * If the series don't have any data to represent,
   * we still want to show the line. Pushing one empty
   * element will render the line chart.
   */
  if (!data.length) {
    data.push({ date: startDate, y: 0 });
  }

  series.data = data;
  series.dataFields.dateX = 'date';
  series.dataFields.valueY = 'y';
  series.dataFields.value = 'value';
  series.groupFields.value = 'sum';
  series.yAxis = valueAxis;
  series.strokeWidth = 0;

  const bullet = series.bullets.push(new Circle());
  /** Set a stroke color to differentiate better bullets when they are close. */
  bullet.stroke = bulletStroke;

  /** Series basic tooltip styling. */
  series.tooltip.pointerOrientation = 'up';
  series.tooltip.background.cornerRadius = 0;
  series.tooltip.label.fontSize = tooltipFontSize;
  series.tooltip.label.fill = tooltipLabel;
  series.tooltip.label.paddingLeft = 0;
  series.tooltip.label.paddingRight = 0;
  series.tooltip.label.paddingBottom = 0;
  series.tooltip.getFillFromObject = false;
  series.tooltip.background.fill = colors.defaultTooltip;

  bullet.tooltipHTML = '';
  bullet.adapter.add('tooltipHTML', (html, target) => tooltipAdapter(target, series));

  bullet.adapter.add('fill', bulletFillAdapter);

  /**
   * The bullet size needs to be proportional
   * with the number of grouped items from the
   * data set the bullet represents. The number
   * of items is aggregated in the value
   * dataField. Here we set the min and max sizes
   * of the bullets and set logarithmic to true
   * for a better size vizualization.
   */
  series.heatRules.push({
    target: bullet,
    min: minBulletSize,
    max: maxBulletSize,
    property: 'radius',
    dataField: 'value',
    logarithmic: true,
  });

  return series;
};

const sideEffectsTooltipAdapter = (target, series) => {
  /** Gets the set of side-effects the bullet represents. */
  if (target.dataItem) {
    const symptomsData = getTooltipDataContext(target.dataItem);
    const date = getTooltipDate(target.dataItem);

    const groupedSymptoms = _.groupBy(symptomsData, 'symptomNameValue');
    /**
     * The symptoms are displayed on separate lines, prepended with a dot
     * mapped to the color of the maximum severity for that symptom and its count.
     */
    const symptomLines = _.map(groupedSymptoms, (list, name) => {
      const severityDotColor = getColorBySeverity(list);
      return (`
        <span style="color: ${severityDotColor}">●</span>
        ${translate(
          'PATIENT_RECORD.sideEffectsInsights.symptomDetails',
          { name, count: list.length },
        )}
        <br />
      `);
    });

    /** Series tooltip fill color is depended on the maximum severity of symptoms set. */
    if (symptomsData.length) {
      /* eslint-disable-next-line no-param-reassign */
      series.tooltip.background.fill = getColorBySeverity(symptomsData);
    }

    return (`
      <div style="${bulletChartTooltipStyle}">
        <strong>
          ${translate('PATIENT_RECORD.sideEffectsInsights.title').toUpperCase()} (${date})
        </strong>
        <div>
          ${symptomLines.join('')}
        </div>
      </div>
    `);
  }
  return '';
};

const sideEffectsBulletFillAdapter = (fill, target) => {
  /** Bullet color is dependent on the maximum severity of symptoms set. */
  if (target.dataItem) {
    const symptomsData = getTooltipDataContext(target.dataItem);
    return getColorBySeverity(symptomsData);
  }
  return SYMPTOM_SEVERITY_COLORS.mild;
};

const createSideEffectsSeries = (chart, data, name, startDate) => {
  const colors = {
    grid: sideEffectsChartBaseGrid,
    defaultTooltip: SYMPTOM_SEVERITY_COLORS.severe,
  };
  createLineSeries(
    chart,
    data,
    name,
    colors,
    sideEffectsTooltipAdapter,
    sideEffectsBulletFillAdapter,
    startDate,
  );
};

const medicationsTooltipAdapter = (target) => {
  if (target.dataItem) {
    /** Gets the set of medications the bullet represents. */
    const medicationsData = getTooltipDataContext(target.dataItem);
    const date = getTooltipDate(target.dataItem);

    const groupedMedications = _.groupBy(medicationsData, 'medicationNameValue');

    /**
     * The medications are displayed on separate lines, with their missed times count.
     */
    const medsLines = _.map(groupedMedications, (list, name) => (`
    <div>
      ${translate('PATIENT_RECORD.medicationsInsights.medDetails',
        { name, count: list.length })}
    </div>`));

    return (`
      <div style="${bulletChartTooltipStyle}">
        <strong>
          ${translate('PATIENT_RECORD.medicationsInsights.title').toUpperCase()} (${date})
        </strong>
        <div>
          ${medsLines.join('')}
        </div>
      </div>
    `);
  }
  return '';
};

const medicationBulletFillAdapter = () => MEDICATION_ADHERENCE_COLOR;

const createMedicationsAdherenceSeries = (chart, data, name, startDate) => {
  const colors = {
    grid: medicationChartBaseGrid,
    defaultTooltip: MEDICATION_ADHERENCE_COLOR,
  };

  createLineSeries(
    chart,
    data,
    name,
    colors,
    medicationsTooltipAdapter,
    medicationBulletFillAdapter,
    startDate,
  );
};

const KnInsightsMetricsChart = (props) => {
  const {
    seizureData, symptomsData, medicationsData, updateRanges, toggledSeizure, startDate, endDate,
  } = props;
  const chartContainer = useRef(null);
  const chart = useRef(null);
  const rangeStart = useRef(null);
  const rangeEnd = useRef(null);

  const onRangeChangeEnded = useCallback(({ target }) => {
    const minZoomed = new Date(target.minZoomed);
    const maxZoomed = new Date(target.maxZoomed);

    if (!rangeStart.current && !rangeEnd.current) {
      rangeStart.current = minZoomed;
      rangeEnd.current = maxZoomed;
      updateRanges(minZoomed, maxZoomed);
    }

    /**
     * The range selector throws change events for hours and minutes differences.
     * We are interested only on days changes to update the ranges.
     */
    if ((rangeStart.current && !isSameDay(rangeStart.current, minZoomed))
      || (rangeEnd.current && !isSameDay(rangeEnd.current, maxZoomed))
    ) {
      rangeStart.current = minZoomed;
      rangeEnd.current = maxZoomed;
      updateRanges(rangeStart.current, rangeEnd.current);
    }
  }, [updateRanges]);

  useTheme(am4themesAnimated);
  useEffect(() => {
    ready(() => {
      chartContainer.current = createChartContainer();
      chart.current = createChart(
        chartContainer.current,
        seizureData,
        onRangeChangeEnded,
        startDate,
        endDate,
      );

      /**
       * Note: the series color used is based
       * on the order in which the series are
       * added: the series order must match the
       * colors list order.
       */
      createSeries(chart.current, SEIZURE_TYPES.ca);
      createSeries(chart.current, SEIZURE_TYPES.cna);
      createSeries(chart.current, SEIZURE_TYPES.cu);
      createSeries(chart.current, SEIZURE_TYPES.nca);
      createSeries(chart.current, SEIZURE_TYPES.ncna);
      createSeries(chart.current, SEIZURE_TYPES.ncu);
      createSeries(chart.current, SEIZURE_TYPES.ua);
      createSeries(chart.current, SEIZURE_TYPES.una);
      createSeries(chart.current, SEIZURE_TYPES.ucua);

      createMedicationsAdherenceSeries(chart.current, medicationsData, 'medications', startDate);

      createSideEffectsSeries(chart.current, symptomsData, 'symptoms', startDate);
    });

    return () => {
      /** Cleanup the amcharts data and events on unmount. */
      if (chartContainer.current) {
        chartContainer.current.dispose();
      }
    };
  }, [seizureData, symptomsData, medicationsData, onRangeChangeEnded, startDate, endDate]);

  useEffect(() => {
    if (toggledSeizure.type && chart.current) {
      const series = chart.current.map.getKey(toggledSeizure.type);

      if (series) {
        if (series.isHiding || series.isHidden) {
          series.show();
        } else {
          series.hide();
        }
      }
    }
  }, [toggledSeizure]);

  return (
    <KnInsightsMetricsChartContainer id={chartId} />
  );
};

KnInsightsMetricsChart.defaultProps = {
  symptomsData: null,
  medicationsData: null,
};

KnInsightsMetricsChart.propTypes = {
  seizureData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  symptomsData: PropTypes.arrayOf(PropTypes.shape({})),
  medicationsData: PropTypes.arrayOf(PropTypes.shape({})),
  toggledSeizure: PropTypes.shape({ type: PropTypes.string.isRequired }).isRequired,
  updateRanges: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
};

export { getColorBySeverity };

export default KnInsightsMetricsChart;
