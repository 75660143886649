/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

export default {
  MuiCard: {
    root: {
      borderRadius: 2,
      width: '100%',
      maxWidth: 474,
      alignSelf: 'center',
      /** overflow: hidden was preventing the page from having scroll if the
       * window height was smaller than the card's content on Safari.
       */
      overflowY: 'auto',

      '& .MuiCardContent-root': {
        padding: 32,

        '&:last-child': {
          paddingBottom: 32,
        },
      },
    },
  },
};
