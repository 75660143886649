/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import KnTextField from 'components/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { KnBoldSectionHeader } from 'components/Typography';
import KnButton from 'components/Button';
import { emailFormatValidator } from 'utils/customFieldValidators';
import forgotPasswordActions from 'redux/actions/forgotPasswordActions';

const initialLookupInfo = {
  email: '',
};

const EmailLookupStep = ({ submitDisabled, onSuccess }) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const {
    handleSubmit, errors, control,
  } = useForm({
    mode: 'onBlur',
    defaultValues: initialLookupInfo,
    reValidateMode: 'onBlur',
  });

  /** We can cache the validation rules, as they should change only if the
   * translation is updated so that the messages are in the correct language.
   */
  const emailValidationRules = useMemo(() => ({
    required: {
      value: true,
      message: translate('FORGOT_PASSWORD.FIELD_VALIDATION_MESSAGES.email.required'),
    },
    validate: {
      emailFormat: emailFormatValidator,
    },
  }), [translate]);

  /** This function will be called by handleSubmit with form data
   * only when the data is valid. We can make the forgot password call now.
   */
  const onSubmit = (formData, e) => {
    e.preventDefault();
    dispatch(forgotPasswordActions.forgotPassword(formData.email))
      .then((response) => {
        if (response && !response.error) {
          /** Fogot password call was successful, send response to step 2 of the flow */
          onSuccess({ username: response });
        }
      });
  };

  return (
    <>
      <Typography variant="h6" component={KnBoldSectionHeader} marginBottom>
        {translate('FORGOT_PASSWORD.emailLookupTitle')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          as={KnTextField}
          name="email"
          rules={emailValidationRules}
          label={translate('GENERAL.email')}
          inputProps={{ 'data-testid': 'email-input-field', maxLength: 256 }}
          InputLabelProps={{ 'data-testid': 'email-input-label' }}
          error={errors.email}
          control={control}
        />
        <Box
          pt={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <KnButton
            variant="text"
            type="button"
            route="/"
            data-testid="login-link"
          >
            {translate('FORGOT_PASSWORD.backToLogin')}
          </KnButton>
          <KnButton
            disabled={submitDisabled}
            data-testid="send-code-button"
          >
            {translate('FORGOT_PASSWORD.sendCode')}
          </KnButton>
        </Box>
      </form>
    </>
  );
};

EmailLookupStep.propTypes = {
  submitDisabled: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default EmailLookupStep;
