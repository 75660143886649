/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
/** TODO: consider current language when loaded reference data files  */
import medsData from 'i18n/reference/medications_en.json';
import refData from 'i18n/reference/simpleReference_en.json';

/**
 * Load reference data from local JSON files
 * for easy access
 */
const ReferenceData = {
  Medications: medsData,
  ...refData,
};

/** Reference data types */
export const REFERENCE_DATA_TYPES = {
  comorbidity: 'Comorbidity',
  diagnosedDuration: 'DiagnosedWithEpilepsyDuration',
  medications: 'Medications',
  medicationUsageDuration: 'MedicationUsageDuration',
  dosageTimePeriod: 'MedicationDosageTimePeriod',
  seizureRecoveryTime: 'SeizureRecoveryTime',
  seizureTrigger: 'SeizureTrigger',
  seizureTimePeriod: 'SeizureTimePeriod',
  sideEffect: 'SideEffect',
  messageEventType: 'MessageEventType',
};

/**
 * Gets the list of reference data for a given type
 *
 * @param {string} referenceType
 * @returns {array} List of reference data objects
 */
const getList = (referenceType) => ReferenceData[referenceType];

/**
 * Searches for a given type of reference data by id,
 * and returns the value of it if it's a simple reference data,
 * or the entire object, if it's a medication
 *
 * @param {string} referenceType
 * @param {number} dataId
 * @returns {any} reference data item for meds, title value for the rest, `undefined` if not found
 */
const getValue = (referenceType, dataId) => {
  const valuesList = getList(referenceType);
  /** Handle Medication reference data differently than the simple reference data */
  if (referenceType === REFERENCE_DATA_TYPES.medications) {
    return _.find(valuesList, { id: dataId });
  }
  return _.get(_.find(valuesList, { id: dataId }), 'title');
};

export default {
  getValue,
  getList,
};
