/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import KnPageContent from 'components/Content';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EmailLookupStep from './EmailLookupStep';
import ChangePasswordStep from './ChangePasswordStep';
import PasswordConfirmedStep from './PasswordConfirmedStep';

const stepComponents = [EmailLookupStep, ChangePasswordStep, PasswordConfirmedStep];

const ForgotPasswordPage = () => {
  const appStore = useSelector((state) => state.app);
  const [currentStep, setCurrentStep] = useState(0);
  const stepsData = useRef({});
  const StepComponent = stepComponents[currentStep];

  /**
   * Called when a card (step) succeeds
   */
  const nextStep = (response) => {
    stepsData.current = {
      ...stepsData.current,
      ...response,
    };
    setCurrentStep(currentStep + 1);
  };

  return (
    <KnPageContent dark>
      <Card elevation={0}>
        <CardContent>
          <StepComponent
            stepsData={stepsData.current}
            submitDisabled={appStore.busyCount > 0}
            onSuccess={nextStep}
          />
        </CardContent>
      </Card>
    </KnPageContent>
  );
};

export default ForgotPasswordPage;
