/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { createContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import KnDialog from './Dialog';

const KnDialogServiceContext = createContext(Promise.reject);

export const KnDialogServiceProvider = ({ children }) => {
  const [dialogState, setDialogState] = useState(null);
  const awaitingPromiseRef = useRef();

  const handleClose = () => {
    /**
     * Note: we don't reject the promise on cancel as we usually
     * don't want to do on cancel anything more than closing
     * the modal. To be revisited if we ever need to subscribe
     * to promise rejection.
     */
    setDialogState(null);
  };

  const handleSubmit = () => {
    /**
     * On submit, we resolve the promise so that any subscriber will
     * be notified and can handle dialog submition.
     */
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    setDialogState(null);
  };

  const openDialog = (options) => {
    setDialogState(options);
    return new Promise((resolve, reject) => {
      /** Save the promise result to the ref. */
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  return (
    <KnDialogServiceContext.Provider
      value={openDialog}
    >
      {children}
      <KnDialog
        open={!!dialogState}
        onClose={handleClose}
        onSubmit={handleSubmit}
        {...dialogState}
      />
    </KnDialogServiceContext.Provider>
  );
};

KnDialogServiceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useDialog = () => React.useContext(KnDialogServiceContext);
export default useDialog;
