/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { appInsights } from 'appInsights';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { KnBoldSectionHeader } from 'components/Typography';
import KnTextField from 'components/TextField';
import KnButton from 'components/Button';
import registerActions from 'redux/actions/registerActions';
import { APP_PAGE_URLS, TRACKING_EVENTS, ERROR_CODES } from 'Constants';
import { trimValueEmptyValidator } from 'utils/customFieldValidators';

const initialVerifyAccountInfo = {
  confirmationCode: '',
};

const VerifyAccountStep = ({
  submitDisabled, stepsData, onReset, onSuccess,
}) => {
  const { t: translate } = useTranslation();
  const registerStore = useSelector((state) => state.register);
  const {
    control,
    handleSubmit,
    errors,
  } = useForm({
    mode: 'onBlur',
    defaultValues: initialVerifyAccountInfo,
    reValidateMode: 'onBlur',
  });
  const dispatch = useDispatch();

  useEffect(() => {
    appInsights.trackEvent({ name: TRACKING_EVENTS.viewRegisterVerificationCodePage });
  }, []);

  /** We can cache the validation rules, as they should change only if the
   * translation is updated so that the messages are in the correct language.
   */
  const verificationCodeValidationRules = useMemo(() => ({
    required: {
      value: true,
      message: translate('LOGIN.FIELD_VALIDATION_MESSAGES.confirmationCode.required'),
    },
    validate: {
      trimValueEmpty: (value) => (
        trimValueEmptyValidator(value)
          ? translate('LOGIN.FIELD_VALIDATION_MESSAGES.confirmationCode.required')
          : true
      ),
    },
  }), [translate]);

  /** This function will be called by handleSubmit with form data
   * only when the data is valid. We can make the confirmSignIn call now.
   */
  const onSubmit = (formData, e) => {
    e.preventDefault();
    dispatch(registerActions.confirmSignUp(
      stepsData.username,
      formData.confirmationCode,
      stepsData,
    )).then((response) => {
      if (!response.error) {
        /** Confirm sign up was successful, can go to next step */
        appInsights.trackEvent({ name: TRACKING_EVENTS.successfulSignUp });
        onSuccess();
      }
    });
  };

  const onBack = useCallback(() => {
    onReset();
  }, [onReset]);

  const onResend = useCallback(() => {
    dispatch(registerActions.resendSignUp(stepsData.username));
  }, [dispatch, stepsData]);

  const duplicateAccountError = registerStore.error === ERROR_CODES.DUPLICATE_ACCOUNT;
  return (
    <>
      <Typography variant="h6" component={KnBoldSectionHeader} marginBottom>
        {translate('REGISTER.verificationTitle')}
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography paragraph>{translate('REGISTER.verifyAccountHint')}</Typography>
        <Controller
          as={KnTextField}
          name="confirmationCode"
          label={translate('FIELD_LABELS.confirmationCode')}
          rules={verificationCodeValidationRules}
          error={errors.confirmationCode}
          inputProps={{ 'data-testid': 'confirmation-code-input-field', maxLength: 256 }}
          InputLabelProps={{ 'data-testid': 'confirmation-code-input-label' }}
          defaultValue=""
          control={control}
          disabled={duplicateAccountError}
        />
        <Box marginBottom={2}>
          <KnButton
            type="button"
            variant="text"
            route={APP_PAGE_URLS.register}
            data-testid="resend-code-button"
            onClick={onResend}
            disabled={duplicateAccountError}
          >
            {translate('REGISTER.resendCodeButton')}
          </KnButton>
        </Box>

        <Box
          pt={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <KnButton
            type="button"
            variant="text"
            disabled={submitDisabled}
            route={APP_PAGE_URLS.register}
            data-testid="cancel-register-link"
            onClick={onBack}
          >
            {translate('GENERAL.cancelButton')}
          </KnButton>
          <KnButton
            disabled={submitDisabled || duplicateAccountError}
            data-testid="next-button"
          >
            {translate('REGISTER.verifyButton')}
          </KnButton>
        </Box>
      </form>
    </>
  );
};

VerifyAccountStep.propTypes = {
  submitDisabled: PropTypes.bool.isRequired,
  stepsData: PropTypes.shape({ username: PropTypes.string }).isRequired,
  onReset: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default VerifyAccountStep;
