/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import KnIconWrapper from './styles';

const KnDuplicateIcon = (props) => {
  const {
    color,
    ...rest
  } = props;

  return (
    <KnIconWrapper fontSize={16}>
      <SvgIcon
        fontSize="inherit"
        viewBox="3 -3 19 19"
        overflow="visible"
        {...rest}
      >
        <path fill={color} fillRule="evenodd" d="M12.09 0H1.728C.777 0 0 .777 0 1.727v12.091h1.727V1.728h10.364V0zm2.592 3.455h-9.5c-.95 0-1.727.777-1.727 1.727v12.09c0 .95.777 1.728 1.727 1.728h9.5c.95 0 1.727-.777 1.727-1.727V5.182c0-.95-.777-1.727-1.727-1.727zm-9.5 13.818h9.5V5.182h-9.5v12.09z" />
      </SvgIcon>
    </KnIconWrapper>
  );
};

KnDuplicateIcon.propTypes = {
  color: PropTypes.string,
};

KnDuplicateIcon.defaultProps = {
  color: '#666',
};

export default KnDuplicateIcon;
