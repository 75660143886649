/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useCallback, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
  useHistory,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import useUserStatus from 'utils/userStatus';
import initI18n from 'i18n/i18n';
import { ThemeProvider } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from 'styles/theme';
import appActions from 'redux/actions/appActions';
import loginActions from 'redux/actions/loginActions';
import { APP_PAGE_URLS } from 'Constants';
import KnLayout from 'components/Layout';
import LoginPage from 'features/login/LoginPage';
import RegisterPage from 'features/register/RegisterPage';
import HomePage from 'features/home/HomePage';
import InvitePatientsPage from 'features/home/InvitePatientsPage';
import PatientRecordPage from 'features/patient/PatientRecordPage';
import ForgotPasswordPage from 'features/forgotPassword/ForgotPasswordPage';
import SettingsPage from 'features/account/SettingsPage';
import FAQPage from 'features/faq/FAQPage';
import TitrationBuilderPage from 'features/titrations/TitrationBuilderPage';
import TitrationsManagementPage from 'features/titrations/TitrationsManagementPage';
import AssignPresetPage from 'features/patient/AssignPresetPage';
import AssignRegimenPage from 'features/patient/AssignRegimenPage';
import ThresholdSetupPage from 'features/patient/ThresholdSetupPage';
import { KnDialogServiceProvider } from 'components/dialog/DialogService';
import Amplify, { Auth } from 'aws-amplify';
import { addLicense } from '@amcharts/amcharts4/core';

initI18n();

/** Configure Amplify with the CognitoPool settings  */
const cognitoConfig = {
  region: process.env.REACT_APP_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
};
Amplify.configure({ Auth: cognitoConfig });
Auth.configure({ storage: sessionStorage });

const PrivateRoute = (props) => {
  const { component: Component, ...rest } = props;
  const userStatus = useUserStatus();
  const unauthorized = (userStatus === null);

  /**
   * userStatus is undefined while we are waiting for
   * the AWS user data. Until then, we'll show no content.
   */
  if (userStatus === undefined) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={({ ...routerProps }) => (
        unauthorized
          ? <Redirect to="/" />
          : <Component {...routerProps} />
      )}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};

const Main = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userStatus = useUserStatus();

  useEffect(() => {
    addLicense('CH236683054');
  }, []);

  const onIdle = useCallback(() => {
    /** On inactivity, if the user is logged in, trigger a logout. */
    if (userStatus) {
      dispatch(loginActions.logout());
    }
  }, [dispatch, userStatus]);

  useIdleTimer({
    timeout: Number(process.env.REACT_APP_USER_INACTIVITY_TIMEOUT),
    onIdle,
    debounce: 500,
  });

  useEffect(() => {
    /**
     * Fetch BE API version number
     */
    dispatch(appActions.fetchApiVersion());
  }, [dispatch]);

  useEffect(() => {
    /**
     * Listen to React Router's history change event and
     * dispatch an action to remove any application error
     * notification, because it becomes obsolete when
     * leaving the page.
     */
    const unsubscribe = history.listen(() => {
      dispatch(appActions.appPopNotification());
    });

    return unsubscribe;
  }, [dispatch, history]);

  return (
    <Box display="flex" flex="1">
      <Switch>
        <Route exact path="/"><LoginPage /></Route>
        <Route path={APP_PAGE_URLS.register}><RegisterPage /></Route>
        <Route path={APP_PAGE_URLS.forgotPassword}><ForgotPasswordPage /></Route>

        <PrivateRoute path={APP_PAGE_URLS.home} component={HomePage} />
        <PrivateRoute path={APP_PAGE_URLS.patientRecord} component={PatientRecordPage} />
        <PrivateRoute path={APP_PAGE_URLS.settings} component={SettingsPage} />
        <PrivateRoute path={APP_PAGE_URLS.faq} component={FAQPage} />
        <PrivateRoute path={APP_PAGE_URLS.newTitration} component={TitrationBuilderPage} />
        <PrivateRoute path={APP_PAGE_URLS.editTitration} component={TitrationBuilderPage} />
        <PrivateRoute path={APP_PAGE_URLS.titrationsList} component={TitrationsManagementPage} />
        <PrivateRoute path={APP_PAGE_URLS.assignPreset} component={AssignPresetPage} />
        <PrivateRoute path={APP_PAGE_URLS.assignRegimen} component={AssignRegimenPage} />
        <PrivateRoute path={APP_PAGE_URLS.editAssignedRegimen} component={AssignRegimenPage} />
        <PrivateRoute path={APP_PAGE_URLS.threshold} component={ThresholdSetupPage} />
        <PrivateRoute path={APP_PAGE_URLS.invitePatients} component={InvitePatientsPage} />
      </Switch>
    </Box>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <KnLayout>
          <KnDialogServiceProvider>
            <Main />
          </KnDialogServiceProvider>
        </KnLayout>
      </Router>
    </ThemeProvider>
  );
}

export default App;
