/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { Auth } from 'aws-amplify';
import ACTION_TYPES from 'redux/actionTypes';
import appActions from 'redux/actions/appActions';
import { ERROR_CODES } from 'Constants';

/** Currently we make no differentiation on error scenarios */
const handleLoginFailure = (dispatch, errorCode) => {
  dispatch(appActions.appPushNotification(`LOGIN.ERROR_MESSAGES.${errorCode}`));
  return dispatch({ type: ACTION_TYPES.LOGIN_ERROR, error: errorCode });
};

/**
 * Login action creator,
 * performs the first (sign in) step of the MFA authentication
 * @param credentials Object containing email and password
 */
const signIn = (credentials) => {
  const request = () => ({ type: ACTION_TYPES.LOGIN_REQUEST });
  const success = () => ({ type: ACTION_TYPES.LOGIN_SUCCESS });

  return (dispatch) => {
    dispatch(request());
    dispatch(appActions.appPushBusy());
    dispatch(appActions.appPopNotification());

    /** Call Cognito sign in */
    return Auth.signIn(credentials.email.trim(), credentials.password)
      .then(
        (response) => {
          dispatch(success());
          /** Let's just return the response to the caller (sign in card) */
          return response;
        },
        () => handleLoginFailure(dispatch, ERROR_CODES.SIGNIN_ERROR),
      )
      .catch(() => handleLoginFailure(dispatch, ERROR_CODES.SIGNIN_ERROR))
      .finally(() => dispatch(appActions.appPopBusy()));
  };
};

/**
 * Login action creator,
 * performs the second (confirm sign in) step of the MFA authentication
 * @param user CognitoUser object
 * @param code Confirmation code
 */
const confirmSignIn = (user, code) => {
  const request = () => ({ type: ACTION_TYPES.LOGIN_REQUEST });
  const success = () => ({ type: ACTION_TYPES.LOGIN_SUCCESS });

  return (dispatch) => {
    dispatch(request());
    dispatch(appActions.appPushBusy());
    dispatch(appActions.appPopNotification());

    /** Call Cognito confirm sign in
     * Use user.challengeName instead of MFA_TYPES.SMS, to allow for TOTP as well
     */
    return Auth.confirmSignIn(user, code.trim(), user.challengeName)
      .then(
        (response) => {
          dispatch(success());
          return response;
        },
        () => handleLoginFailure(dispatch, ERROR_CODES.MFA_ERROR),
      )
      .catch(() => handleLoginFailure(dispatch, ERROR_CODES.MFA_ERROR))
      .finally(() => dispatch(appActions.appPopBusy()));
  };
};

/**
 * Logout action creator
 * To be revisited, once logout story is defined
 */
const logout = () => (dispatch) => {
  const success = () => ({ type: ACTION_TYPES.LOGGED_OUT });
  dispatch(appActions.appPushBusy());
  dispatch(appActions.appPopNotification());

  return Auth.signOut({ global: false })
    .then(
      () => {
        /** Our PrivateRoute will take care of navigating to login page. */
        dispatch(success());
      },
      () => handleLoginFailure(dispatch, ERROR_CODES.SIGNOUT_ERROR),
    )
    .catch(() => handleLoginFailure(dispatch, ERROR_CODES.SIGNOUT_ERROR))
    .finally(() => dispatch(appActions.appPopBusy()));
};


/** Action used to clear error from the Login page */
const clearError = () => ({ type: ACTION_TYPES.LOGIN_CLEAR_ERROR });

/**
 * Collection of the exposed login-related action creators
 */
export default {
  signIn,
  confirmSignIn,
  logout,
  clearError,
};
