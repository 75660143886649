/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import KnCheckIcon from 'components/icons/CheckIcon';
import KnClearIcon from 'components/icons/ClearIcon';
import useDialog from 'components/dialog/DialogService';
import patientActions from 'redux/actions/patientActions';
import palette from 'styles/colors';

const rejectIconColor = palette.copper;

const KnPatientsVerification = ({ rowData }) => {
  const { t: translate } = useTranslation();
  const dialog = useDialog();
  const dispatch = useDispatch();

  const onAccept = (e) => {
    e.stopPropagation();
    dispatch(patientActions.verifyPatient(rowData.linkId, true));
  };

  const onReject = (e) => {
    e.stopPropagation();
    dialog({
      title: translate('HOME.patientRejectionModal.title'),
      description: translate('HOME.patientRejectionModal.content'),
      submitLabel: translate('HOME.patientRejectionModal.submitButton'),
      closeLabel: translate('GENERAL.cancelButton'),
    }).then(() => {
      dispatch(patientActions.verifyPatient(rowData.linkId, false));
    });
  };

  return (
    <>
      <IconButton onClick={onAccept} data-testid="accept-patient-button">
        <KnCheckIcon />
      </IconButton>
      <IconButton onClick={onReject} data-testid="reject-patient-button">
        <KnClearIcon color={rejectIconColor} />
      </IconButton>
    </>
  );
};

KnPatientsVerification.propTypes = {
  /**
   * NOTE: rowData actually contains all the patient data, not only linkId.
   * But without a way to define an interface for objects and to avoid to have
   * to sync this shape and the patient objects mapped in the patient actions,
   * let's make the linter happy and declare only the one(s) we are currently
   * using in code. Add more as needed.
   */
  rowData: PropTypes.shape({
    linkId: PropTypes.number.isRequired,
  }).isRequired,
};

export default KnPatientsVerification;
