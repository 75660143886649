/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { fade } from '@material-ui/core/styles/colorManipulator';
import palette from '../colors';

const tabsBackgroundColor = palette.white.white2;
const tabInactiveTextColor = fade(palette.black.black1, 0.6);

export default {
  MuiTabs: {
    root: {
      marginBottom: 8,
    },
    flexContainer: {
      backgroundColor: tabsBackgroundColor,
    },
  },
  MuiTab: {
    root: {
      fontWeight: 'normal',
      fontSize: 16,

      '&:not(.Mui-selected)': {
        color: tabInactiveTextColor,
      },
    },
  },
};
