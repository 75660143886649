/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import KnPageContent from 'components/Content';
import { withKeyNamespace } from 'utils/utils';
import { TITRATIONS_CONTEXT, APP_PAGE_URLS } from 'Constants';
import patientActions from 'redux/actions/patientActions';
import KnTitrationForm from 'components/titrations/TitrationForm';
import KnAssignRegimenConfirmationCard from './AssignRegimenConfirmationCard';

const i18nKey = withKeyNamespace('ASSIGN_REGIMEN');

/** Page used for asigning a regimen,
 * or updating (editing) an assigned regimen
 */
const AssignRegimenPage = (props) => {
  const { t: translate } = useTranslation();
  const [successfulSave, setSuccessfulSave] = useState(false);
  const assignedMedications = useRef(null);
  const dispatch = useDispatch();
  const { location, match, history } = props;

  /**
   * Default the router parameters in case the user
   * will navigate directly to the titration page.
   */
  const patientId = _.get(match, 'params.patientId', '');
  const regimenId = _.get(match, 'params.regimenId', '');
  const patientName = _.get(location, 'state.patientName', '');
  const medications = _.get(location, 'state.medications', []);
  const assignedAt = _.get(location, 'state.assignedAt', null);

  useEffect(() => {
    /**
     * This page depends on router parameters data to work as expected.
     * If these are missing, it means the page was accessed directly.
     * Redirect the user to the patient record page which is the
     * starting point of all patient related pages.
     */
    if (!location.state && patientId) {
      history.replace(APP_PAGE_URLS.patientRecord.replace(':patientId', patientId));
    }
  }, [history, location, match, patientId]);

  const title = translate(i18nKey('patientMedicationRegimen'), { name: patientName });

  /** Called both on new assignment and editing an assignment */
  const submitRegimen = useCallback((data) => {
    /** We need a copy of the medications to pass to the confirmation card through props. */
    assignedMedications.current = data.medications;
    return dispatch(patientActions.assignRegimen(data.medications, patientId, regimenId))
      .then(() => { setSuccessfulSave(true); });
  }, [dispatch, patientId, regimenId]);

  return (
    <KnPageContent>
      {!successfulSave && (
        <KnTitrationForm
          context={TITRATIONS_CONTEXT.assignRegimen}
          submitAction={submitRegimen}
          submitButtonLabel={translate(i18nKey('assignRegimenButton'))}
          title={title}
          defaultMedications={medications}
          preventCleanSubmit={!!regimenId}
        />
      )}

      {successfulSave && (
        <KnAssignRegimenConfirmationCard
          patientName={patientName}
          patientId={patientId}
          medications={assignedMedications.current}
          assignedAt={assignedAt}
        />
      )}
    </KnPageContent>
  );
};

AssignRegimenPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      patientName: PropTypes.string.isRequired,
      medications: PropTypes.arrayOf(PropTypes.shape()),
      assignedAt: PropTypes.instanceOf(Date),
    }),
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      patientId: PropTypes.string.isRequired,
      regimenId: PropTypes.string,
    }),
  }).isRequired,
};

export default AssignRegimenPage;
