/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import ACTION_TYPES from 'redux/actionTypes';

const initialState = {
  data: null,
  error: false,
};

const titrationsListReducer = (state = initialState, action) => {
  const { data, type } = action;
  switch (type) {
    case ACTION_TYPES.TITRATION_LIST_REQUEST:
      return {
        ...state,
        error: false,
      };

    case ACTION_TYPES.TITRATION_LIST_SUCCESS:
      return {
        ...state,
        data,
      };

    case ACTION_TYPES.TITRATION_LIST_ERROR:
      return {
        ...state,
        error: true,
      };

    case ACTION_TYPES.TITRATION_DELETED:
      return {
        ...state,
        data: _.filter(state.data, (titration) => (titration.id !== action.titrationId)),
      };

    default:
      return state;
  }
};

export default titrationsListReducer;
