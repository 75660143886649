/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import palette from 'styles/colors';
import { color } from '@amcharts/amcharts4/core';

export const EMAIL_FORMAT = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i;

/** Password should be minimumn 8 characters long and contain
 * at least one uppercase letter, one number and one special character.
 * Cognito allows any password which contains the following alphanumeric and special characters:
 * A-Za-z0-9^$*.[]{}()?"!@#%&/\,><':;|_~`+=
 */
export const PASSWORD_FORMAT = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d^$*.[\]{}()?"!@#%&/\\,><':;|_~`+=]{8,}$/;

/** Regex for name validation */
export const NAME_FORMAT = /^[A-Za-z\u00C0-\u00FF][A-Za-z\u00C0-\u00FF'-]+([ A-Za-z\u00C0-\u00FF][A-Za-z\u00C0-\u00FF'-]+)*$/;

/** By default, we consider phone numbers to be from US */
export const DEFAULT_COUNTRY_CODE = 'US';

/** Regex for titration dosage validation */
export const TITRATION_DOSAGE_FORMAT = /^[0-9]([.][0-9]{1,2})?$|^[1-9][0-9]{1,2}([.][0-9]{1,2})?$|^[1-2][0-9]{1,3}([.][0-9]{1,2})?$|^3000([.][0]{1,2})?$/;

/** Regex for positive integer numbers (eg. used for threshold seizures inputs) */
export const INTEGER_POSITIVE_VALUE_FORMAT = /^[1-9]\d*$/;

/** Regex for titration preset name validation. */
export const TITRATION_NAME_FORMAT = /^[A-Za-z0-9\u00C0-\u00FF'\-#&\s]+$/;

/**
 * API URLS paths relative to the base API URL.
 */
export const API_URLS = {
  /** Public endpoints */
  /** Endpoint for getting BE API version number */
  health: 'health',
  /** Endpoint for getting health systems list */
  healthSystem: 'hcp/healthsystem',
  /** Endpoint for getting user role options */
  userRole: 'hcp/hcp-roles',

  /** Authenticated endpoints */

  /** Endpoint for
   * - registering a new HCP user account (POST)
   * - getting current user info (GET)
   * - updating user profile (PATCH) */
  users: 'users',
  /** Endpoint for getting list of HCP users with physician role */
  physicians: 'users/primary',

  /** GET Patient links list (status to be attached as url param)
   *  PATCH link accept/reject */
  patientLinks: 'patient-links',
  patientDeactivatedLinks: 'patient-links/deactivated',
  /** Endpoint for unlinking a patient (PATCH) */
  patientUnlink: 'patient-links/:linkId/deactivated',
  /** Endpoint for getting patient information */
  patientInfo: 'patients/:patientId',
  /** Endpoint for getting patient seizures data */
  patientSeizures: 'patients/:patientId/seizures',
  /** Endpoint for getting patient symptoms data */
  patientSymptoms: 'patients/:patientId/side-effects',
  /** Endpoint for getting patient medication adherence data */
  patientMedicationAdherence: 'patients/:patientId/medication-adherence',
  /** Endpoint for getting/setting medication regimens */
  patientRegimens: 'patients/:patientId/titrations',
  /** GET, PUT thresholds endpoint */
  patientThresholds: 'patients/:patientId/thresholds',
  /** Endpoint for getting the latest surveys taken by a patient */
  patientSurveys: 'patients/:patientId/surveys',
  /** Endpoint for getting latest events for given types */
  patientEvents: 'patients/:patientId/latest-threshold-events',
  /** GET side-effect threshold defaults per medication */
  thresholdPresets: 'medication-side-effect-threshold-defaults',

  /** Endpoint for editing/deactivating a patient's regimen */
  patientRegimen: 'patients/:patientId/titrations/:regimenId',

  /** GET, POST, DELETE, PUT titrations endpoint */
  titrations: 'titration-presets',

  /** Contentful api */
  faq: 'spaces/:spaceId/environments/:environment/entries?content_type=hcpfaq',

  /** POST invite study patient endpoint */
  inviteStudyPatient: 'patient-invites',
};

export const APP_PAGE_URLS = {
  home: '/home',
  register: '/register',
  forgotPassword: '/forgotPassword',
  patientRecord: '/patient/:patientId',
  settings: '/settings',
  faq: '/faq',
  titrationsList: '/titrationsList',
  newTitration: '/titration',
  editTitration: '/editTitration/:presetId',
  assignPreset: '/assignPreset/:patientId',
  assignRegimen: '/assignRegimen/:patientId',
  editAssignedRegimen: '/editAssignedRegimen/:patientId/:regimenId',
  threshold: '/threshold/:patientId',
  invitePatients: '/invitePatients',
};

/**
 * API request methods
 */
export const API_REQUEST_METHODS = {
  GET: 'GET',
  PATCH: 'PATCH',
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT',
};

/**
 * API request error codes
 */
export const API_REQUEST_ERROR_CODES = {
  /** Server sent error codes */

  BAD_REQUEST: 400,

  UNAUTHORIZED: 401,

  /** Error code if the user has not been registered successfully in our users system. */
  USER_NOT_FOUND: 404,

  /** When tring to save a titration preset with a name that already is used by another preset */
  TITRATION_PRESET_EXISTS: 409,

  /** Codes reserved for our app */

  /** This is our last fallback option for error code, might never get to use it */
  GENERIC_ERROR: 1000,
  /** When no token found (eg. user is not logged in) */
  NO_ACCESS_TOKEN: 1001,
  /** No internet connection */
  NETWORK_ERROR: 1002,
};

/**
 * Locally defined error values for different scenarios,
 * usually maps to an error string key in the translation file
 */
export const ERROR_CODES = {
  SIGNIN_ERROR: 'signInError',
  SIGNOUT_ERROR: 'signOutError',
  MFA_ERROR: 'mfaError',
  SIGNUP_ERROR: 'signUpError',
  RESEND_SIGNUP_ERROR: 'resendSignUpError',
  ACCOUNT_VERIFY_ERROR: 'verifyError',
  FORGOT_PASSWORD_ERROR: 'forgotPasswordError',
  CHANGE_PASSWORD_ERROR: 'changePasswordError',
  FORGOT_PASSWORD_RESEND_ERROR: 'forgotPasswordResendError',
  /** Cognito error codes */
  CODE_MISMATCH: 'CodeMismatchException',
  EXPIRED_CODE: 'ExpiredCodeException',
  NOT_AUTHORIZED: 'NotAuthorizedException',
  /** Lambda related errors */
  LAMBDA_EXCEPTION: 'UserLambdaValidationException',
  /** Part of lambda error message, and also maps to a translation key */
  DUPLICATE_ACCOUNT: 'DUPLICATE_ACCOUNT',
};

/**
 * Aws Amplify's MFA types
 * Currently only SMS (TOTP to come later?)
 */
export const MFA_TYPES = {
  SMS: 'SMS_MFA',
};

export const HCP_USER_STATUS = {
  pending: 'pending',
  active: 'active',
};

/**
 * Patient links statuses;
 * (matching BE values)
 */
export const PATIENT_LINKS_STATUS = {
  /** Verified (linked) patients status */
  accepted: 'accepted',
  /** Unverified patients status */
  pending: 'pending',
  /** Status used when making API call to reject a patient */
  rejected: 'rejected',
  /** This status does not map to a BE status,
   * but on the FE side, we work with this one
   */
  unlinked: 'unlinked',
};

/**
 * User roles (matching BE values)
 */
export const HCP_USER_ROLES = {
  physician: 'physician',
  nurse: 'nurse',
  staff: 'general_staff',
};

/**
 * Medication regimen statuses (matching BE values)
 */
export const REGIMEN_STATUS = {
  notStarted: 'not_started',
  active: 'active',
  completed: 'completed',
  deactivated: 'deactivated',
};

export const METRICS_TYPE = {
  seizure: 'seizure',
  symptom: 'symptom',
};

/**
 * Survey types (matching BE values)
 * and used as key in translation file
 */
export const SURVEY_TYPES = {
  anxiety: 'anxiety',
  depression: 'depression',
};

/**
 * Threshold event types
 * - key matching BE values
 * - value used as key in translation file
 */
export const THRESHOLD_EVENT_TYPES = {
  seizure: 'seizure',
  side_effect: 'side_effect',
};

export const TITRATIONS_CONTEXT = {
  /** Titration input contexts */
  newTitration: 'newTitration',
  editTitration: 'editTitration',
  assignPreset: 'assignPreset',
  assignRegimen: 'assignRegimen',
  /** Titration display contexts */
  titrationsManagement: 'titrationsManagement',
  assignedRegimen: 'assignedRegimen',
  pastRegimen: 'pastRegimen',
};

/**
 * Time period for the top line metrics.
 */
export const TOPLINE_METRICS_PERIOD = {
  days: 90,
};

/**
 * Time period for the insights metrics.
 */
export const INSIGHTS_METRICS_PERIOD = {
  days: 365,
};

/**
 * Default timerange of the insights metrics.
 */
export const INSIGHTS_METRICS_DEFAULT_TIME_RANGE = {
  days: 30,
};

/** Time period of a bucket,
 * when calculating trend indicator
 */
export const METRICS_TREND_PERIOD = {
  days: 30,
};

/**
 * Default number of common symptoms.
 */
export const TOP_SYMPTOMS_COUNT = 3;

/**
 * Format for date values in API requests
 */
export const API_DATE_FORMAT = 'yyyy-MM-dd';

/**
 * Seizures are characterized by convulsions
 * and aware attributes.
 */
export const SEIZURE_TYPES = {
  ca: 'ca',
  cna: 'cna',
  cu: 'cu',
  nca: 'nca',
  ncna: 'ncna',
  ncu: 'ncu',
  ua: 'ua',
  una: 'una',
  ucua: 'ucua',
};

/**
 * The responses for convulsive and loss of consciousness
 * questions from a user when entering a new seizure entry.
 */
export const SEIZURE_USER_RESPONSES = {
  yes: 'yes',
  no: 'no',
  notSure: 'not_sure',
};

/** The maximum number of days for a medication. */
export const TITRATION_MAX_DAYS_DURATION = 14;

/** The maximum number of times per day for a medication. */
export const TITRATION_MAX_FREQUENCY = 4;

/** The maximum severity value of a symptom. */
export const SYMPTOM_MAX_SEVERITY = 5;

/** Units used in Medication Regimens,
 * should be only values accepted by the BE
 */
export const MEDICATION_UNITS = ['mg', 'mL'];

/** Status of medication adherence as reported by patient. */
export const MEDICATION_ADHERENCE_STATUS = {
  missed: 'missed',
};

/**
 * The order of these colors correspond
 * with the order in which the series
 * are added in the chart.
 */
export const SEIZURES_COLORS = [
  color(palette.brightBlue),
  color(palette.lightBlue),
  color(palette.extraLightBlue),
  color(palette.brightPurple),
  color(palette.lightPurple),
  color(palette.extraLightPurple),
  color(palette.brightOrange),
  color(palette.lightOrange),
  color(palette.extraLightOrange),
];

/** Symptoms severity color map. */
export const SYMPTOM_SEVERITY_COLORS = {
  mild: color(palette.veryLightBrown),
  moderate: color(palette.darkPeach),
  severe: color(palette.copper),
};

/** Tracking events for analytics */
export const TRACKING_EVENTS = {
  viewRegisterPage: 'Sign up: Register',
  viewRegisterVerificationCodePage: 'Sign up: Verification code',
  successfulSignUp: 'Sign up: Success',
  viewPatientList: 'Patient List',
  viewPatientRecord: 'Patient Record',
  viewTitrationManager: 'Titration Manager: List',
  viewTitrationBuilder: 'Titration Manager: Builder',
  clickOnAssignMedication: 'Patient Record: Assign',
  successfullyAssignedMedication: 'Patient Record: Assign Medication Success',
  clickSetThreshold: 'Threshold',
  clickThresholdSave: 'Threshold save',
};

/** Base color for medication adherence vizualisations. */
export const MEDICATION_ADHERENCE_COLOR = color(palette.green.royal);

/**
 * Tabs are identified by index. Use these named constants
 * to demistify numeric values. If the tabs order changes,
 * these should be updated also.
 */
export const PATIENTS_LIST_TABS = {
  VERIFIED_PATIENTS: 0,
  UNVERIFIED_PATIENTS: 1,
  UNLINKED_PATIENTS: 2,
};
