/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import KnTextField from 'components/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { KnBoldSectionHeader } from 'components/Typography';
import KnButton from 'components/Button';
import KnValidatedTextField from 'components/ValidatedTextField';
import { APP_PAGE_URLS, PASSWORD_FORMAT } from 'Constants';
import forgotPasswordActions from 'redux/actions/forgotPasswordActions';

const initialChangePasswordInfo = {
  confirmationCode: '',
  newPassword: '',
  confirmPassword: '',
};

const ChangePasswordStep = ({ submitDisabled, stepsData, onSuccess }) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const {
    handleSubmit, errors, control, triggerValidation,
  } = useForm({
    mode: 'onBlur',
    defaultValues: initialChangePasswordInfo,
    reValidateMode: 'onBlur',
  });

  const onPasswordBlur = useCallback(() => {
    /** On password blur, if there is a value in the confirm password,
     * trigger the validation of confirm password field manually,
     * in case a mismatch password error was fixed.
     */
    if (control.getValues('confirmPassword')) {
      triggerValidation('confirmPassword');
    }
  }, [control, triggerValidation]);

  /** This function will be called by handleSubmit with form data
   * only when the data is valid. We can make the forgot password submit call now.
   */
  const onSubmit = (formData, e) => {
    /** To be done */
    e.preventDefault();
    dispatch(forgotPasswordActions.forgotPasswordSubmit(
      stepsData.username,
      formData.confirmationCode,
      formData.newPassword,
    ))
      .then((response) => {
        if (response && !response.error) {
          /** Fogot password submit call was successful, can go to next step of the flow */
          onSuccess();
        }
      });
  };

  const onResend = useCallback(() => {
    dispatch(forgotPasswordActions.forgotPassword(stepsData.username, false, true));
  }, [dispatch, stepsData.username]);

  return (
    <>
      <Typography variant="h6" component={KnBoldSectionHeader} marginBottom>
        {translate('FORGOT_PASSWORD.changePasswordTitle')}
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography paragraph>{translate('FORGOT_PASSWORD.changePasswordHint')}</Typography>
        <KnValidatedTextField
          Component={KnTextField}
          name="confirmationCode"
          control={control}
          errors={errors}
          defaultValue=""
          required
          trimSpaces
          maxLength={256}
        />
        <KnValidatedTextField
          Component={KnTextField}
          name="newPassword"
          control={control}
          errors={errors}
          type="password"
          required
          format={PASSWORD_FORMAT}
          notEqualTo={stepsData.username}
          maxLength={256}
          onBlur={onPasswordBlur}
        />
        <KnValidatedTextField
          Component={KnTextField}
          name="confirmPassword"
          control={control}
          errors={errors}
          type="password"
          required
          matchField="newPassword"
          maxLength={256}
        />
        <Typography paragraph>{translate('REGISTER.passwordHint')}</Typography>
        <Box
          pt={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <KnButton
            type="button"
            variant="text"
            route={APP_PAGE_URLS.forgotPassword}
            data-testid="resend-code-button"
            disabled={submitDisabled}
            onClick={onResend}
          >
            {translate('REGISTER.resendCodeButton')}
          </KnButton>
          <KnButton
            data-testid="change-password-button"
            disabled={submitDisabled}
          >
            {translate('FORGOT_PASSWORD.changePasswordButton')}
          </KnButton>
        </Box>
      </form>
    </>
  );
};

ChangePasswordStep.propTypes = {
  submitDisabled: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  stepsData: PropTypes.shape().isRequired,
};

export default ChangePasswordStep;
