/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import KnButton from 'components/Button';

const KnDialog = ({
  open,
  title,
  description,
  onSubmit,
  onClose,
  submitLabel,
  closeLabel,
}) => {
  if (open) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        data-testid="dialog-wrapper"
      >
        <DialogTitle data-testid="dialog-title">{title}</DialogTitle>
        <DialogContent data-testid="dialog-content">
          <DialogContentText data-testid="dialog-content-text">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions data-testid="dialog-actions">
          <KnButton variant="text" onClick={onClose} data-testid="dialog-cancel-button">
            {closeLabel}
          </KnButton>
          <KnButton onClick={onSubmit} data-testid="dialog-submit-button">
            {submitLabel}
          </KnButton>
        </DialogActions>
      </Dialog>
    );
  }

  return null;
};

KnDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  submitLabel: PropTypes.string,
  closeLabel: PropTypes.string,
};

KnDialog.defaultProps = {
  title: '',
  description: '',
  submitLabel: '',
  closeLabel: '',
};

export default KnDialog;
