/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { fade } from '@material-ui/core/styles/colorManipulator';
import palette from '../colors';

const backdropBackgroundColor = fade(palette.black.black1, 0.5);

export default {
  MuiDialog: {
    paper: {
      width: '100%',
    },
    paperWidthSm: {
      maxWidth: 474,
    },
  },
  MuiDialogTitle: {
    root: {
      textTransform: 'uppercase',
      padding: 32,
    },
  },
  MuiDialogContent: {
    root: {
      paddingLeft: 32,
      paddingRight: 32,
      minHeight: 120,
    },
  },
  MuiDialogActions: {
    root: {
      justifyContent: 'space-between',
      padding: 32,
    },
  },
  MuiBackdrop: {
    root: {
      backgroundColor: backdropBackgroundColor,
    },
  },
};
