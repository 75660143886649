/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Box from '@material-ui/core/Box';
import KnArrowDownIcon from 'components/icons/ArrowDownIcon';
import KnButton from 'components/Button';
import { getStatusColor } from 'features/patient/RegimenStatus';
import { TITRATIONS_CONTEXT } from 'Constants';
import KnTitrationInfo from './item-components/TitrationItemInfo';
import KnMedicationInfo from './item-components/TitrationItemMedication';
import {
  KnTitrationExpansionPanelSummary,
  KnTitrationExpansionPanelDetails,
} from './styles';


/**
 * Generic component, used to show one titration item
 * Uses KnTitrationInfo and KnMedicationInfo components to show the titration
 */
const KnTitrationItem = (props) => {
  const {
    titrationData,
    context,
    actions,
    alwaysExpanded,
  } = props;
  const { t: translate } = useTranslation();

  /** This will add the 'expanded' prop to the ExpansionPanel,
   * if alwaysExpanded is set to `true`
   */
  const expandedProp = { expanded: alwaysExpanded || undefined };

  return (
    <Box mb={1.5}>
      <ExpansionPanel square elevation={10} {...expandedProp} data-testid="titration-item">
        <KnTitrationExpansionPanelSummary expandIcon={!alwaysExpanded && <KnArrowDownIcon />}>
          <KnTitrationInfo
            context={context}
            titration={titrationData}
            actions={actions}
          />
        </KnTitrationExpansionPanelSummary>
        <KnTitrationExpansionPanelDetails color={getStatusColor(titrationData.status)}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              {titrationData.medications.map((presetMedication) => (
                <KnMedicationInfo
                  key={presetMedication.index}
                  presetMedication={presetMedication}
                />
              ))}
            </Box>
            {context === TITRATIONS_CONTEXT.assignPreset
              && (
              <Box alignSelf="flex-end" paddingBottom={2.5}>
                <KnButton
                  data-testid="assign-preset-button"
                  type="button"
                  onClick={() => actions.onAssignPreset(titrationData)}
                >
                  {translate('ASSIGN_REGIMEN.assignPresetButton')}
                </KnButton>
              </Box>
              )}
          </Box>
        </KnTitrationExpansionPanelDetails>
      </ExpansionPanel>
    </Box>
  );
};

KnTitrationItem.propTypes = {
  titrationData: PropTypes.shape().isRequired,
  context: PropTypes.oneOf(
    [TITRATIONS_CONTEXT.assignPreset, TITRATIONS_CONTEXT.titrationsManagement,
      TITRATIONS_CONTEXT.assignedRegimen, TITRATIONS_CONTEXT.pastRegimen],
  ).isRequired,
  actions: PropTypes.shape(),
  alwaysExpanded: PropTypes.bool,
};

KnTitrationItem.defaultProps = {
  actions: {},
  alwaysExpanded: false,
};

export default KnTitrationItem;
