/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import ACTION_TYPES from 'redux/actionTypes';
import { PATIENT_LINKS_STATUS } from 'Constants';

const DEFAULT_SORT_KEY_VERIFIED = 'lastName';
const DEFAULT_SORT_ORDER_VERIFIED = 'asc';
const DEFAULT_SORT_KEY_UNVERIFIED = 'dateOfRequest';
const DEFAULT_SORT_ORDER_UNVERIFIED = 'desc';
const DEFAULT_SORT_KEY_UNLINKED = 'dateOfDeactivation';
const DEFAULT_SORT_ORDER_UNLINKED = 'desc';

const initialState = {
  unverified: null,
  verified: null,
  unlinked: null,
  error: false,
  /** Sorting applicable only for verified list */
  sortBy: DEFAULT_SORT_KEY_VERIFIED,
  sortDirection: DEFAULT_SORT_ORDER_VERIFIED,
  /** Filtering applied for both lists, done by BE
   * when filtered, this field holds the user id of the physician
   * or 0 for All
   */
  physicianFilter: undefined,
};

const sortPatients = (data, sortKey, sortDirection) => _.orderBy(
  data,
  [(patient) => {
    if (typeof patient[sortKey] === 'string') {
      return patient[sortKey].toLowerCase();
    }
    return patient[sortKey];
  }],
  [sortDirection],
);

const removePatientWithLinkId = (data, linkId) => (
  _.filter(data, (patient) => (patient.linkId !== linkId))
);

const patientsListReducer = (state = initialState, action) => {
  const { data, type } = action;

  switch (type) {
    case ACTION_TYPES.PATIENT_LIST_REQUEST:
      return {
        ...state,
        error: false,
      };

    case ACTION_TYPES.PATIENT_LIST_SUCCESS:
      switch (action.status) {
        case PATIENT_LINKS_STATUS.accepted:
          return {
            ...state,
            verified: sortPatients(data, state.sortBy, state.sortDirection),
          };

        case PATIENT_LINKS_STATUS.pending:
          return {
            ...state,
            unverified: sortPatients(
              data, DEFAULT_SORT_KEY_UNVERIFIED, DEFAULT_SORT_ORDER_UNVERIFIED,
            ),
          };

        case PATIENT_LINKS_STATUS.unlinked:
          return {
            ...state,
            unlinked: sortPatients(
              data, DEFAULT_SORT_KEY_UNLINKED, DEFAULT_SORT_ORDER_UNLINKED,
            ),
          };

        default:
          return state;
      }

    case ACTION_TYPES.PATIENT_LIST_ERROR:
      return (
        action.unverified
          ? {
            ...state,
            unverified: null,
            error: true,
          }
          : {
            ...state,
            verified: null,
            error: true,
          }
      );

    case ACTION_TYPES.PATIENT_ACCEPTED: {
      const acceptedPatient = _.find(
        state.unverified,
        (patient) => (patient.linkId === action.linkId),
      );

      return {
        ...state,
        unverified: removePatientWithLinkId(state.unverified, action.linkId),
        verified: sortPatients(
          [...state.verified, acceptedPatient],
          state.sortBy,
          state.sortDirection,
        ),
      };
    }

    case ACTION_TYPES.PATIENT_REJECTED:
      return {
        ...state,
        unverified: removePatientWithLinkId(state.unverified, action.linkId),
      };

    case ACTION_TYPES.PATIENT_LIST_SORT: {
      const { sortKey: newSortKey } = action;
      const isAsc = state.sortDirection === 'asc';
      /** Start with default order on sorting by new key
        * OR switch order if key didn't change */
      let newSortDirection = DEFAULT_SORT_ORDER_VERIFIED;
      if (newSortKey === state.sortBy) {
        newSortDirection = isAsc ? 'desc' : 'asc';
      }
      return {
        ...state,
        sortBy: newSortKey,
        sortDirection: newSortDirection,
        /** Apply sort to verified patients */
        verified: sortPatients(state.verified, newSortKey, newSortDirection),
      };
    }

    case ACTION_TYPES.PATIENT_LIST_PHYSICIAN_FILTER: {
      return {
        ...state,
        physicianFilter: action.physicianId,
      };
    }

    case ACTION_TYPES.PATIENT_UNLINK_SUCCESS: {
      return {
        ...state,
        verified: removePatientWithLinkId(state.verified, action.linkId),
      };
    }

    default:
      return state;
  }
};

export { sortPatients };

export default patientsListReducer;
