/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { appInsights } from 'appInsights';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import {
  APP_PAGE_URLS,
  PATIENTS_LIST_TABS,
  PATIENT_LINKS_STATUS,
  TRACKING_EVENTS,
  HCP_USER_ROLES,
} from 'Constants';
import Typography from '@material-ui/core/Typography';
import { KnBoldSectionHeader, KnColoredText } from 'components/Typography';
import KnErrorMessage from 'components/ErrorMessage';
import KnTable from 'components/Table';
import patientActions from 'redux/actions/patientActions';
import { filterPatientsByName } from 'utils/patientsFilters';
import ACTION_TYPES from 'redux/actionTypes';
import palette from 'styles/colors';
import KnPatientsVerification from './PatientsVerification';
import KnPatientsTableToolbar from './PatientsTableToolbar';

const DEFAULT_TAB = PATIENTS_LIST_TABS.VERIFIED_PATIENTS;
const thresholdMetColor = palette.copper;

const mapTabToPatientLinkStatus = (tab) => {
  if (tab === PATIENTS_LIST_TABS.UNVERIFIED_PATIENTS) {
    return PATIENT_LINKS_STATUS.pending;
  }
  if (tab === PATIENTS_LIST_TABS.VERIFIED_PATIENTS) {
    return PATIENT_LINKS_STATUS.accepted;
  }
  return PATIENT_LINKS_STATUS.unlinked;
};

const KnPatientLists = ({ initialTab }) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    error,
    unverified,
    verified,
    unlinked,
    sortBy,
    sortDirection,
    physicianFilter,
  } = useSelector((state) => state.patientsList);
  const {
    currentUser,
    physiciansList,
  } = useSelector((state) => state.user);
  const [selectedTab, setSelectedTab] = useState(initialTab || DEFAULT_TAB);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    appInsights.trackEvent({ name: TRACKING_EVENTS.viewPatientList });
  }, []);

  useEffect(() => {
    if (physicianFilter === undefined
      && physiciansList.initialized) {
      /** Ready to set default (initial) filter for the patients list
       * and make the data fetch
      */
      const defaultFilter = (_.get(currentUser, 'role.id') === HCP_USER_ROLES.physician
        && physiciansList.data.length > 0) ? currentUser.userId : 0;
      dispatch(patientActions.filterPatientsByPhysician(
        defaultFilter,
        mapTabToPatientLinkStatus(selectedTab),
      ));
    }
  }, [dispatch, currentUser, physiciansList, physicianFilter, selectedTab]);

  const onTableRowClick = useCallback((row) => {
    if (selectedTab === PATIENTS_LIST_TABS.VERIFIED_PATIENTS) {
      /** First clear previous patient data, to avoid old data flash */
      dispatch({ type: ACTION_TYPES.PATIENT_CLEAR_RECORD });
      /** Set available new patient data, rest will be fetched by the PatientRecord page */
      dispatch(patientActions.setPatientInfo(row));
      history.push(
        APP_PAGE_URLS.patientRecord.replace(':patientId', row.patientId),
      );
    }
  }, [dispatch, history, selectedTab]);

  const verifiedPatientsTableColumns = useMemo(() => {
    const thresholdMetDisplay = (rowData) => {
      if (rowData.thresholdMet) {
        return (
          <KnColoredText color={thresholdMetColor}>
            {translate('GENERAL.dateUTC', { date: rowData.thresholdMet })}
          </KnColoredText>
        );
      }
      return '-';
    };

    return ([
      {
        name: translate('HOME.tableHeaders.lastName'),
        valueAccessor: 'lastName',
        sortKey: 'lastName',
      },
      {
        name: translate('HOME.tableHeaders.firstName'),
        valueAccessor: 'firstName',
        sortKey: 'firstName',
      },
      {
        name: translate('GENERAL.dob'),
        valueAccessor: (rowData) => translate('GENERAL.date', { date: rowData.dob }),
      },
      {
        name: translate('GENERAL.gender.label'),
        valueAccessor: (rowData) => rowData.gender && translate(`GENERAL.gender.${rowData.gender}`),
      },
      {
        name: translate('HOME.tableHeaders.lastSeizure'),
        valueAccessor: (rowData) => translate('GENERAL.date', { date: rowData.lastSeizure }),
        sortKey: 'lastSeizure',
      },
      {
        name: translate('HOME.tableHeaders.threshold'),
        valueAccessor: thresholdMetDisplay,
        sortKey: 'thresholdMet',
      },
      {
        name: translate('HOME.tableHeaders.accountNumber'),
        valueAccessor: 'accountNumber',
      },
    ]);
  }, [translate]);

  const unverifiedPatientsTableColumns = useMemo(() => ([
    {
      name: '',
      component: KnPatientsVerification,
    },
    {
      name: translate('HOME.tableHeaders.lastName'),
      valueAccessor: 'lastName',
    },
    {
      name: translate('HOME.tableHeaders.firstName'),
      valueAccessor: 'firstName',
    },
    {
      name: translate('HOME.tableHeaders.emailAddress'),
      valueAccessor: 'email',
    },
    {
      name: translate('GENERAL.dob'),
      valueAccessor: (rowData) => translate('GENERAL.date', { date: rowData.dob }),
    },
    {
      name: translate('HOME.tableHeaders.dateOfRequest'),
      valueAccessor: (rowData) => translate('GENERAL.date', { date: rowData.dateOfRequest }),
    },
    {
      name: translate('HOME.tableHeaders.accountNumber'),
      valueAccessor: 'accountNumber',
    },
  ]), [translate]);

  const unlinkedPatientsTableColumns = useMemo(() => ([
    {
      name: translate('HOME.tableHeaders.lastName'),
      valueAccessor: 'lastName',
    },
    {
      name: translate('HOME.tableHeaders.firstName'),
      valueAccessor: 'firstName',
    },
    {
      name: translate('GENERAL.dob'),
      valueAccessor: (rowData) => translate('GENERAL.date', { date: rowData.dob }),
    },
    {
      name: translate('GENERAL.gender.label'),
      valueAccessor: (rowData) => rowData.gender && translate(`GENERAL.gender.${rowData.gender}`),
    },
    {
      name: translate('HOME.tableHeaders.emailAddress'),
      valueAccessor: 'email',
    },
    {
      name: translate('HOME.tableHeaders.dateOfDeactivation'),
      valueAccessor: (rowData) => translate('GENERAL.date', { date: rowData.dateOfDeactivation }),
    },
    {
      name: translate('HOME.tableHeaders.accountNumber'),
      valueAccessor: 'accountNumber',
    },
  ]), [translate]);

  const fetchPatientsList = useCallback((tab, silent = true) => {
    dispatch(patientActions.fetchPatients(mapTabToPatientLinkStatus(tab), silent));
  }, [dispatch]);

  const onTabChange = useCallback((...args) => {
    /** First parameter is event; we only need the second parameter (tabIndex). */
    const [, tabIndex] = args;
    setSelectedTab(tabIndex);

    /** Clear the search input. */
    setSearchTerm('');

    /** Fetch the appropriate patient links. */
    fetchPatientsList(tabIndex);
  }, [fetchPatientsList]);

  const onSearchTermChange = useCallback(({ target: { value } }) => {
    setSearchTerm(value);
  }, []);

  const onPhysicianFilterChange = useCallback((event) => {
    const { target: { value } } = event;
    /** `value` for 'All' option is undefined, exactly what we want  */
    dispatch(patientActions.filterPatientsByPhysician(
      value,
      mapTabToPatientLinkStatus(selectedTab),
    ));
  }, [dispatch, selectedTab]);

  const hasSearchTerm = searchTerm.trim();
  const isVerifiedPatientsTab = (selectedTab === PATIENTS_LIST_TABS.VERIFIED_PATIENTS);
  const isUnlinkedPatientsTab = (selectedTab === PATIENTS_LIST_TABS.UNLINKED_PATIENTS);

  const tableData = useMemo(() => {
    /** If there is a searching value, then we prioritize the filtered collection. */
    let dataSource = [];
    switch (selectedTab) {
      case PATIENTS_LIST_TABS.VERIFIED_PATIENTS:
        dataSource = verified;
        break;
      case PATIENTS_LIST_TABS.UNVERIFIED_PATIENTS:
        dataSource = unverified;
        break;
      case PATIENTS_LIST_TABS.UNLINKED_PATIENTS:
        dataSource = unlinked;
        break;
      default:
        dataSource = [];
    }
    if (hasSearchTerm && selectedTab !== PATIENTS_LIST_TABS.UNLINKED_PATIENTS) {
      return filterPatientsByName(
        dataSource,
        searchTerm,
      );
    }
    return dataSource;
  }, [hasSearchTerm, searchTerm, selectedTab, unverified, verified, unlinked]);

  const tableColumns = useMemo(() => {
    if (selectedTab === PATIENTS_LIST_TABS.UNVERIFIED_PATIENTS) {
      return unverifiedPatientsTableColumns;
    }
    if (selectedTab === PATIENTS_LIST_TABS.VERIFIED_PATIENTS) {
      return verifiedPatientsTableColumns;
    }
    return unlinkedPatientsTableColumns;
  }, [
    selectedTab,
    unverifiedPatientsTableColumns,
    verifiedPatientsTableColumns,
    unlinkedPatientsTableColumns,
  ]);

  const tableHintMessage = useMemo(() => {
    if (selectedTab === PATIENTS_LIST_TABS.UNVERIFIED_PATIENTS) return translate('HOME.patientTables.unverifiedHint');
    if (selectedTab === PATIENTS_LIST_TABS.VERIFIED_PATIENTS) return translate('HOME.patientTables.verifiedHint');
    return translate('HOME.patientTables.unlinkedHint');
  }, [selectedTab, translate]);

  const tableEmptyDataMessage = useMemo(() => {
    if (hasSearchTerm && tableData && !tableData.length) {
      return translate('HOME.patientTables.emptySearchResults');
    }
    if (!!physicianFilter && tableData && !tableData.length) {
      return translate('HOME.patientTables.emptyFilteredList');
    }
    if (!error && isVerifiedPatientsTab && verified && !verified.length) {
      return translate('HOME.patientTables.emptyVerifiedList');
    }
    if (!error && unverified && !unverified.length) {
      return translate('HOME.patientTables.emptyUnverifiedList');
    }
    if (!error && isUnlinkedPatientsTab && unlinked && !unlinked.length) {
      return translate('HOME.patientTables.emptyUnlinkedList');
    }
    return '';
  }, [
    error, isVerifiedPatientsTab, verified, unverified,
    hasSearchTerm, tableData, translate, physicianFilter,
    isUnlinkedPatientsTab, unlinked,
  ]);

  const redoFetchPatientsList = useCallback(() => {
    /** On error refetch the patients list and show the progress indicator. */
    fetchPatientsList(selectedTab, false);
  }, [fetchPatientsList, selectedTab]);

  const TableDataErrorComponent = useMemo(() => (
    <KnErrorMessage
      error={error}
      messageKey="HOME.ERROR_MESSAGES.patientsListError"
      onRetry={redoFetchPatientsList}
    />
  ), [error, redoFetchPatientsList]);

  const onRequestSort = useCallback((sortKey) => {
    dispatch(patientActions.sortPatients(sortKey));
  }, [dispatch]);

  return (

    <>
      <Typography variant="h4" component={KnBoldSectionHeader}>
        {translate('HOME.mainTitle')}
      </Typography>

      <Box pt={1} pb={2}>
        <Typography variant="body2">{tableHintMessage}</Typography>
      </Box>

      <KnPatientsTableToolbar
        searchTerm={searchTerm}
        onSearchTermChange={onSearchTermChange}
        physicianFilter={physicianFilter}
        onPhysicianFilterChange={onPhysicianFilterChange}
        disabled={isUnlinkedPatientsTab}
      />

      <Tabs value={selectedTab} onChange={onTabChange} textColor="primary" indicatorColor="primary">
        <Tab label={translate('HOME.tabs.tabActive')} data-testid="active-patients-tab" />
        <Tab label={translate('HOME.tabs.tabUnverified')} data-testid="unverified-patients-tab" />
        <Tab label={translate('HOME.tabs.tabUnlinked')} data-testid="unlinked-patients-tab" />
      </Tabs>

      <KnTable
        rowKeyAccessor="linkId"
        columns={tableColumns}
        data={tableData}
        emptyDataMessage={tableEmptyDataMessage}
        DataErrorComponent={TableDataErrorComponent}
        onRequestSort={onRequestSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onRowClick={isVerifiedPatientsTab ? onTableRowClick : null}
      />
    </>
  );
};

KnPatientLists.propTypes = {
  initialTab: PropTypes.number,
};

KnPatientLists.defaultProps = {
  initialTab: undefined,
};

export default KnPatientLists;
