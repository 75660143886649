/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import ACTION_TYPES from 'redux/actionTypes';

const initialState = {
  busy: false,
  /** Error during the login flow */
  error: null,
};

const loginReducer = (state = initialState, action) => {
  const { type, error } = action;

  switch (type) {
    case ACTION_TYPES.LOGIN_REQUEST:
      return {
        ...state,
        busy: true,
        error: null,
      };
    case ACTION_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        busy: false,
      };
    case ACTION_TYPES.LOGIN_ERROR:
      return {
        ...state,
        busy: false,
        error,
      };
    case ACTION_TYPES.LOGIN_CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export { initialState };

export default loginReducer;
