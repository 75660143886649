/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import ACTION_TYPES from 'redux/actionTypes';

const initialState = {
  currentUser: {
    initialized: false,
  },
  physiciansList: {
    data: [],
    initialized: false,
  },
};

const userReducer = (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case ACTION_TYPES.CURRENT_USER_SUCCESS:
    case ACTION_TYPES.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...data,
          initialized: true,
        },
      };

    case ACTION_TYPES.PHYSICIANS_LIST_SUCCESS:
    case ACTION_TYPES.PHYSICIANS_LIST_ERROR:
      return {
        ...state,
        physiciansList: {
          data,
          initialized: true,
        },
      };

    case ACTION_TYPES.CURRENT_USER_ERROR:
      return {
        ...state,
        currentUser: {
          initialized: true,
        },
      };

    default:
      return state;
  }
};

export default userReducer;
