/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const KnLogoIcon = () => (
  <SvgIcon
    width="43"
    height="80"
    viewBox="0 0 43 80"
    fill="none"
  >
    <path d="M42.8555 40C42.8555 51.0457 33.9012 60 22.8555 60C11.8098 60 2.85547 51.0457 2.85547 40C2.85547 28.9543 11.8098 20 22.8555 20C33.9012 20 42.8555 28.9543 42.8555 40Z" fill="#FFC234" />
    <path d="M8.57143 15.7139C8.57143 18.0809 6.65265 19.9996 4.28571 19.9996C1.91878 19.9996 0 18.0809 0 15.7139C0 13.347 1.91878 11.4282 4.28571 11.4282C6.65265 11.4282 8.57143 13.347 8.57143 15.7139Z" fill="#292E47" />
    <path d="M17.1412 72.8572C17.1412 76.8021 13.9432 80.0001 9.99832 80.0001C6.05343 80.0001 2.85547 76.8021 2.85547 72.8572C2.85547 68.9123 6.05343 65.7144 9.99832 65.7144C13.9432 65.7144 17.1412 68.9123 17.1412 72.8572Z" fill="#292E47" />
    <path d="M40.0006 7.14285C40.0006 11.0877 36.8026 14.2857 32.8577 14.2857C28.9128 14.2857 25.7148 11.0877 25.7148 7.14285C25.7148 3.19796 28.9128 0 32.8577 0C36.8026 0 40.0006 3.19796 40.0006 7.14285Z" fill="#292E47" />
  </SvgIcon>
);

export default KnLogoIcon;
