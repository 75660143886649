/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import KnIconWrapper from './styles';

const KnPlusIcon = (props) => {
  const {
    color,
    ...rest
  } = props;

  return (
    <KnIconWrapper fontSize={14}>
      <SvgIcon
        fontSize="inherit"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        {...rest}
      >
        <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill={color} />
      </SvgIcon>
    </KnIconWrapper>
  );
};

KnPlusIcon.propTypes = {
  color: PropTypes.string,
};

KnPlusIcon.defaultProps = {
  color: '#666',
};

export default KnPlusIcon;
