/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import refDataService, { REFERENCE_DATA_TYPES } from 'services/referenceDataService';
import { v4 as uuidv4 } from 'uuid';
import { serverDateToGMT } from 'utils/utils';

const mapLocalMedicationsToServer = (meds) => meds.map((med, medIndex) => ({
  index: medIndex + 1,
  medication: {
    id: med.id,
  },
  unit: med.unit,
  dosages: med.dosages.map((dosage, dosageIndex) => ({
    index: dosageIndex + 1,
    isCustom: dosage.hasCustomDosage,
    quantity: parseFloat(dosage.quantity),
    durationInDays: dosage.duration,
    frequencyPerDay: dosage.frequency,
    timePeriodDosages: dosage.times.map((time, index) => ({
      quantity: parseFloat(dosage.individualQuantities[index]),
      timePeriod: {
        id: _.get(time, 'timePeriod.id'),
      },
    })),
  })),
}));

const mapLocalTitrationToServer = (data) => ({
  name: data.name && data.name.trim(),
  presetMedications: mapLocalMedicationsToServer(data.medications),
});

const mapServerMedicationsToLocal = (meds) => meds.map((presetMedication) => ({
  index: presetMedication.index,
  ...refDataService.getValue(
    REFERENCE_DATA_TYPES.medications,
    presetMedication.medication.id,
  ),
  unit: presetMedication.unit,
  dosages: _.sortBy(presetMedication.dosages, ['index'])
    .map((presetDosage) => ({
      index: presetDosage.index,
      keyId: uuidv4(), /** To be used in the UI as stable component key */
      quantity: presetDosage.quantity,
      duration: presetDosage.durationInDays,
      frequency: presetDosage.frequencyPerDay,
      hasCustomDosage: presetDosage.isCustom,
      times: presetDosage.timePeriodDosages.map((timePeriodDosage, index) => ({
        index, /** To be used as component key, as id might repeat */
        timePeriod: {
          id: timePeriodDosage.timePeriod.id,
          value: refDataService.getValue(
            REFERENCE_DATA_TYPES.dosageTimePeriod,
            timePeriodDosage.timePeriod.id,
          ),
        },
      })),
      individualQuantities: presetDosage.timePeriodDosages.map(
        (timePeriodDosage) => timePeriodDosage.quantity,
      ),
    })),
}));

/** Mapper for both titration preset, and medication regimen data received from the server */
const mapServerTitrationToLocal = (titration) => {
  const regimen = {
    /** Common fields */
    id: titration.id,
    medications: mapServerMedicationsToLocal(_.sortBy(_.get(titration, 'presetMedications', titration.medications), ['index'])),
    /** Fields in presets */
    name: titration.name,
    lastUpdated: new Date(titration.lastUpdated),
    /** Fields in assigned regimens */
    assignedAt: new Date(titration.assignedAt),
    startedAt: titration.patientStartedTitrationOn
      && serverDateToGMT(titration.patientStartedTitrationOn),
    completedAt: titration.titrationEndDate && serverDateToGMT(titration.titrationEndDate),
    goalDoseReached: !!titration.titrationGoalDoseReached,
    status: titration.status,
  };

  /** Consider only the date part from the deactivatedAt timestamp field received from the server
   * Note: once the BE will send YYYY-MM-DD only, just map
   *  as `deactivatedAt: titration.deactivatedAt && serverDateToGMT(titration.deactivatedAt)`
  */
  if (titration.deactivatedAt) {
    const dateOnly = titration.deactivatedAt.length > 10
      ? titration.deactivatedAt.substring(0, 10) : titration.deactivatedAt;
    regimen.deactivatedAt = serverDateToGMT(dateOnly);
  }

  return regimen;
};

export default {
  mapLocalTitrationToServer,
  mapLocalMedicationsToServer,
  mapServerTitrationToLocal,
  mapServerMedicationsToLocal,
};
