/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import patientActions from 'redux/actions/patientActions';
import { TOPLINE_METRICS_PERIOD, METRICS_TYPE } from 'Constants';
import Typography from '@material-ui/core/Typography';
import { getMetricsTimeRange } from 'utils/metrics';
import { KnSectionHeader, KnCardsSubtitle } from 'components/Typography';
import KnErrorMessage from 'components/ErrorMessage';
import { withKeyNamespace } from 'utils/utils';
import KnMetricsCard from './MetricsCard';
import KnPatientAchievementsReports from './PatientAchievementsReports';
import { KnPatientReportsBox } from './styles';

const i18nKey = withKeyNamespace('PATIENT_RECORD');

/**
 * Component for displaying patient top line metrics
 * @param {string} patientId Id of the patient
 */
const KnPatientReports = ({ patientId }) => {
  const {
    seizuresMetrics,
    symptomsMetrics,
    patientInfo,
    surveyResults,
    thresholdEvents,
  } = useSelector((state) => state.patientRecord);
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const [metricsTimeRange, setMetricsTimeRange] = useState();

  useEffect(() => {
    /** We need patient timezone loaded, to decide the time range for the symptoms metrics */
    if (_.get(patientInfo, 'data.timeZone')) {
      /** Note: Currently, patient timezone is not considered for seizures metrics.
       * I expect this to change in the future.
       */
      const { startDate, endDate } = getMetricsTimeRange({
        timezone: _.get(patientInfo, 'data.timeZone'),
        offset: TOPLINE_METRICS_PERIOD.days,
      });
      setMetricsTimeRange({ startDate, endDate });
      dispatch(patientActions.fetchTopLineSeizureData(
        patientId,
        startDate,
        endDate,
      ));

      dispatch(patientActions.fetchTopLineSymptomData(
        patientId,
        startDate,
        endDate,
      ));
    }
  }, [dispatch, patientId, patientInfo]);

  useEffect(() => {
    dispatch(patientActions.fetchLatestSurveyResults(patientId));
    dispatch(patientActions.fetchLatestThresholdEvents(patientId));
  }, [dispatch, patientId]);

  /** Retry handlers for error cases */
  const redoFetchSeizures = useCallback(() => {
    dispatch(patientActions.fetchTopLineSeizureData(
      patientId,
      metricsTimeRange.startDate,
      metricsTimeRange.endDate,
    ));
  }, [dispatch, patientId, metricsTimeRange]);

  const redoFetchSymptoms = useCallback(() => {
    dispatch(patientActions.fetchTopLineSymptomData(
      patientId,
      metricsTimeRange.startDate,
      metricsTimeRange.endDate,
    ));
  }, [dispatch, patientId, metricsTimeRange]);

  const redoFetchSurveyResults = useCallback(() => {
    dispatch(patientActions.fetchLatestSurveyResults(patientId));
  }, [dispatch, patientId]);

  const redoFetchThresholdEvents = useCallback(() => {
    dispatch(patientActions.fetchLatestThresholdEvents(patientId));
  }, [dispatch, patientId]);

  return (
    <KnPatientReportsBox mr={2} mb={2}>
      <Box mb={3}>
        <Typography variant="h6" component={KnSectionHeader}>
          {translate(i18nKey('patientReports.title'))}
        </Typography>
        <Typography component={KnCardsSubtitle}>
          {translate(i18nKey('patientReports.subtitle'), { count: TOPLINE_METRICS_PERIOD.days })}
        </Typography>
      </Box>

      {seizuresMetrics.data && metricsTimeRange
      && (
        <KnMetricsCard
          data={seizuresMetrics.data}
          type={METRICS_TYPE.seizure}
          timeRange={metricsTimeRange}
        />
      )}

      <KnErrorMessage
        error={seizuresMetrics.error}
        messageKey={i18nKey('ERROR_MESSAGES.seizuresFetchError')}
        onRetry={redoFetchSeizures}
        centered={false}
        mb={2}
      />

      {symptomsMetrics.data && metricsTimeRange
      && (
        <KnMetricsCard
          data={symptomsMetrics.data}
          type={METRICS_TYPE.symptom}
          timeRange={metricsTimeRange}
        />
      )}

      <KnErrorMessage
        error={symptomsMetrics.error}
        messageKey={i18nKey('ERROR_MESSAGES.symptomsFetchError')}
        onRetry={redoFetchSymptoms}
        centered={false}
        mb={2}
      />

      <KnPatientAchievementsReports
        surveys={surveyResults.data}
        events={thresholdEvents.data}
      />

      <KnErrorMessage
        error={surveyResults.error}
        messageKey={i18nKey('ERROR_MESSAGES.surveyResultsFetchError')}
        onRetry={redoFetchSurveyResults}
        centered={false}
        mb={2}
        data-testid="survey-results-fetch-error"
      />

      <KnErrorMessage
        error={thresholdEvents.error}
        messageKey={i18nKey('ERROR_MESSAGES.eventsFetchError')}
        onRetry={redoFetchThresholdEvents}
        centered={false}
        mb={2}
        data-testid="threshold-met-fetch-error"
      />

    </KnPatientReportsBox>
  );
};

KnPatientReports.propTypes = {
  patientId: PropTypes.string.isRequired,
};

export default KnPatientReports;
