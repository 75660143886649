/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

export default {
  MuiLink: {
    button: {
      marginLeft: 5,

      '&:disabled': {
        opacity: 0.3,
      },
    },
  },
};
