/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { styled } from '@material-ui/core/styles';
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  ready, create, color, Container, percent, useTheme,
} from '@amcharts/amcharts4/core';
import {
  XYChart, XYCursor, ColumnSeries, DateAxis, ValueAxis,
} from '@amcharts/amcharts4/charts';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';
import { translate } from 'i18n/i18n';
import palette from 'styles/colors';

const labelsColor = color(palette.brownishGrey);
const labelsFontSize = 11;
const dateAxisLabelFormat = 'MMM';
const columnsColor = color(palette.linkWater);
const lastColumnColor = color(palette.slateGrey);
const lastColumnLabelColor = color(palette.brightPurple);
const lastColumnLabelSuffix = '\u2022';
const tooltipBackgroundColor = color(palette.midnight);
const tooltipLabelColor = color(palette.white.white2);
const columnsContainerOutlineColor = palette.coolGrey.coolGrey2;

/**
 * Adapter function which returns either the default color for an amcharts object
 * OR a contrast color if the datetime value the object references meets the conditions
 * defined here.
 *
 * @param {string} defaultColor The default color set for fill or stroke of the object.
 * @param {string} contrastColor The contrast color.
 * @param {object} target The amcharts object the adapter is defined for (label, column, etc).
 * @param {string} dateSelector The date property the object is referencing.
 */
const getColorByDate = (defaultColor, contrastColor, target, dateSelector) => {
  const currentMonth = new Date().getMonth();
  if (target.dataItem) {
    const targetDate = target.dataItem.dates[dateSelector];
    if (targetDate && (targetDate.getMonth() === currentMonth)) {
      return contrastColor;
    }
  }
  return defaultColor;
};

/**
 * Adapter function to return a different label if the datetime conditions are met.
 *
 * @param {string} text The default label text.
 * @param {string} target The amcharts object the adapter is defined for.
 */
const getLabelTextByDate = (text, target) => {
  const currentMonth = new Date().getMonth();
  if (target.dataItem) {
    const targetDate = target.dataItem.dates.date;
    if (targetDate && (targetDate.getMonth() === currentMonth)) {
      return `${lastColumnLabelSuffix} ${text}`;
    }
  }
  return text;
};

/**
 * Amcharts dimensions are relative to the chart container.
 * Define actual sizes here.
 */
const KnChartContainer = styled('div')({
  height: 80,
  width: 306,

  /**
   * Needed as the pixels are antialised by default and because of
   * this the border around the chart it's rendered blurry.
   * This does pixel rounding and produces sharp edges. We revert the
   * shapeRendering property on columns and tooltip to default value auto,
   * so we should have no visual defects.
   */
  '& g > g:last-child': {
    shapeRendering: 'crispEdges',
  },
  /**
   * The designs require a border around the columns only. Without a way to configure
   * this, the border is added through CSS.
   * This targets the svg rect child where the columns are displayed and adds it.
   * Note: we set the stroke width to 1px and the stroke opacity because of crispEdges property.
   */
  '& g > g:last-child rect': {
    strokeWidth: 1,
    stroke: columnsContainerOutlineColor,
    strokeOpacity: 0.3,
  },
});

const createChartContainer = (chartId) => {
  const container = create(chartId, Container);
  container.layout = 'grid';
  container.fixedWidthGrid = false;
  container.width = percent(100);
  container.height = 80; /** TODO revise after license purchase */
  container.fontFamily = 'DIN Next LT W01 Regular';
  return container;
};

const createChart = (container, data) => {
  const chart = container.createChild(XYChart);
  chart.width = percent(100);
  chart.height = percent(100);
  chart.paddingTop = 4;
  chart.paddingLeft = 1;
  chart.paddingRight = 1;
  chart.cursor = new XYCursor();

  chart.data = data;

  /** On horizontal axis we show the timeline, in months. */
  const dateAxis = chart.xAxes.push(new DateAxis());
  dateAxis.dateFormats.setKey('month', dateAxisLabelFormat);
  dateAxis.gridIntervals.setAll([
    { timeUnit: 'month', count: 1 },
  ]);
  dateAxis.renderer.grid.template.disabled = true;
  dateAxis.renderer.labels.template.fontSize = labelsFontSize;
  dateAxis.renderer.labels.template.fill = labelsColor;
  dateAxis.cursorTooltipEnabled = false;

  /** On vertical axis we show no grid values. */
  const valueAxis = chart.yAxes.push(new ValueAxis());
  valueAxis.min = 0;
  valueAxis.renderer.grid.template.disabled = true;
  valueAxis.renderer.baseGrid.disabled = true;
  valueAxis.renderer.labels.template.disabled = true;
  valueAxis.cursorTooltipEnabled = false;

  /**
   * The chart is column based.
   * The horizontal axis uses date property as data source.
   * The vertical axis uses value property as data source.
   */
  const series = chart.series.push(new ColumnSeries());
  series.dataFields.dateX = 'date';
  series.dataFields.valueY = 'value';

  /** Define the tooltip text and style. */
  series.adapter.add('tooltipText', (text, target) => {
    const { dataContext } = target.tooltipDataItem;
    return dataContext.value === 1
      ? translate('PATIENT_RECORD.metricsChartTooltip')
      : translate('PATIENT_RECORD.metricsChartTooltip_plural');
  });
  series.tooltip.getFillFromObject = false;
  series.tooltip.background.fill = tooltipBackgroundColor;
  series.tooltip.background.filters.clear();
  series.tooltip.background.cornerRadius = 0;
  series.tooltip.label.paddingTop = 10;
  series.tooltip.label.paddingBottom = 10;
  series.tooltip.label.fontSize = 18;
  series.tooltip.label.fill = tooltipLabelColor;
  series.tooltip.background.shapeRendering = 'auto';

  /** Define columns background and border colors. */
  series.columns.template.fill = columnsColor;
  series.columns.template.stroke = columnsColor;
  series.columns.template.shapeRendering = 'auto';

  /** Define color adapters. */
  series.columns.template.adapter.add('fill', (fill, target) => getColorByDate(fill, lastColumnColor, target, 'dateX'));
  series.columns.template.adapter.add('stroke', (stroke, target) => getColorByDate(stroke, lastColumnColor, target, 'dateX'));
  dateAxis.renderer.labels.template.adapter.add('fill', (fill, target) => getColorByDate(fill, lastColumnLabelColor, target, 'date'));
  dateAxis.renderer.labels.template.adapter.add('text', getLabelTextByDate);
};

const KnMetricsChart = ({ chartId, data }) => {
  const chartContainer = useRef(null);

  useTheme(am4themesAnimated);
  useEffect(() => {
    ready(() => {
      chartContainer.current = createChartContainer(chartId);
      createChart(chartContainer.current, data);
    });

    return () => {
      /** Cleanup the amcharts data and events on unmount. */
      if (chartContainer.current) {
        chartContainer.current.dispose();
      }
    };
  }, [data, chartId]);

  return (
    <KnChartContainer id={chartId} />
  );
};

KnMetricsChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  chartId: PropTypes.string.isRequired,
};

export default KnMetricsChart;
