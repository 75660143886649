/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@material-ui/core/styles';
import { KnBrightBox } from 'components/Content';
import InputAdornment from '@material-ui/core/InputAdornment';
import KnSearchIcon from 'components/icons/SearchIcon';
import { KnTextFieldBase } from 'components/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { KnActionLink } from 'components/Link';
import palette from 'styles/colors';
import userActions from 'redux/actions/userActions';
import { composeFullName } from 'utils/utils';
import { APP_PAGE_URLS } from 'Constants';

const KnToolbarInput = styled(KnTextFieldBase)({
  '& .MuiFilledInput-root': {
    backgroundColor: palette.white.white1,
  },
});

const KnSearchInput = styled(KnToolbarInput)({
  width: 458,
});

const KnFilterInput = styled(KnToolbarInput)(({ theme }) => ({
  width: 226,
  marginLeft: theme.spacing(1),
}));

const KnPatientsTableToolbar = (props) => {
  const {
    onSearchTermChange,
    onPhysicianFilterChange,
    searchTerm,
    physicianFilter,
    disabled,
  } = props;
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const { physiciansList } = useSelector((state) => state.user);

  /** This adds the search icon at the end of the text field. */
  const InputProps = useMemo(() => ({
    endAdornment: (
      <InputAdornment position="end">
        <KnSearchIcon />
      </InputAdornment>
    ),
  }), []);

  useEffect(() => {
    dispatch(userActions.getPhysicians());
  }, [dispatch]);

  /** Sorting be lastName and then by firstName */
  const orderedPhysicians = useMemo(() => (
    _.orderBy(
      physiciansList.data,
      [(physician) => `${physician.lastName.toLowerCase()}${physician.firstName.toLowerCase()}`],
    )
  ), [physiciansList.data]);

  return (
    <KnBrightBox
      display="flex"
      justifyContent="space-between"
      pt={1}
      pb={1}
      pr={4}
      pl={2}
      mb={2}
    >
      <div>
        <KnSearchInput
          value={searchTerm}
          onChange={onSearchTermChange}
          label={translate('GENERAL.search')}
          InputProps={InputProps}
          /* eslint-disable-next-line react/jsx-no-duplicate-props */
          inputProps={{ 'data-testid': 'patients-search-input-field' }}
          disabled={disabled}
        />

        <KnFilterInput
          select
          value={physiciansList.initialized && physicianFilter && !disabled ? physicianFilter : 0}
          label={translate('HOME.filterByPhysician')}
          onChange={onPhysicianFilterChange}
          data-testid="physician-filter-dropdown"
          disabled={disabled}
        >
          <MenuItem value={0} data-testid="physician-filter-option-all">
            {translate('HOME.filterAll')}
          </MenuItem>
          { physiciansList.initialized
            && orderedPhysicians.map((physician, index) => (
              <MenuItem
                key={physician.userId}
                value={physician.userId}
                data-testid={`physician-filter-option-${index + 1}`}
              >
                {composeFullName(physician.firstName, physician.lastName)}
              </MenuItem>
            ))}
        </KnFilterInput>
      </div>

      {(process.env.REACT_APP_ALLOW_INVITE === 'true') && (
        <KnActionLink
          label={translate('HOME.invitePatient')}
          route={APP_PAGE_URLS.invitePatients}
        />
      )}
    </KnBrightBox>
  );
};

KnPatientsTableToolbar.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  onSearchTermChange: PropTypes.func.isRequired,
  physicianFilter: PropTypes.number,
  onPhysicianFilterChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

KnPatientsTableToolbar.defaultProps = {
  physicianFilter: undefined,
  disabled: false,
};

export default KnPatientsTableToolbar;
