/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import palette from 'styles/colors';

const menuListDelimiter = palette.silver.silver2;

export default {
  MuiMenuItem: {
    root: {
      fontSize: 14,
    },
  },
  MuiListItem: {
    root: {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: menuListDelimiter,
    },
    gutters: {
      paddingLeft: 7,
      paddingRight: 7,
    },
  },

  MuiList: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
};
