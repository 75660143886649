/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import palette from 'styles/colors';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { REGIMEN_STATUS } from 'Constants';

const defaultRegimenPanelBorderColor = palette.secondary;

/**
 * Returns text color for regimen status, based on status,
 * Note: also used to get regimen card (bottom) color
 * @param {string} status
 */
export const getStatusColor = (status, reached) => {
  switch (status) {
    case REGIMEN_STATUS.notStarted:
      return palette.error.main;
    case REGIMEN_STATUS.active:
      return reached ? palette.green.dark : palette.brightOrange;
    default:
      /** default colour for a titration card */
      return defaultRegimenPanelBorderColor;
  }
};

/**
 * Create style with color and background
 * for regimen status, based on status
 * @param {string} status
 */
const getStatusColorStyles = (status, reached) => {
  const color = getStatusColor(status, reached);
  let backgroundColor;

  switch (status) {
    case REGIMEN_STATUS.notStarted:
      backgroundColor = palette.red.light;
      break;
    case REGIMEN_STATUS.active:
      backgroundColor = reached ? palette.green.light : palette.paleOrange;
      break;
    default:
  }

  return { color, backgroundColor };
};

const KnStatusWrapper = styled('div')(({ theme, status, reached }) => ({
  alignSelf: 'center',
  padding: theme.spacing(0.5, 3),
  borderRadius: 90,
  fontSize: 16,
  fontWeight: 500,
  ...getStatusColorStyles(status, reached),
}));

/**
 * Displays the a regimen status,
 *  by translating it, and setting proper colors for it
 * @param {string} status Status of a regimen, to be displayed
 */
const KnRegimenStatus = ({ status, reachedGoal }) => {
  const { t: translate } = useTranslation();
  const translationStatusKey = reachedGoal ? 'reachedGoal' : status;
  const safeGoalProp = reachedGoal ? 'true' : '';

  return (
    <Typography status={status} reached={safeGoalProp} component={KnStatusWrapper} data-testid="regimen-status">
      {translate(
        'PATIENT_RECORD.medicationRegimen.status',
        { status: translate(`PATIENT_RECORD.medicationRegimen.STATUS_VALUES.${translationStatusKey}`) },
      )}
    </Typography>
  );
};

KnRegimenStatus.propTypes = {
  status: PropTypes.oneOf([
    REGIMEN_STATUS.notStarted,
    REGIMEN_STATUS.active,
    REGIMEN_STATUS.completed,
  ]).isRequired,
  reachedGoal: PropTypes.bool.isRequired,
};

export default KnRegimenStatus;
