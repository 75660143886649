/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { KnSubtleText } from 'components/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { undisclosed } from 'utils/utils';

const KnTableRow = styled(TableRow)(({ clickable }) => ({
  cursor: clickable ? 'pointer' : 'auto',
}));

/**
 * Table component which uses Material-UI Table components and accepts an
 * array of columns objects (see the propTypes definitions) and an array
 * of data objects (these objects will be used to populate the rows of the
 * table and each should have at least all the keys defined as the columns
 * valueAccessor property).
 *
 * It will use the name of the columns to build the table header and for each
 * column it will display the value pointed by valueAccessor on a data entry.
 * It also accepts a column custom component which will be passed the rowData
 * as prop.
 *
 */
const KnTable = (props) => {
  const {
    data,
    columns,
    rowKeyAccessor,
    emptyDataMessage,
    DataErrorComponent,
    sortBy,
    sortDirection,
    onRequestSort,
    onRowClick,
    ...rest
  } = props;
  const headers = useMemo(() => columns.map(
    (column) => ({ name: column.name, sortKey: column.sortKey }),
  ), [columns]);

  /**
   * Styled props must be string values; this is used on the table row
   * to set the cursor to pointer if it can be clicked.
   */
  const isRowClickable = onRowClick ? 'true' : '';

  const columnValue = useCallback((row, valueAccessor) => {
    let value;
    if (typeof valueAccessor === 'string') {
      value = row[valueAccessor];
    } else {
      value = valueAccessor(row);
    }
    return undisclosed(value);
  }, []);

  const sortHandler = (sortKey) => () => {
    onRequestSort(sortKey);
  };

  const onRowClickHandler = useCallback((row) => {
    if (onRowClick) {
      onRowClick(row);
    }
  }, [onRowClick]);

  return (
    <>
      <TableContainer {...rest}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={`th-${index}`}
                  sortDirection={header.sortKey === sortBy ? sortDirection : false}
                >
                  {header.sortKey
                    ? (
                      <TableSortLabel
                        active={header.sortKey === sortBy}
                        direction={header.sortKey === sortBy ? sortDirection : 'asc'}
                        onClick={sortHandler(header.sortKey)}
                      >
                        {header.name}
                      </TableSortLabel>
                    )
                    : header.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {data && data.map((row, rowIndex) => (
              <KnTableRow
                clickable={isRowClickable}
                data-testid={`table-row-${rowIndex + 1}`}
                key={row[rowKeyAccessor]}
                onClick={() => onRowClickHandler(row)}
              >
                {columns.map((column, index) => (
                  /* eslint-disable-next-line react/no-array-index-key */
                  <TableCell key={`th-row-${row[rowKeyAccessor]}-${index}`} component="th" scope="row">
                    {column.component
                      ? <column.component rowData={row} />
                      : columnValue(row, column.valueAccessor)}
                  </TableCell>
                ))}
              </KnTableRow>
            ))}

          </TableBody>
        </Table>
      </TableContainer>
      {data && !data.length && emptyDataMessage && (
        <Box display="flex" justifyContent="center" pt={7}>
          <Typography
            component={KnSubtleText}
            data-testid="empty-table-message"
            variant="body2"
          >
            {emptyDataMessage}
          </Typography>
        </Box>
      )}
      {DataErrorComponent && (
        <Box display="flex" justifyContent="center" pt={7}>
          {DataErrorComponent}
        </Box>
      )}
    </>
  );
};

KnTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        valueAccessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
        sortKey: PropTypes.string,
      }),
      PropTypes.shape({
        component: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ]),
  ).isRequired,
  rowKeyAccessor: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  emptyDataMessage: PropTypes.string,
  DataErrorComponent: PropTypes.node,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  onRequestSort: PropTypes.func,
  onRowClick: PropTypes.func,
};

KnTable.defaultProps = {
  data: null,
  DataErrorComponent: null,
  emptyDataMessage: '',
  sortBy: '',
  sortDirection: 'asc',
  onRequestSort: () => {},
  onRowClick: null,
};

export default KnTable;
