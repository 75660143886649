/**
* @copyright Copyright (C) 2020 Kokoon - All Rights Reserved
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import axios from 'axios';
import ACTION_TYPES from 'redux/actionTypes';
import { API_URLS } from 'Constants';

const instance = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_BASE_URL,
});

const appPushBusy = () => ({
  type: ACTION_TYPES.APP_PUSH_BUSY,
});

const appPopBusy = () => ({
  type: ACTION_TYPES.APP_POP_BUSY,
});

const appPushNotification = (messageKey) => ({
  type: ACTION_TYPES.APP_PUSH_NOTIFICATION,
  messageKey,
});

const appPopNotification = () => ({
  type: ACTION_TYPES.APP_POP_NOTIFICATION,
});
/**
 * BE version action creator, get version number
 */
const fetchApiVersion = () => {
  const success = (version) => ({ type: ACTION_TYPES.APP_SET_API_VERSION, version });

  return async (dispatch) => {
    try {
      const response = await instance.get(API_URLS.health);
      dispatch(success(response.data.version));
    } catch (error) {
      /** Do nothing, BE API version will not be displayed */
    }
  };
};
export default {
  appPushBusy,
  appPopBusy,
  appPushNotification,
  appPopNotification,
  fetchApiVersion,
};
